import {FormProvider, useForm} from "react-hook-form";
import {ReactComponent as Search} from "../../assets/icons/search.svg";
import InputField from "../Input";
import "./style.scss";
function SearchInput({setSearchVal}) {
  const methods = useForm();

  const onSubmit = data => {
    setSearchVal(data.search);
  };
  return (
    <FormProvider {...methods}>
      <form className="search-input" onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField
          type="search"
          name="search"
          required={false}
          placeholder={"Search by name"}
          errMsg="invalid input"
        />
        <button>
          <Search />
        </button>
      </form>
    </FormProvider>
  );
}

export default SearchInput;
