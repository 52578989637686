import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {Stepper, StepButton, Step, Button} from "@mui/material";
import Role from "./Role";
import Req from "./req";
import "./style.scss";
import Benefit from "./benefit";
import Done from "./done";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import {useCreateVacancyMutation} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import {useNavigate} from "react-router-dom";

export const STEP_TITLES = ["Role", "Requirements", "Benefits", "Done"];
const TOTAL_STEPS = 3;
const CreateVacancy = () => {
  const methods = useForm({
    mode: "all",
    shouldUnregister: true,
  });
  let navigate = useNavigate();
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [createVacancy, {isLoading}] = useCreateVacancyMutation();
  const onSubmit = async data => {
    try {
      const payload = {
        ...data,
        cover_image: image,
      };
      let res = await createVacancy(payload).unwrap();

      if (res.status === "success")
        toaster("success", "Vacancy successfully created");
      setTimeout(() => navigate("/hr/vacancy"), 1300);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  const handleStep = step => {
    if (methods.formState.isValid) {
      setActiveStep(step);
    } else {
      console.warn("feilds not valid");
    }
  };
  const [activeStep, setActiveStep] = useState(0);
  return (
    <div className="pd_create_vacancy addProperty">
      <TitleWithBackBtn title="Create Vacancy" />
      <div className="formSide">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stepper className="stepper" activeStep={activeStep}>
              {STEP_TITLES.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepButton
                      className={`${activeStep === index ? "active" : ""}`}
                      type="button"
                      onClick={() => handleStep(index)}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === 3 && (
              <p className="midTitle">
                Please confirm everything is in order <br /> before publishing.
              </p>
            )}

            <div className="form-container">
              {activeStep >= 0 && (
                <Role
                  setImageUrl={setImageUrl}
                  setImage={setImage}
                  display={activeStep === 0}
                />
              )}

              {activeStep >= 1 && <Req display={activeStep === 1} />}

              {activeStep >= 2 && <Benefit display={activeStep === 2} />}

              {activeStep >= 3 && (
                <Done
                  formState={{...methods.getValues(), image: imageUrl}}
                  display={activeStep === 3}
                />
              )}

              <div className="btn-wrap">
                <Button
                  onClick={() => {
                    return activeStep > 0
                      ? setActiveStep(activeStep - 1)
                      : null;
                  }}
                  type="button"
                  variant="outlined"
                >
                  {activeStep === 0 ? "Cancel" : `Previous`}
                </Button>
                <Button
                  onClick={() => handleStep(activeStep + 1)}
                  type="button"
                  variant="contained"
                  style={{
                    display: activeStep < TOTAL_STEPS ? "flex" : "none",
                  }}
                >
                  Next
                </Button>
                <SubmitBtn
                  btnText="Publish"
                  disabled={!methods.formState.isValid}
                  isLoading={isLoading}
                  style={{
                    display: activeStep === TOTAL_STEPS ? "flex" : "none",
                  }}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default CreateVacancy;
