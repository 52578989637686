import {useForm, FormProvider} from "react-hook-form";
import {useState} from "react";
import {Stepper, StepButton, Step, Button} from "@mui/material";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import "./style2.scss";
import Details from "../AddPropertyForm/Details";
import Customization2 from "../AddPropertyForm/Customization2";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import ViewEstate from "./view";
import {makeArr, removeEmpty, removeObj, toaster} from "../../../utils/utils";
import {useAddEstateMutation} from "../../../services/api";
import {useNavigate} from "react-router-dom";

export const STEP_TITLES = ["Details", "Customization", "Done"];
const TOTAL_STEPS = 2;

const AddEstate = () => {
  const methods = useForm({
    mode: "all",
    shouldUnregister: true,
  });
  const [image, setImage] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [submitEstate, {isLoading}] = useAddEstateMutation();
  const navigate = useNavigate();
  const onSubmit = async data => {
    const credentials = {
      ...data,
      features: makeArr(data.features, "featureName"),
      document_fee: removeObj(data.document_fee),
      documents: makeArr(data.documents, "document"),
      gallery: image,
      featured: parseInt(data.featured),
    };
    try {
      let res = await submitEstate(removeEmpty(credentials)).unwrap();
      if (res.status === "success") {
        navigate("/properties");
        toaster("success", res.message);
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleStep = step => {
    if (methods.formState.isValid) {
      setActiveStep(step);
    } else {
      console.warn("feilds not valid");
    }
  };
  return (
    <div className="addProperty">
      <header className="heading">
        <TitleWithBackBtn title="Add Estate" />
      </header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stepper className="stepper" activeStep={activeStep}>
            {STEP_TITLES.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton
                    className={`${activeStep === index ? "active" : ""}`}
                    type="button"
                    onClick={() => handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>

          <div
            className={`form-container ${
              activeStep === TOTAL_STEPS ? "p0" : ""
            } `}
          >
            {activeStep >= 0 && (
              <Details
                setImgUrls={setImgUrls}
                images={image}
                setImages={setImage}
                type={2}
                display={activeStep === 0}
              />
            )}
            {activeStep >= 1 && (
              <Customization2 type={2} display={activeStep === 1} />
            )}
            {activeStep >= 2 && (
              <ViewEstate
                isFormView={false}
                images={imgUrls}
                estate={methods.getValues()}
                display={activeStep === 2}
              />
            )}
            <div className="btn-wrap">
              <Button
                onClick={() =>
                  activeStep > 0 ? setActiveStep(activeStep - 1) : null
                }
                type="button"
                variant="outlined"
              >
                {activeStep === 0
                  ? "Cancel"
                  : `Previous:${STEP_TITLES[activeStep - 1]}`}
              </Button>
              <Button
                onClick={() => handleStep(activeStep + 1)}
                type="button"
                variant="contained"
                style={{
                  display: activeStep < TOTAL_STEPS ? "flex" : "none",
                }}
              >
                Next:{STEP_TITLES[activeStep + 1]}
              </Button>
              <SubmitBtn
                btnText="Publish"
                disabled={!methods.formState.isValid}
                isLoading={isLoading}
                style={{
                  display: activeStep === TOTAL_STEPS ? "flex" : "none",
                }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEstate;
