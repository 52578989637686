import {LoadingButton} from "@mui/lab";
import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {deleteImg, uploadImg} from "../../services/UploadImg";
import {toaster, truncateString} from "../../utils/utils";
import "./style.scss";

const UploadPdf = ({
  name,
  filesAccepted,
  label,
  placeholder = "Upload File",
  setFile,
  setFileUrl,
  folder,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDelLoading, setDelIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [delToken, setDelToken] = useState("");

  const onDrop = useCallback(
    acceptedFiles => {
      const delImg = async token => {
        setDelIsLoading(true);
        try {
          await deleteImg(token);
        } catch (err) {
          console.warn(err);
        } finally {
          setDelIsLoading(false);
        }
      };

      const uploader = async file => {
        if (file) {
          if (delToken) {
            await delImg(delToken);
          }
          setIsLoading(true);
          try {
            let url = await uploadImg(
              file,
              process.env.REACT_APP_CLOUDINARY_PRESET,
              folder
            );
            setFile(url.public_id);
            if (setFileUrl) setFileUrl(url.secure_url);
            setDelToken(url.delete_token);
          } catch (err) {
            setError("Image could not be uploaded");
            toaster("error", "Image could not be uploaded");
          } finally {
            setIsLoading(false);
          }
        } else {
          setError("No file selected");
        }
      };

      uploader(acceptedFiles[0]);
    },
    [delToken, folder, setFile, setFileUrl]
  );

  const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    noClick: true,
    accept: filesAccepted.join(", "),
    multiple: false,
  });

  return (
    <div className="form-group upload-pdf">
      <label htmlFor="">{label}</label>
      <div className={`input`} {...getRootProps()}>
        <p>
          {acceptedFiles[0]
            ? truncateString(acceptedFiles[0].name)
            : placeholder}
        </p>
        <input {...rest} {...getInputProps()} />
        <LoadingButton
          onClick={open}
          loading={isLoading || isDelLoading}
          variant="contained"
          color={acceptedFiles[0] ? "red" : "primary"}
        >
          {acceptedFiles[0] ? "Change file" : "Upload file"}
        </LoadingButton>
      </div>
      {error && <div className="input-err-msg">{error}</div>}
    </div>
  );
};

export default UploadPdf;
