import {Button} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Controller, useFormContext} from "react-hook-form";
import {truncateString, titleCase} from "../../utils/utils";
import {ReactComponent as FileIcon} from "../../assets/icons/fileIcon.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import "./style.scss";

const DropZoneField = ({name, filesAccepted, fileFormats, ...rest}) => {
  const {control, setValue} = useFormContext();
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles(prev => [...prev, ...acceptedFiles]);
  }, []);

  const removeFile = file => () => {
    console.log("removeFile...");
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setMyFiles(prev => [...acceptedFiles]);
    setValue(name, acceptedFiles);
  };

  const {acceptedFiles, getRootProps, getInputProps, isDragActive} =
    useDropzone({
      onDrop,
      accept: filesAccepted.join(", "),
      multiple: true,
    });

  return (
    <div className="drop-zone-wrap">
      <Controller
        render={({field: {onChange}}) => (
          <>
            <div
              className={`drop-zone ${isDragActive ? "active" : ""}`}
              {...getRootProps()}
            >
              <input
                {...rest}
                {...getInputProps({
                  onChange: e => {
                    setMyFiles(prev => [...prev, ...acceptedFiles]);
                    onChange(e.target.files);
                  },
                })}
              />
              <Button variant="outlined" color="textClr">
                Add File
              </Button>
              <p>or drop files to upload</p>
            </div>
          </>
        )}
        name={name}
        control={control}
      />
      <p style={{margin: "0.6rem 0"}}>
        Accepted file(s) format is/are{" "}
        <span style={{color: "#4193FF"}}> {fileFormats} </span>
      </p>
      <h3>Uploaded Files</h3>
      <ul className="files-wrap">
        {myFiles.map(file => (
          <li className="file-item" key={file.path}>
            <FileIcon className="file-icon" />
            <div className="file-dt">
              <p>
                {truncateString(titleCase(file.name.split(".")[0], 30))}.
                {file.name.split(".")[file.name.split(".").length - 1]}
              </p>
            </div>
            <button onClick={removeFile(file)}>
              <CancelIcon color="iconColor" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropZoneField;
