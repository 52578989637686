import {Outlet} from "react-router-dom";
import {CustomSideLink} from "../../components/CustomSideLink";
import "../MasterDataLayout/style.scss";

const HrLayout = () => {
  return (
    <div className="pg-layout">
      <div className="drop-down-links sidebar-drop">
        <CustomSideLink baseUrl={"hr"} to="">
          Applications
        </CustomSideLink>
        <CustomSideLink baseUrl={"hr"} to="vacancy">
          Vacancy
        </CustomSideLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default HrLayout;
