import {useForm, FormProvider} from "react-hook-form";
import {useState} from "react";
import {Stepper, StepButton, Step, Button} from "@mui/material";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import "./style2.scss";
import Details from "../AddPropertyForm/Details";
import Customization2 from "../AddPropertyForm/Customization2";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {makeArr, removeEmpty, removeObj, toaster} from "../../../utils/utils";
import {
  useEditPropertyMutation,
  useGetOnePropertyQuery,
} from "../../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";

export const STEP_TITLES = ["Details", "Customization"];
const TOTAL_STEPS = 1;

const EditEstate = () => {
  const methods = useForm({
    mode: "all",
    shouldUnregister: true,
  });
  const {id} = useParams();

  const {
    data: estate = null,
    isSuccess,
    isLoading: isGetLoading,
    isError,
    error,
  } = useGetOnePropertyQuery(`estate/${id}`);

  const [image, setImage] = useState([]);
  const [editEstate, {isLoading}] = useEditPropertyMutation();
  const navigate = useNavigate();
  const onSubmit = async data => {
    const credentials = {
      ...data,
      features: makeArr(data.features, "featureName"),
      document_fee: removeObj(data.document_fee),
      documents: makeArr(data.documents, "document"),
      gallery: image,
      featured: parseInt(data.featured),
    };

    const filledEstate = Object.keys(estate)
      .filter(
        key =>
          !key.includes("payment_plan") &&
          !key.includes("longitude") &&
          !key.includes("latitude") &&
          !key.includes("id") &&
          !key.includes("created_at") &&
          !key.includes("type") &&
          !key.includes("gallery") &&
          key !== "status"
      )
      .reduce((cur, key) => {
        return Object.assign(cur, {[key]: estate[key]});
      }, {});

    if (isSuccess) {
      try {
        let res = await editEstate({
          body: {
            ...Object.assign(
              {
                ...filledEstate,
                document_fee: Object.entries(estate.document_fee),
                featured: estate.featured ? 1 : 0,
              },
              {...removeEmpty(credentials)}
            ),
          },
          url: `estate/${id}`,
        }).unwrap();
        if (res.status === "success") {
          navigate("/properties");
          toaster("success", res.message);
        }
      } catch (err) {
        if (err.status === "FETCH_ERROR")
          toaster("error", "Something went wrong, please try again...");
        else toaster("error", err.data.message);
        console.warn(err);
      }
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleStep = step => {
    if (methods.formState.isValid) {
      setActiveStep(step);
    } else {
      console.warn("feilds not valid");
    }
  };

  if (isGetLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="addProperty">
      <header className="heading">
        <TitleWithBackBtn title={`Edit Estate `} />
      </header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stepper className="stepper" activeStep={activeStep}>
            {STEP_TITLES.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton
                    className={`${activeStep === index ? "active" : ""}`}
                    type="button"
                    onClick={() => handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>

          <div className="form-container">
            {activeStep >= 0 && (
              <Details
                images={image}
                setImages={setImage}
                type={2}
                display={activeStep === 0}
                isEditType={true}
              />
            )}
            {activeStep >= 1 && (
              <Customization2 type={2} display={activeStep === 1} />
            )}
            <div className="btn-wrap">
              <Button
                onClick={() =>
                  activeStep > 0 ? setActiveStep(activeStep - 1) : null
                }
                type="button"
                variant="outlined"
              >
                {activeStep === 0
                  ? "Cancel"
                  : `Previous:${STEP_TITLES[activeStep - 1]}`}
              </Button>
              <Button
                onClick={() => handleStep(activeStep + 1)}
                type="button"
                variant="contained"
                style={{
                  display: activeStep < TOTAL_STEPS ? "flex" : "none",
                }}
              >
                Next:{STEP_TITLES[activeStep + 1]}
              </Button>
              <SubmitBtn
                btnText="Save Estate"
                disabled={!methods.formState.isValid}
                isLoading={isLoading}
                style={{
                  display: activeStep === TOTAL_STEPS ? "flex" : "none",
                }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditEstate;
