import "./style.scss";
const EmptyResponsePage = ({
  image = "/images/EmptyResponseDefault.png",
  message,
  btn,
}) => {
  return (
    <div className="empty-response-pg">
      <img src={image} alt="no result" />
      <p>{message}</p>
      {btn && btn}
    </div>
  );
};

export default EmptyResponsePage;
