import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import {Grid} from "@mui/material";
import EachVacancy from "../../../components/EachVacancy";
import {useGetAllVacancyQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import moment from "moment";
import {truncateString} from "../../../utils/utils";
import EmptyResponsePage from "../../../components/EmptyResponsePage";

// import TablePagination from "../../../components/TablePagination";
const Vacancy = () => {
  const {
    data: vacancy = [],
    isLoading,
    isError,
    error,
  } = useGetAllVacancyQuery(1);
  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_vacancy table-pg-layout">
      <PageTitleAddBtn
        title={"Vacancy"}
        btnText={"Create Vacancy"}
        to={"create-vacancy"}
      />

      {vacancy.data.length ? (
        <>
          <Grid container mt={1} spacing={4}>
            {vacancy.data.map((item, i) => (
              <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
                <EachVacancy
                  title={item.title}
                  date={moment(item.updated_at).format("DD-MM-YYYY")}
                  story={truncateString(item.description, 30)}
                  id={item.id}
                  closing={moment(item.closing_date).format("DD-MM-YYYY")}
                  image={item.image}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <EmptyResponsePage
          message={
            "No Vacancy has been created Yet, Click 'Create Vacancy' To Create A New Vacancy"
          }
        />
      )}
    </div>
  );
};

export default Vacancy;
