import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import "./style.scss";
import EachPromo from "../../../components/eachPromo";
import {useGetAllPromoQuery} from "../../../services/api";
import {Grid} from "@mui/material";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import TablePagination from "../../../components/TablePagination";
import EmptyResponsePage from "../../../components/EmptyResponsePage";
import {useState} from "react";
const Promos = () => {
  const [page, setPage] = useState(1);

  const {
    data: promo = [],
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetAllPromoQuery(page);

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_promo">
      <PageTitleAddBtn
        title={"Promos"}
        btnText={"Create Promo"}
        to={"/publish/promos/add"}
      />
      {isSuccess && promo.data.length ? (
        <>
          <Grid container mt={1} spacing={4} alignItems={"stretch"}>
            {promo.data.map(item => {
              return (
                <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
                  <EachPromo
                    title={item.name}
                    bgImg={item.image}
                    id={item.id}
                    desc={item.description}
                    status={item.status}
                  />
                </Grid>
              );
            })}
          </Grid>
          <TablePagination page={page} setPage={setPage} data={promo} />
        </>
      ) : (
        <EmptyResponsePage
          message={"No Promo Yet, Click 'Add Promo' To Add A New Series"}
        />
      )}
    </div>
  );
};

export default Promos;
