import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import prom from "../../../assets/icons/prom.png";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import "./style.scss";
import {
  useDeletePromoMutation,
  useGetOnePromoQuery,
} from "../../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import ErrorMsg from "../../../components/ErrorMsg";
import Loader from "../../../components/Loader";
import {toaster} from "../../../utils/utils";
const ViewPromo = () => {
  const {id} = useParams();

  const {
    data: promo = null,
    isLoading,
    isError,
    error,
  } = useGetOnePromoQuery(id);

  const [deletePromo] = useDeletePromoMutation();
  const navigate = useNavigate();
  const deleteHandler = async () => {
    try {
      const response = await deletePromo(id).unwrap();
      if (response.status === "success") {
        toaster("success", response.message);
        navigate("/publish/promos");
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_view_promo">
      <header className="heading">
        <TitleWithBackBtn title="View Promo" />
        <Button
          variant="contained"
          color="red0"
          endIcon={<DeleteIcon color="red" />}
          className="delBtn"
          onClick={deleteHandler}
        >
          Delete
        </Button>
      </header>

      <div className="pg_content_view">
        <img src={promo && promo.image} alt="prom" />
        <div className="nameFlex">
          <p className="name">{promo && promo.name}</p>
        </div>

        <div className="details">
          <p className="promDet">Promo Details</p>
          <p className="story">{promo && promo.description}</p>
        </div>
        <div className="prom_publish">
          <p className="promDet">Publish Date</p>
          <p className="story">
            {promo && moment(promo.createdAt).format("MMM Do YY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewPromo;
