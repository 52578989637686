/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import "../../MasterDataPages/Users/style.scss";
import MarketersTable from "./MarketersTable";
import {useState} from "react";
import Modal from "../../../components/Modal";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import InputField from "../../../components/Input";
import SelectField from "../../../components/Input/SelectField";
import {FormProvider, useForm} from "react-hook-form";
import {selectOptions} from "../../../utils/data";
import DropZoneField from "../../../components/DropZoneField";
import {useExporterMutation} from "../../../services/api";
import {downloader, toaster} from "../../../utils/utils";
const Marketers = () => {
  const methods = useForm({mode: "all"});
  const onSubmit = data => {};

  const [modal, setModal] = useState(false);
  const [createUserType, setCreateUserType] = useState("none");
  const [value, setValue] = useState("");

  const closeModal = () => {
    setModal(!modal);
    setCreateUserType("none");
    setValue("");
    methods.reset();
  };

  const handleRadioChange = event => {
    setValue(event.target.value);
  };

  const handleSubmitUserType = e => {
    e.preventDefault();
    if (value.length > 0) setCreateUserType(value);
    else return;
  };

  const [getFile] = useExporterMutation();
  const download = async () => {
    try {
      const response = await getFile({type: "marketers"}).unwrap();

      downloader(response, "marketers");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="users-pg table-pg-layout">
      <div className="heading">
        <h1 className="pg-title">Marketers</h1>
        <Button
          color="primary"
          variant="outlined"
          // disabled={selected.length < 1}

          onClick={download}
        >
          Export
        </Button>
      </div>

      <MarketersTable />
      <Modal openModal={modal} closeModal={closeModal}>
        <h2>Add Marketer</h2>
        {createUserType === "none" && (
          <div>
            <RadioGroup
              aria-label="create user"
              name="position"
              value={value}
              sx={{m: "1rem 0", width: "470px"}}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                className={`radio-label ${
                  value === "individualType" ? "checked" : ""
                }`}
                value="individualType"
                control={<Radio className="radio-input" />}
                label={
                  <div>
                    <p>
                      <strong>Add Individual Marketer</strong>
                    </p>
                    <p>Add a single marketer </p>
                  </div>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                className={`radio-label ${
                  value === "csvType" ? "checked" : ""
                }`}
                value="csvType"
                control={<Radio className="radio-input" />}
                label={
                  <div>
                    <p>
                      <strong>Import Marketer CSV list</strong>
                    </p>
                    <p>Import and add marketers using a csv file. </p>
                  </div>
                }
                labelPlacement="end"
              />
            </RadioGroup>
            <div className="btn-wrap">
              <Button onClick={closeModal} type="button" variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={handleSubmitUserType}
                type="button"
                variant="contained"
              >
                Continue
              </Button>
            </div>
          </div>
        )}
        {createUserType !== "none" && (
          <FormProvider {...methods}>
            <form
              className="createUserForm"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {createUserType === "individualType" && (
                <div style={{width: "680px"}}>
                  <InputField
                    type="text"
                    name={`fullName`}
                    placeholder="Full Name"
                    label={"Full Name"}
                    errMsg="invalid name input"
                  />
                  <InputField
                    type="email"
                    name={`email`}
                    placeholder="Enter Email Address"
                    label={"Email Address"}
                    errMsg="invalid email address"
                  />
                  <InputField
                    type="text"
                    name={`address`}
                    placeholder="Enter House Address"
                    label={"House Address"}
                    errMsg="invalid house address"
                  />
                  <div className="form-group-wrap">
                    <SelectField
                      name={`location`}
                      label={"Location"}
                      errMsg="This field is required"
                      selectOption={selectOptions("Location")}
                    />
                    <InputField
                      type="tel"
                      name={`phoneNumber`}
                      placeholder="Enter Phone Number"
                      label={"Phone Number"}
                    />
                  </div>
                </div>
              )}
              {createUserType === "csvType" && (
                <div style={{width: "523px"}}>
                  <p>
                    Download a{" "}
                    <a href="#" style={{color: "#4193FF"}}>
                      sample CSV template
                    </a>{" "}
                    to see an example of the format required
                  </p>
                  <DropZoneField
                    name="dropzone"
                    filesAccepted={[".csv"]}
                    fileFormats=".csv"
                  />
                </div>
              )}
              <div className="btn-wrap">
                <Button onClick={closeModal} type="button" variant="outlined">
                  Cancel
                </Button>

                <SubmitBtn
                  disabled={!methods.formState.isValid}
                  btnText={"Add Marketer"}
                />
              </div>
            </form>
          </FormProvider>
        )}
      </Modal>
    </div>
  );
};

export default Marketers;
