import call from "../../../assets/icons/call.svg";
import message from "../../../assets/icons/Message.svg";
import "./style.scss";
import {useState} from "react";
import Modal from "../../../components/Modal";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import InputField from "../../../components/Input";
import SelectField from "../../../components/Input/SelectField";
import {FormProvider, useForm} from "react-hook-form";
import {Avatar, Button} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {
  useChangePasswordMutation,
  useChangeAdminRoleMutation,
  useGetOneAdminQuery,
} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import {useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
const SingleUser = () => {
  const {id} = useParams();
  const {
    data: user = null,
    isLoading: isLoadingSingle,
    isError,
    error,
  } = useGetOneAdminQuery(id);

  const methods = useForm({mode: "all"});
  const pwdMethods = useForm({mode: "all"});
  const [editRole, {isLoading: roleLoading}] = useChangeAdminRoleMutation();
  const [changePwd, {isLoading}] = useChangePasswordMutation();

  const onSubmit = async data => {
    try {
      let res = await changePwd({body: data, id: id}).unwrap();
      if (res.status === "success") toaster("success", res.message);
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };

  const onSubmit2 = async data => {
    try {
      let res = await editRole({credentials: data, id: id}).unwrap();
      if (res.status === "success") toaster("success", res.message);
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };

  const [modal, setModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const closeModal = () => {
    setModal(!modal);
    methods.reset();
  };

  const closePassWordModal = () => {
    setPasswordModal(!passwordModal);
    pwdMethods.reset();
  };
  if (isLoadingSingle) return <Loader />;
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_profile admin_profile">
      <div className="heading">
        <TitleWithBackBtn title="User Profile" />
      </div>
      <div className="display_img">
        <Avatar
          alt={user && user.data.firstname + " " + user.data.lastname}
          src={user && user.data.display_picture}
          sx={{
            width: 80,
            height: 80,
            borderRadius: "8px",
          }}
        />
      </div>
      <div className="profileBox">
        <div className="box-head">
          <p>Personal Information</p>
        </div>
        <div className="eachDetail">
          <p className="question">Name</p>
          <p className="answer">
            {user && user.data.firstname + " " + user.data.lastname}
          </p>
        </div>
        <div className="eachDetail">
          <p className="question">Gender</p>
          <p className="answer">{user && user.data.gender}</p>
        </div>
        <div className="eachDetail contact">
          <p className="question">Contact Info</p>
          <div className="answerLeft">
            <div className="message">
              <img src={message} alt="message" />
              <p>{user && user.data.email}</p>
            </div>
            {user && user.data.phone_number && (
              <div className="message">
                <img src={call} alt="message" />
                <p>{user && user.data.phone_number}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profileBox">
        <div className="box-head">
          <p>Role & Permissions</p>
          <button onClick={() => setModal(!modal)} className="edit">
            Edit
          </button>
        </div>
        <div className="eachDetail">
          <p className="question">Role Name</p>
          <p className="answer">{user && user.data.roles[0]}</p>
        </div>
        <div className="eachDetail">
          <p className="question">Role Type</p>
          <p className="answer">{user && user.data.roles[0]}</p>
        </div>
        {/* <div className="eachDetail three">
          <p className="question">Permissions</p>
          <div className="answer">
            <p className="">24</p>
            <button className="edit">View Permissions</button>
          </div>
        </div> */}
      </div>
      <div className="profileBox">
        <div className="box-head">
          <p>Account Configuration</p>
        </div>
        <div className="eachDetail three">
          <p className="question">PASSWORD</p>

          <div className="answer">
            <button
              onClick={() => setPasswordModal(!passwordModal)}
              className="edit"
            >
              Change
            </button>
          </div>
        </div>
        <div className="eachDetail">
          <p className="question">Language</p>
          <p className="answer">English</p>
        </div>
      </div>
      <Modal openModal={modal} closeModal={closeModal}>
        <h2>Edit Role</h2>

        <FormProvider {...methods}>
          <form
            className="createUserForm"
            onSubmit={methods.handleSubmit(onSubmit2)}
          >
            <div style={{width: "680px"}}>
              <SelectField
                name={`role`}
                label={"Role Name"}
                errMsg="invalid email address"
                placeholder="Select Role"
                selectOption={[
                  "Admin",
                  "Auditor",
                  "Client Service",
                  "Human Resource",
                  "Sales",
                  "Super Admin",
                ]}
              />
            </div>

            <div className="btn-wrap">
              <Button onClick={closeModal} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn
                isLoading={roleLoading}
                disabled={!methods.formState.isValid}
                btnText={"Save Changes"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal openModal={passwordModal} closeModal={closePassWordModal}>
        <h2>Change Password</h2>

        <FormProvider {...pwdMethods}>
          <form
            className="createUserForm"
            onSubmit={pwdMethods.handleSubmit(onSubmit)}
          >
            <div style={{width: "680px"}}>
              <InputField
                type="password"
                name="password"
                label="Change Password"
                placeholder="Enter password"
                errMsg="invalid field"
              />
            </div>

            <div className="btn-wrap">
              <Button
                onClick={closePassWordModal}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>

              <SubmitBtn
                isLoading={isLoading}
                disabled={!pwdMethods.formState.isValid}
                btnText={"Save Changes"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};

export default SingleUser;
