import AuthLayout from "../../templates/AuthLayout";
import lock from "../../assets/icons/lock.svg";
import {useForm, FormProvider} from "react-hook-form";
import InputField from "../../components/Input";
import {Link} from "react-router-dom";
import "./style.scss";
import SubmitBtn from "../../components/SubmitBtn/SubmitBtn";
const Reset = () => {
  const methods = useForm();

  const onSubmit = data => console.log(data);
  return (
    <AuthLayout>
      <div className="whiteBox-reset">
        <img src={lock} alt="logo" className="logo" />
        <p className="page_name">Reset Password</p>
        <p className="sub">
          Please enter your email address to reset password.
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              type="email"
              name="email"
              placeholder=""
              id="password"
              label="Email"
              errMsg="invalid email input"
            />

            <SubmitBtn btnText={"Reset Password"} />
            <Link className="forget" to="/">
              Login
            </Link>
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
};

export default Reset;
