import moment from "moment";

const Done = ({display, formState}) => {
  return (
    <>
      <div
        className="pd_done pd_view_promo"
        style={{display: display ? "block" : "none", padding: 0}}
      >
        <div
          className="pg_content_view"
          style={{width: "100%", marginTop: "0", padding: 0}}
        >
          <img src={formState.image} alt="prom" />
          <div className="nameFlex">
            <p className="name">{formState.title}</p>
            <p className="date">{moment().format("DD-MM-YYYY")}</p>
          </div>

          <div className="details">
            <p className="promDet"> Description</p>
            <p className="story">{formState.description}</p>
          </div>
          <div className="details">
            <p className="promDet"> Requirements</p>
            <p className="story">{formState.requirements}</p>
          </div>
          <div className="details">
            <p className="promDet"> Benefits</p>
            <p className="story">{formState.benefits}</p>
          </div>
          <div className="prom_publish">
            <p className="promDet">Closing Date</p>
            <p className="story">
              {moment(formState.closing_date).format("DD-MM-YYYY")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Done;
