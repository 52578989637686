import EachResponse2 from "../../../components/eachResponse2";
import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {LoadingButton} from "@mui/lab";

const CPSDetails = () => {
  return (
    <div className="pd_cps_display">
      <div className="heading topSide">
        <p className="pg-title">Client Partnership Scheme</p>
        <div className="btn-wrap">
          <Button
            variant="contained"
            color="primary20"
            endIcon={<EditIcon color="primary" />}
          >
            Edit
          </Button>
          <LoadingButton
            variant="contained"
            color="red0"
            endIcon={<DeleteIcon color="red" />}
            loading={true}
            loadingPosition="end"
            className="delBtn"
            // onClick={deleteHandler}
          >
            Delete
          </LoadingButton>
        </div>
      </div>
      <div className="responseBox">
        <EachResponse2
          faint={true}
          question={"Introduction"}
          answer={
            "Clients to earn a commission of 5% on any plot of land sold and 3% on housing unit sold.documents are avaiWhat documents are available? and what documents are avaiWhat documents are available? and what documents are avaiWhat documents are available? and what documents"
          }
        />
        <EachResponse2
          question={"Business Developement Executive (BDE) "}
          answer={
            "Clients to earn a commission of 5% on any plot of land sold and 3% on housing unit sold.documents are avaiWhat documents are available? and what documents are avaiWhat documents are available? and what documents are avaiWhat documents are available? and what documents"
          }
        />
      </div>
    </div>
  );
};

export default CPSDetails;
