// slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {IconButton} from "@mui/material";
const Carousel = ({children, showArrows = true}) => {
  const SlickButtonFix = ({currentSlide, slideCount, type, ...props}) => (
    <IconButton className="btn-carousel" {...props}>
      {type === "prev" ? (
        <ArrowBackIcon color="white" />
      ) : (
        <ArrowForwardIcon color="white" />
      )}
    </IconButton>
  );

  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    width: "100%",
    arrows: showArrows,
    // infinite: false,
    margin: 10,
    autoplay: false,
    prevArrow: <SlickButtonFix type="prev" />,
    nextArrow: <SlickButtonFix type="next" />,
  };
  return <Slider {...sliderSettings}>{children}</Slider>;
};

export default Carousel;
