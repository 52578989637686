import InputField from "../../../components/Input";
import Textbox from "../../../components/Input/Textbox";

const Benefit = ({display}) => {
  return (
    <div className="pd_benefit" style={{display: display ? "block" : "none"}}>
      <Textbox
        placeholder={"Enter Benefits"}
        name={"benefits"}
        label={"Benefits"}
        errMsg={"invalid field"}
        extraClass={"extraHeight"}
      />
      <div className="half">
        <InputField
          type="date"
          name="closing_date"
          placeholder="Date"
          label="Closing Date"
          min={new Date().toISOString().split("T")[0]}
        />
      </div>
    </div>
  );
};

export default Benefit;
