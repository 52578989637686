import locate from "../../../assets/icons/location.svg";
// import DownloadIcon from "@mui/icons-material/Download";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "../../../components/Carousel";
import {getYtId} from "../../../utils/utils";
import moment from "moment";
import {Button} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function ViewHousingForm({data}) {
  return (
    <div className="pd-view">
      <div className="aboutEstate">
        <div className="carousel">
          <Carousel>
            {data.gallery.map((el, i) => (
              <img key={"image-" + i} src={el} alt={"image-" + i} />
            ))}
          </Carousel>
        </div>
        <div className="estateAbout">
          <p className="name">{data.name}</p>
        </div>
        <div className="hotSide">
          <p className={data.tag.toLowerCase()}>{data.tag}</p>
          <p className={"blue"}>Housing</p>
          <p className={data.property_status.split(" ")[0].toLowerCase()}>
            {data.property_status}
          </p>
        </div>
        <div className="location">
          <img src={locate} alt="locate" />
          {data.location || "N/A"}
        </div>
        <div className="desc">
          <p className="head">Description</p>
          <p className="story">{data.description}</p>
        </div>
        <Button
          variant="contained"
          href={data.brochure}
          download
          color="primary20"
          endIcon={<FileDownloadIcon color="primary" />}
        >
          Brochure
        </Button>
        <div className="video">
          <div className="eachVideo">
            <p className="head">Property Address</p>
            <div className="map">
              <div
                style={{
                  overflow: "hidden",
                  background: "none !important",
                  height: "100%",
                  width: "100%",
                }}
                className="gmap_canvas"
              >
                <iframe
                  title="maps"
                  width="100%"
                  height="100%"
                  id="gmap_canvas"
                  src={`https://maps.google.com/maps?q=${data.address},${data.location}=&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                <br />
                <a href="https://www.embedgooglemap.net">
                  embedded google search
                </a>
              </div>
            </div>
          </div>
          {data.youtube_url && (
            <div className="eachVideo">
              <p className="head">Documentary</p>
              <div className="map">
                <iframe
                  title="youtube url"
                  height="173px"
                  width="100%"
                  src={`https://www.youtube.com/embed/${getYtId(
                    data.youtube_url
                  )}`}
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <div className="accordion">
          <ul className="accordion-list acc" style={{listStyleType: "disc"}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="accTop">Configurations</p>
              </AccordionSummary>
              {data?.configurations.map((item, i) => {
                return (
                  <AccordionDetails key={item + i}>
                    <li className="accInn">{item}</li>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          </ul>
        </div>
        <div className="date">
          <p className="head">Publish Date</p>
          <p className="dateText">
            {" "}
            {moment(new Date()).format("Do MMMM YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ViewHousingForm;
