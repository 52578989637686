import {Pagination} from "@mui/material";

function TablePagination({data, page, setPage}) {
  return (
    <>
      {data.meta.last_page > 1 && (
        <div className="pagination-wrap">
          <p>
            Showing{" "}
            {data.meta.current_page === 1
              ? data.meta.per_page
              : data.meta.total - data.meta.from + 1}{" "}
            result of {data.meta.total} records
          </p>
          <Pagination
            color="primary"
            count={data.meta.last_page}
            defaultPage={1}
            page={page}
            onChange={(e, value) => setPage(value)}
            shape="rounded"
          />
        </div>
      )}
    </>
  );
}

export default TablePagination;
