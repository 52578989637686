import {Button} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";

import InputField from "../../../components/Input";
import Textbox from "../../../components/Input/Textbox";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import UploadMultipleFile from "../../../components/UploadImgField/UploadMultipleFile";
import {useAddPromoMutation} from "../../../services/api";
import {toaster} from "../../../utils/utils";

import "./style.scss";
import {useState} from "react";
const AddPromo = () => {
  const methods = useForm();
  const [image, setImage] = useState([]);

  const [addEvent, {isLoading}] = useAddPromoMutation();
  const onSubmit = async data => {
    const payload = {
      ...data,
      image: image[0],
    };

    try {
      // call login trigger from rtk query
      await addEvent(payload).unwrap();
      // set user details and token in the state

      toaster("success", "Successful");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="pd_add_promo">
      <TitleWithBackBtn title="Create Promo" />

      <div className="pg_content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              type="text"
              name="name"
              placeholder="Enter Promo Title"
              label="Promo Title"
            />

            <Textbox
              placeholder={"Enter Promo Details"}
              name={"description"}
              label={"Promo Details"}
              errMsg={"invalid field"}
            />
            <UploadMultipleFile
              text="Upload Multiple Pictures"
              name="image"
              id="multi"
              folder="Mobile/Promos"
              labelText="Media"
              max={1}
              images={image}
              setImages={setImage}
            />

            <div className="btn-wrap">
              <Button type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn btnText="Create Promo" isLoading={isLoading} />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddPromo;
