import {Link, useMatch} from "react-router-dom";

export const CustomSideLink = ({children, to, baseUrl, ...props}) => {
  let match = useMatch(
    to !== "" ? `/${baseUrl}/` + to + "/*" : `/${baseUrl}/` + to
  );
  return (
    <Link className={`side-item ${match ? "active" : ""}`} to={to} {...props}>
      {children}
    </Link>
  );
};
