import {Stack} from "@mui/material";
import CustomerTable from "./CustomerTable";
import "../../MasterDataPages/Users/style.scss";
import {useExporterMutation} from "../../../services/api";
import {downloader, toaster} from "../../../utils/utils";
import {useState} from "react";
import SearchInput from "../../../components/SearchInput";
import {LoadingButton} from "@mui/lab";
const Customers = () => {
  const [getFile, {isLoading}] = useExporterMutation();
  const [search, setSearch] = useState("");
  const download = async () => {
    try {
      const response = await getFile({type: "customers"}).unwrap();

      downloader(response, "customers");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  return (
    <div className="users-pg table-pg-layout">
      <div className="heading">
        <div className="titleFlex">
          <h1 className="title">Customers</h1>
          <SearchInput setSearchVal={setSearch} />
        </div>
        <Stack
          justifyContent={"flex-end"}
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          width={"70%"}
        >
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="outlined"
            onClick={download}
            //   disabled={selected.length < 1}
            // sx={{minWidth: 150}}
          >
            Export
          </LoadingButton>
        </Stack>
      </div>
      <CustomerTable search={search} />
    </div>
  );
};

export default Customers;
