import BackBtn from "../BackBtn";
import "./style.scss";
const TitleWithBackBtn = ({title}) => {
  return (
    <div className="title-wrap">
      <BackBtn />
      <h1 className="pg-title">{title}</h1>
    </div>
  );
};

export default TitleWithBackBtn;
