import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { getComparator, stableSort } from "../../../utils/utils";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import { SubscribeDropDown } from "./index";
import LoadingTable from "../../../components/loadingTable";
import ErrorMsg from "../../../components/ErrorMsg";
import moment from "moment";

const headCells = [
  {
    id: "interested_in",
    label: "Interested In",
  },
  {
    id: "property_name",
    label: "Property Name",
  },
  {
    id: "name",
    label: "Full Name",
  },
  {
    id: "promo_name",
    label: "Promo Name",
  },

  {
    id: "phone",
    label: "Contact",
  },
  // {
  //   id: "status",
  //   label: "Status",
  // },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "action",
    label: "Action",
  },
];

export default function SubscribeTable({
  rows = [],
  isLoading,
  isError,
  error,
}) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        style={{ boxShadow: "none", borderRadius: "8px" }}
        sx={{ width: "100%", mb: 2 }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              align="left"
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="left">
                        <p
                          style={{
                            width: "max-content",
                            textTransform: "capitalize",
                          }}
                        >
                          {row.interested_in}
                        </p>{" "}
                      </TableCell>
                      <TableCell align="left">{row.property_name}</TableCell>
                      <TableCell align="left">{row.users_name}</TableCell>
                      <TableCell align="left">
                        {row.promo_name || "N/A"}
                      </TableCell>

                      <TableCell align="left">
                        {row.phone || row.email || "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row.created_at).format("L")}
                      </TableCell>
                      <TableCell className="action" align="left">
                        <SubscribeDropDown id={row.id} />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
