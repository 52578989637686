import {useState} from "react";
import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import "./style.scss";
import EachEstate from "../../../components/eachEstate";
import {useNavigate} from "react-router-dom";
import {Button, Pagination} from "@mui/material";
import {useGetAllPropertiesQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import EmptyResponsePage from "../../../components/EmptyResponsePage";
import FilterSelect from "../../../components/Input/FilterSelect";
import {FormProvider, useForm} from "react-hook-form";
import {convertObjToParams} from "../../../utils/utils";
import {states} from "../../../utils/data";

const PropertiesEstates = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState("");
  const [searchVal, setSearchVal] = useState("");

  const handleAdd = () => {
    navigate("/properties/estate/add");
  };

  const {
    data: estates = [],
    isFetching,
    isError,
    error,
  } = useGetAllPropertiesQuery({
    name: "estates",
    page,
    params,
    search: searchVal,
  });

  const handleFilterSubmit = data => {
    setParams(convertObjToParams(data));
  };
  // loading state
  if (isFetching) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="pd-estate pd-property">
      <PageTitleAddBtn
        title={"Estates"}
        isSearch={true}
        setSearchVal={setSearchVal}
        btnText={"Add Estate"}
        action={handleAdd}
      />

      <div className="productBox">
        <div className="filterSide">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFilterSubmit)}>
              <div className="selectFilter">
                <FilterSelect
                  label={"Location"}
                  name="location"
                  selectOption={[...states]}
                />
                <FilterSelect
                  label={"Status"}
                  name="property_status"
                  selectOption={["For Sale", "Sold Out"]}
                />
                {/* <FilterSelect
                  label={"Price"}
                  name="price"
                  selectOption={["one", "two", "three"]}
                /> */}
                <FilterSelect
                  label={"Tag"}
                  name="tag"
                  selectOption={["New", "Hot"]}
                />
                <Button type="submit" variant="outlined">
                  Apply Filter
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>

        <div className="productBox">
          {estates.data.length > 0 ? (
            <>
              {estates.data.map(item => {
                return <EachEstate item={item} key={item.id} />;
              })}
              {estates.meta.last_page > 1 && (
                <Pagination
                  color="primary"
                  count={estates.meta.last_page}
                  defaultPage={1}
                  page={page}
                  onChange={(e, value) => setPage(value)}
                  shape="rounded"
                />
              )}
            </>
          ) : (
            <EmptyResponsePage
              message={"Empty Result, Click 'Add Estate' To Add A New Estate"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertiesEstates;
