import "./style.scss";
import {Avatar} from "@mui/material";
const PersonCard = ({name, email, phone, head, avatar, children}) => {
  return (
    <div className="pd_card2">
      <div className="details">
        <Avatar className="avatar" src={avatar} alt="user" />
        <div className="right">
          <p className="name">{name}</p>
          <p className="name">{email}</p>
          <p className="name">{phone}</p>
        </div>
      </div>
      <div className="others">
        <div className="head">
          <p>{head}</p>
          <div className="line"></div>
        </div>

        {/* children should be like this */}
        {/* <div className="each">
          <p className="left">Intrested In:</p>
          <p className="right">Estate</p>
        </div> */}
        {children}
      </div>
    </div>
  );
};

export default PersonCard;
