import {Button, Grid, Pagination} from "@mui/material";
import EachAudit from "../../components/EachAudit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useExporterAuditMutation,
  useGetWhistleBlowersQuery,
} from "../../services/api";
import Loader from "../../components/Loader";
import ErrorMsg from "../../components/ErrorMsg";
import EmptyResponsePage from "../../components/EmptyResponsePage";
import {useState} from "react";
import {downloader, toaster} from "../../utils/utils";

const Audit = () => {
  const [page, setPage] = useState(1);

  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetWhistleBlowersQuery(page);

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="pd_audit">
      <div className="heading">
        <p className="pg-title">Whistle Blowing</p>
        {/* 
        <Button
          variant="outlined"
          onClick={download}
        >
          Export
        </Button> */}
      </div>
      {isSuccess && data.data.length > 0 ? (
        <>
          <Grid container spacing={4}>
            {data.data.map((item, i) => (
              <Grid key={"event_" + i} item xs={12} sm={12} md={6} lg={4}>
                <EachAudit data={item} />
              </Grid>
            ))}
          </Grid>
          {data.meta.last_page > 1 && (
            <Pagination
              color="primary"
              count={data.meta.last_page}
              defaultPage={1}
              page={page}
              onChange={(e, value) => setPage(value)}
              shape="rounded"
            />
          )}
        </>
      ) : (
        <EmptyResponsePage message={"No Whistle Blowers Are Available Yet"} />
      )}
    </div>
  );
};

export default Audit;
