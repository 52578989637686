import "./style.scss";
const EachResponse2 = ({ question, answer, faint }) => {
  return (
    <div className="eachResponse">
      <div className="questionSide">
        <p className={`questionText ${faint ? "faint" : ""}`}>{question}</p>
      </div>
      <div className="answerSide">
        <p className="answerText">{answer}</p>
      </div>
    </div>
  );
};

export default EachResponse2;
