export const products = [
  {
    id: 1,
    img: "/images/car.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: false,
  },
  {
    id: 2,
    img: "/images/phone.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: false,
  },
  {
    id: 3,
    img: "/images/rings.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: true,
  },
  {
    id: 4,
    img: "/images/house.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: false,
  },
];
export const privateDealProducts = [
  {
    id: 1,
    img: "/images/car.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: false,
  },
  {
    id: 2,
    img: "/images/phone.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: false,
  },
  {
    id: 3,
    img: "/images/rings.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: true,
  },
  {
    id: 4,
    img: "/images/house.png",
    price: "23,153,000",
    timeLeft: "04:53:07",
    productTitle: "Toyota Highlander 2015 Silver Limited 4dr SUV",
    isSpecial: false,
  },
];

export const selectOptions = name => [
  {
    label: `Select ${name}`,
    value: "",
  },
  {
    label: "Option 1",
    value: "option 1",
  },
  {
    label: "Option 2",
    value: "option 2",
  },
  {
    label: "Option 3",
    value: "option 3",
  },
];

export const states = [
  "All States",
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
