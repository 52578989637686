import Textbox from "../../../components/Input/Textbox";

const Req = ({display}) => {
  return (
    <div className="pd_rq" style={{display: display ? "block" : "none"}}>
      <Textbox
        placeholder={"Enter Requirements"}
        name={"requirements"}
        label={"Requirements"}
        errMsg={"invalid field"}
        extraClass={"extraHeight"}
      />
    </div>
  );
};

export default Req;
