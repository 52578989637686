import {Button} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import DeleteIcon from "@mui/icons-material/Delete";
import Carousel from "../../../components/Carousel";
import {useNavigate, useParams} from "react-router-dom";
import {useDeleteCsrMutation, useGetOneCSRQuery} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import moment from "moment";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
const ViewCsr = () => {
  const {id} = useParams();

  const navigate = useNavigate();
  const [deleteCsr] = useDeleteCsrMutation();

  const deleteHandler = async () => {
    try {
      const response = await deleteCsr(id).unwrap();
      if (response.status === "success") {
        toaster("success", response.message);
        navigate("/publish/csr");
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  const {data: csr = null, isLoading, isError, error} = useGetOneCSRQuery(id);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="pd_view_promo">
      <header className="heading">
        <TitleWithBackBtn title={"View CSR"} />
        <Button
          variant="contained"
          color="red20"
          endIcon={<DeleteIcon color="red" />}
          onClick={deleteHandler}
        >
          Delete
        </Button>
      </header>
      <div className="pg_content_view card">
        <div className="carousel">
          <Carousel>
            {csr &&
              csr.gallery.map((item, i) => {
                return <img key={i} src={item} alt="item" />;
              })}
          </Carousel>
        </div>
        <h3 className="name">{csr && csr.title}</h3>
        <div className="details">
          <p className="clr-textClr-40 promDet">Summary</p>
          <p className="story">{csr && csr.summary}</p>
          <br />
          <p className="clr-textClr-40 promDet">Publish Date</p>
          <p>{csr && moment(csr.created_at).format("L")}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewCsr;
