import {FormProvider, useForm} from "react-hook-form";
import {useState} from "react";
import {Button} from "@mui/material";
import "./style.scss";
import DropDownWrapper from "../../components/DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {IconButton} from "@mui/material";
import {Link} from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import {
  convertObjToParams,
  downloader,
  getComparator,
  stableSort,
  toaster,
} from "../../utils/utils";
import {
  useExporterMutation,
  useGetAllApplicationQuery,
  useGetAllVacancyQuery,
} from "../../services/api";
import LoadingTable from "../../components/loadingTable";
import ErrorMsg from "../../components/ErrorMsg";
import moment from "moment";
import TablePagination from "../../components/TablePagination";
import FilterSelect from "../../components/Input/FilterSelect";
import {states} from "../../utils/data";
import SearchInput from "../../components/SearchInput";
import InputField from "../../components/Input";

// dropdown
const SubscribeDropDown = ({id}) => (
  <DropDownWrapper
    className="more-actions"
    action={
      <IconButton className="more-action-btn" aria-label="actions">
        <MoreVertIcon />
      </IconButton>
    }
  >
    <Link to={`viewapplication/${id}`} className="btn-noBg">
      <VisibilityIcon color="iconColor" /> View Application
    </Link>
  </DropDownWrapper>
);
// tableData

const headCells = [
  {
    id: "fullname",
    label: "Application Name",
  },
  {
    id: "phone",
    label: "Phone Number",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "role",
    label: "Role",
  },
  {
    id: "highest_qualification",
    label: "Qualification",
  },
  {
    id: "state_of_residence",
    label: "Location",
  },
  {
    id: "created_at",
    label: "Date",
  },
  {
    id: "action",
    label: "Action",
  },
];

const HrApplication = () => {
  const methods = useForm();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState("");

  const {data: vacancy = null} = useGetAllVacancyQuery(page);
  const {
    data: application = [],
    isFetching: isLoading,
    isError,
    error,
  } = useGetAllApplicationQuery({
    page,
    params,
    search,
  });
  // table magic
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [getFile] = useExporterMutation();
  const download = async () => {
    try {
      const response = await getFile({type: "applications"}).unwrap();

      downloader(response, "applications");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  const handleFilterSubmit = data => {
    setParams(convertObjToParams(data));
  };

  return (
    <div className="hr_application table-pg-layout">
      <div className="heading">
        <h2 className="pg-title">Applications</h2>

        <div className="formSide">
          <SearchInput setSearchVal={setSearch} />

          <Button variant="outlined" onClick={download}>
            Export
          </Button>
        </div>
      </div>
      <div className="filterSide">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFilterSubmit)}>
            <div className="selectFilter">
              <FilterSelect
                label={"Gender"}
                name="gender"
                selectOption={["male", "female"]}
              />
              <FilterSelect
                label={"Role"}
                name="role"
                selectOption={
                  vacancy
                    ? vacancy.data.map(item => {
                        return {label: item.title, value: item.id};
                      })
                    : []
                }
              />
              <FilterSelect
                label={"Location"}
                name="location"
                selectOption={[...states]}
              />

              <InputField
                type="text"
                required={false}
                name="highest_qualification"
                placeholder="Highest Qualification"
                id="qualification"
              />
              <InputField
                type="date"
                required={false}
                name="date"
                placeholder=""
                id="date"
              />

              <Button type="submit" variant="outlined">
                Apply Filter
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="tableSide">
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={application.data.length}
              align="left"
            />
            <TableBody>
              {stableSort(application.data, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="left">{row.fullname}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.gender}</TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                      <TableCell align="left">
                        {row.highest_qualification}
                      </TableCell>
                      <TableCell align="left">
                        {row.state_of_residence}
                      </TableCell>

                      <TableCell align="left">
                        {moment(row.created_at).format("L")}
                      </TableCell>
                      <TableCell className="action" align="left">
                        <SubscribeDropDown id={row.id} />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination page={page} setPage={setPage} data={application} />
    </div>
  );
};

export default HrApplication;
