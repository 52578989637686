import SelectField from "../../../components/Input/SelectField";
import InputField from "../../../components/Input";
import {useForm, FormProvider} from "react-hook-form";
import {selectOptions} from "../../../utils/data";
import "./style.scss";
import {Switch, Button, FormControlLabel, FormGroup} from "@mui/material";
import {useState} from "react";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
const CreateRole = () => {
  const methods = useForm();
  const onSubmit = data => console.log(data);

  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <div className="create-role-pg">
      <TitleWithBackBtn title="Create Role" />
      <FormProvider {...methods}>
        <form
          className="create-role-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <SelectField
            name="roleType"
            label="Role Type"
            errMsg="invalid field"
            selectOption={selectOptions("Role Type")}
          />
          <InputField
            type="text"
            name="roleName"
            placeholder="Role Name"
            id="search"
            label="Role Name"
          />
          <div className="permissions-card">
            <FormGroup>
              <header className="card-header">
                <h3>Permissions</h3>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleChange} />}
                  labelPlacement="start"
                  label="Toggle All"
                />
              </header>
              <div className="card-body">
                <div className="card-row">
                  <p>Properties</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
                <div className="card-row">
                  <p>Sales</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
                <div className="card-row">
                  <p>Client Service</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
                <div className="card-row">
                  <p>Human Resource</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
                <div className="card-row">
                  <p>Audit</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
                <div className="card-row">
                  <p>Publish</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
                <div className="card-row">
                  <p>Master Data</p>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    labelPlacement="start"
                    label="Inactive"
                  />
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="btn-wrap">
            <Button
              type="reset"
              onClick={() => methods.reset()}
              variant="outlined"
            >
              Cancel
            </Button>
            <SubmitBtn btnText={"Create Role"} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateRole;
