import SideBar from "../../components/SideBar";
import NavBar from "../../components/Nav";
import {Outlet} from "react-router-dom";
import "./style.scss";
import MobileSidebar from "../../components/SideBar/MobileSideBar";
const DashBoardLayout = () => {
  return (
    <div className="dashboard-layout">
      <SideBar />
      <MobileSidebar />
      <div className="content-side">
        <NavBar />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashBoardLayout;
