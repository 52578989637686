import {Outlet} from "react-router-dom";
import {CustomSideLink} from "../../components/CustomSideLink";
import "./style.scss";

const MasterDataLayout = () => {
  return (
    <div className="pg-layout">
      <div className="drop-down-links">
        {/* <CustomSideLink baseUrl="master-data" to="">
          Properties
        </CustomSideLink> */}
        <CustomSideLink baseUrl="master-data" to="">
          Role Management
        </CustomSideLink>
        <CustomSideLink baseUrl="master-data" to="administrators">
          Administrators{" "}
        </CustomSideLink>
        {/* <CustomSideLink baseUrl="master-data" to="cps">
          CPS{" "}
        </CustomSideLink> */}
        {/* <CustomSideLink baseUrl="master-data" to="tnc">
          Terms and Conditions
        </CustomSideLink> */}
        <CustomSideLink baseUrl="master-data" to="faq">
          FAQ{" "}
        </CustomSideLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default MasterDataLayout;
