import {Button} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import DeleteIcon from "@mui/icons-material/Delete";
import Carousel from "../../../components/Carousel";
import {useNavigate, useParams} from "react-router-dom";
import {
  useDeleteLoyaltyMutation,
  useGetOneLoyaltyQuery,
} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import moment from "moment";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
const ViewLoyalty = () => {
  const {id} = useParams();

  const navigate = useNavigate();
  const [deleteLoyalty] = useDeleteLoyaltyMutation();

  const deleteHandler = async () => {
    try {
      const response = await deleteLoyalty(id).unwrap();
      if (response.status === "success") {
        toaster("success", response.message);
        navigate("/publish/loyalty");
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  const {
    data: loyalty = null,
    isLoading,
    isError,
    error,
  } = useGetOneLoyaltyQuery(id);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="pd_view_promo">
      <header className="heading">
        <TitleWithBackBtn title={"View Loyalty"} />
        <Button
          variant="contained"
          color="red20"
          endIcon={<DeleteIcon color="red" />}
          onClick={deleteHandler}
        >
          Delete
        </Button>
      </header>
      <div className="pg_content_view card">
        <div className="carousel">
          <Carousel>
            {loyalty &&
              loyalty.gallery.map((item, i) => {
                return <img key={i} src={item} alt="item" />;
              })}
          </Carousel>
        </div>
        <h3 className="name">{loyalty && loyalty.title}</h3>
        <div className="details">
          <p className="clr-textClr-40 promDet">Summary</p>
          <p className="story">{loyalty && loyalty.summary}</p>
          <br />
          <p className="clr-textClr-40 promDet">Publish Date</p>
          <p>{loyalty && moment(loyalty.created_at).format("L")}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewLoyalty;
