import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import "./style.scss";
import {useNavigate, useParams} from "react-router-dom";
import {
  useDeleteVacancyMutation,
  useGetOneVacancyQuery,
} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import {toaster} from "../../../utils/utils";
import moment from "moment";
const ViewVacancy = () => {
  const {id} = useParams();
  const {
    data: vacancy = null,
    isLoading,
    isError,
    error,
  } = useGetOneVacancyQuery(id);
  const navigate = useNavigate();
  const [deleteFaq] = useDeleteVacancyMutation();
  const deleteHandler = async id => {
    try {
      const response = await deleteFaq(id).unwrap();
      if (response.status === "success") toaster("success", response.message);
      setTimeout(() => {
        navigate("/hr/vacancy");
      }, 1000);
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_view_promo">
      <header className="heading">
        <TitleWithBackBtn title="View Vacancy" />
        <Button
          variant="contained"
          color="red0"
          endIcon={<DeleteIcon color="red" />}
          className="delBtn"
          onClick={() => {
            deleteHandler(id);
          }}
        >
          Delete
        </Button>
      </header>

      <div className="pg_content_view">
        <img src={vacancy && vacancy.image} alt="prom" />
        <div className="nameFlex">
          <p className="name">{vacancy && vacancy.title}</p>
          <p className="date">
            {vacancy && moment(vacancy.created_at).format("L")}
          </p>
        </div>

        <div className="details">
          <p className="promDet"> Description</p>
          <p className="story">{vacancy && vacancy.description}</p>
        </div>
        <div className="details">
          <p className="promDet"> Requirements</p>
          <p className="story">{vacancy && vacancy.requirments}</p>
        </div>
        <div className="details">
          <p className="promDet"> Benefits</p>
          <p className="story">{vacancy && vacancy.benefits}</p>
        </div>
        <div className="prom_publish">
          <p className="promDet">Closing Date</p>
          <p className="story">{vacancy && vacancy.closing_date}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewVacancy;
