import { Outlet } from "react-router-dom";
import { CustomSideLink } from "../../components/CustomSideLink";
import "../MasterDataLayout/style.scss";

const PropertiesLayout = () => {
  return (
    <div className="pg-layout">
      <div className="drop-down-links">
        <CustomSideLink baseUrl={"publish"} to="">
          Events
        </CustomSideLink>
        <CustomSideLink baseUrl={"publish"} to="promos">
          Promos
        </CustomSideLink>
        <CustomSideLink baseUrl={"publish"} to="csr">
          CSR{" "}
        </CustomSideLink>
        <CustomSideLink baseUrl={"publish"} to="loyalty">
          Loyalty{" "}
        </CustomSideLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default PropertiesLayout;
