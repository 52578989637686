import {IconButton} from "@mui/material";
import DropDownWrapper from "../DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import link from "../../assets/icons/link.svg";
import "./style.scss";
import moment from "moment";
import {useExporterAuditMutation} from "../../services/api";
import {downloader, toaster} from "../../utils/utils";
const EachAudit = ({data}) => {
  const [getFile] = useExporterAuditMutation();
  const download = async id => {
    try {
      const response = await getFile(id).unwrap();
      downloader(response, "Audit");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  return (
    <div className="pd_eachAudit">
      <div className="line"></div>
      <div className="titleSide">
        <div className="flex">
          <p className="left">{data.user_name || "Anonymous"}</p>
          <p className="right">
            {moment(data.created_at).format("DD-MM-YYYY")}
          </p>
        </div>
        <DropDownWrapper
          className="more-actions"
          action={
            <IconButton className="more-action-btn" aria-label="actions">
              <MoreVertIcon color="primary" />
            </IconButton>
          }
        >
          {/* <Link to={`view/`} className="btn-noBg dropBtn">
            <VisibilityIcon color="iconColor2" /> View Sender
          </Link> */}

          <button
            onClick={() => {
              download(data.id);
            }}
            className="btn-noBg dropBtn"
          >
            <FileUploadIcon color="iconColor2" /> Export
          </button>
          {/* <button
            onClick={() => {
              deleteHandler(id);
            }}
            className="btn-noBg dropBtn"
          >
            <DeleteIcon color="iconColor2" /> Delete
          </button> */}
        </DropDownWrapper>
      </div>
      <div className="messageSide">
        <p className="left">Message</p>
        <p className="right">{data.message}</p>
      </div>
      <a href={data.document || ""} download={true} className="linkBox">
        <img src={link} alt="link" />
        Document Attached
      </a>
    </div>
  );
};

export default EachAudit;
