import {Grid, IconButton} from "@mui/material";
import Carousel from "../../../components/Carousel";
import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./style.scss";
import DropDownWrapper from "../../../components/DropDownWrapper";
import {Link} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useActivateEventMutation,
  useDeleteEventMutation,
  useGetAllEventQuery,
} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import {toaster} from "../../../utils/utils";
import {useState} from "react";
import EmptyResponsePage from "../../../components/EmptyResponsePage";
import TablePagination from "../../../components/TablePagination";
import {LoadingButton} from "@mui/lab";

const EachEvent = ({name, summary, date, id, status, gallery}) => {
  const [deleteEvent, {isLoading}] = useDeleteEventMutation();
  const [activateEvent] = useActivateEventMutation();

  const deleteHandler = async id => {
    if (status === "0") {
      try {
        const response = await activateEvent(id).unwrap();
        if (response.status === "success") toaster("success", response.message);
      } catch (err) {
        if (err.status === "FETCH_ERROR")
          toaster("error", "Something went wrong, please try again...");
        else toaster("error", err.data.message);
      }
    } else {
      try {
        const response = await deleteEvent(id).unwrap();
        if (response.status === "success") toaster("success", response.message);
      } catch (err) {
        if (err.status === "FETCH_ERROR")
          toaster("error", "Something went wrong, please try again...");
        else toaster("error", err.data.message);
      }
    }
  };

  return (
    <div className="card event-card">
      <Carousel showArrows={false}>
        {gallery.map((el, item) => (
          <div key={el} className="card-media">
            <img src={el} alt="event" />
          </div>
        ))}
      </Carousel>
      <DropDownWrapper
        className="more-actions"
        action={
          <IconButton className="more-action-btn" aria-label="actions">
            <MoreVertIcon />
          </IconButton>
        }
      >
        <Link to={`view/${id}`} className="btn-noBg">
          <VisibilityIcon color="iconColor" /> View Event
        </Link>
        <LoadingButton
          onClick={() => {
            deleteHandler(id);
          }}
          startIcon={<DeleteIcon color="red" />}
          loading={isLoading}
          loadingPosition="start"
          disabled={isLoading}
        >
          <span className="clr-red-100">Delete Event</span>
        </LoadingButton>
      </DropDownWrapper>
      <div className="card-body">
        <h4>{name}</h4>
        <p className="clr-textClr-40">Summary</p>
        <p className="summary-txt">{summary}</p>
        <p className="publish-date">
          <span className="clr-textClr-40">Event Date:</span>{" "}
          <span>{date}</span>
        </p>
      </div>
    </div>
  );
};

const Events = () => {
  const [page, setPage] = useState(1);

  const {
    data: event = [],
    isLoading,
    isError,
    error,
  } = useGetAllEventQuery(page);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="event-pg">
      <PageTitleAddBtn
        title={"Events"}
        btnText={"Publish Event"}
        to={"event/add"}
      />
      {event.data.length ? (
        <>
          <Grid container mt={1} spacing={4}>
            {event.data.map(item => {
              return (
                <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
                  <EachEvent
                    name={item.title}
                    summary={item.description}
                    date={item.event_date}
                    id={item.id}
                    gallery={item.gallery}
                  />
                </Grid>
              );
            })}
          </Grid>
          <TablePagination page={page} setPage={setPage} data={event} />
        </>
      ) : (
        <EmptyResponsePage
          message={
            "No Event has Created Yet, Click 'Add Event' To Add A New Series"
          }
        />
      )}
    </div>
  );
};

export default Events;
