import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut} from "../store/slice/AuthSlice";
import {toaster} from "../utils/utils";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, {getState}) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // logout
    toaster(
      "error",
      "Looks like your session has expired, please login again."
    );
    setTimeout(() => {
      api.dispatch(logOut());
    }, 1000);
  }
  return result;
};

export const authApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "authApi",
  tagTypes: [
    "faqs",
    "promo",
    "events",
    "master_data",
    "properties",
    "vacancy",
    "admin",
    "office",
    "marketer",
    "product",
    "csr",
    "loyalty",
  ],
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: "v1/admin/login",
        method: "POST",
        body: credentials,
      }),
    }),
    changePassword: builder.mutation({
      query: ({body, id}) => ({
        url: `admin/auth/v1/change/admin/password/${id || ""}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["admin"],
    }),
    changeDp: builder.mutation({
      query: body => ({
        url: `admin/auth/v1/update/display/picture`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["admin"],
    }),
    editProfile: builder.mutation({
      query: body => ({
        url: `admin/auth/v1/edit/admin/profile`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["admin"],
    }),
    getAllVacancy: builder.query({
      query: page => `admin/auth/v1/get/all/vacancy?page=${page}`,
      providesTags: ["vacancy"],
    }),
    getOneVacancy: builder.query({
      query: id => `admin/auth/v1/get/vacancy/by/${id}`,
      providesTags: ["vacancy"],
      transformResponse: response => response.data,
    }),

    deleteVacancy: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/vacancy/by/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["vacancy"],
    }),
    getAllProperties: builder.query({
      query: ({name, page, params, search}) =>
        `admin/auth/v1/get/all/${name}?page=${page}&${params}&search=${search}`,
      providesTags: ["properties"],
    }),
    getOneProperty: builder.query({
      query: path => `admin/auth/v1/get/${path}`,
      providesTags: ["properties"],
      transformResponse: response => response.data,
    }),
    editProperty: builder.mutation({
      query: credentials => ({
        url: `admin/auth/v1/edit/${credentials.url}`,
        method: "POST",
        body: credentials.body,
      }),
      invalidatesTags: ["properties"],
    }),
    addEstate: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/estate",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["properties"],
    }),
    addHousing: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/home/series",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["properties"],
    }),
    deleteEstate: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/estate/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["properties"],
    }),
    deleteHouse: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/home/series/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["properties"],
    }),
    deleteProduct: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/product/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["properties"],
    }),
    restoreEstate: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/restore/deleted/estate",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["properties"],
    }),
    getDashboard: builder.query({
      query: () => "admin/auth/v1/dashboard",
      providesTags: ["dashboard"],
      transformResponse: response => response,
    }),
    // admin
    getAdmin: builder.query({
      query: ({page, search}) =>
        `admin/auth/v1/get/all/admins?page=${page}&search=${search}`,
      providesTags: ["admin"],
      transformResponse: response => response,
    }),
    getOneAdmin: builder.query({
      query: id => `admin/auth/v1/get/admin/${id || ""}`,
      providesTags: ["admin"],
      transformResponse: response => response,
    }),
    addAdmin: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/admin",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["admin"],
    }),
    deleteAdmin: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/admin/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["admin"],
    }),

    changeAdminRole: builder.mutation({
      query: ({credentials, id}) => ({
        url: `admin/auth/v1/change/admin/role/${id}`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["admin"],
    }),

    // marketer
    getMarketer: builder.query({
      query: (page = 1) => `admin/auth/v1/all/marketers?page=${page}`,
      providesTags: ["marketer"],
    }),
    getOneMarketer: builder.query({
      query: id => `admin/auth/v1/get/admin/${id}`,
      providesTags: ["marketer"],
      transformResponse: response => response,
    }),

    getAllFaqs: builder.query({
      query: () => "admin/auth/v1/get/all/faqs",
      providesTags: ["faqs"],
      transformResponse: response => response.data,
    }),
    getAllPromo: builder.query({
      query: page => `admin/auth/v1/get/all/promos?page=${page}`,
      providesTags: ["promo"],
    }),
    getOnePromo: builder.query({
      query: id => `admin/auth/v1/get/promo/${id}`,
      providesTags: ["promo"],
      transformResponse: response => response.data,
    }),
    addPromo: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/promo",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["promo"],
    }),
    deactivatePromo: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/deactivate/promo/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["promo"],
    }),
    deletePromo: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/promo/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["promo"],
    }),
    activatePromo: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/activate/promo/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["promo"],
    }),
    getAllEvent: builder.query({
      query: (page = 1) => `admin/auth/v1/get/all/events?page=${page}`,
      providesTags: ["event"],
    }),
    getOneEvent: builder.query({
      query: id => `admin/auth/v1/get/event/by/${id}`,
      providesTags: ["event"],
      transformResponse: response => response.data,
    }),
    getWhistleBlowers: builder.query({
      query: page => `admin/auth/v1/all/whistle/blower?page=${page}`,
    }),
    getAllCustomers: builder.query({
      query: ({page, search}) =>
        `admin/auth/v1/all/customers?page=${page}&search=${search}`,
    }),
    getSingleCustomer: builder.query({
      query: id => `admin/auth/v1/get/customer/by/${id}`,
      transformResponse: response => response.data,
    }),
    deleteEvent: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/event/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["event"],
    }),
    activateEvent: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/activate/event",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["event"],
    }),
    addEvent: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/event",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["event"],
    }),
    addFaq: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/faq",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["faqs"],
    }),
    editFaq: builder.mutation({
      query: credentials => ({
        url: `admin/auth/v1/edit/faq/${credentials.faq_id}`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["faqs"],
    }),
    deleteFaq: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/faq/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["faqs"],
    }),
    addPropertyMD: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/create/property/master/data",
        method: "POST",
        body: credentials,
      }),
      // invalidatesTags: [""],
    }),
    createVacancy: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/create/vacancy",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["vacancy"],
    }),

    addCsr: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/csr",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["csr"],
    }),
    deleteCsr: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/csr/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["csr"],
    }),
    getAllCSR: builder.query({
      query: page => `admin/auth/v1/all/csr?page=${page}`,
      providesTags: ["csr"],
    }),
    getOneCSR: builder.query({
      query: id => `admin/auth/v1/get/csr/by/${id}`,
      transformResponse: response => response.data,
    }),

    addLoyalty: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/add/loyalty",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["loyalty"],
    }),
    deleteLoyalty: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/delete/loyalty/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["loyalty"],
    }),
    getAllLoyalty: builder.query({
      query: page => `admin/auth/v1/all/loyalty?page=${page}`,
      providesTags: ["loyalty"],
    }),
    getOneLoyalty: builder.query({
      query: id => `admin/auth/v1/get/loyalty/by/${id}`,
      transformResponse: response => response.data,
    }),

    addProduct: builder.mutation({
      query: credentials => ({
        url: "/admin/auth/v1/add/product",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["product"],
    }),
    getAllOffices: builder.query({
      query: () => `admin/auth/v1/all/office/location`,
      providesTags: ["office"],
      transformResponse: response => response.data,
    }),
    deleteOffice: builder.mutation({
      query: id => ({
        url: `/admin/auth/v1/delete/office/location/by/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["office"],
    }),
    editOffice: builder.mutation({
      query: credentials => ({
        url: `/admin/auth/v1/edit/office/location/${credentials.id}`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["office"],
    }),
    getAllFeedback: builder.query({
      query: ({page, search, params}) =>
        `admin/auth/v1/all/feedbacks?page=${page}&search=${search}&${params}`,
    }),
    getOneFeedback: builder.query({
      query: id => `admin/auth/v1/get/particular/feedback/${id}`,
      transformResponse: response => response.data,
    }),

    getAllApplication: builder.query({
      query: ({page, search, params}) =>
        `admin/auth/v1/all/vacancy/applications?page=${page}&search=${search}&${params}`,
    }),
    getOneApplication: builder.query({
      query: id => `admin/auth/v1/get/vacancy/applications/by/${id}`,
      transformResponse: response => response.data,
    }),

    getAllSubscribe: builder.query({
      query: ({page, search, params}) =>
        `admin/auth/v1/all/requests?page=${page}&search=${search}&${params}`,
    }),
    getAllLeeds: builder.query({
      query: ({page, search, params}) =>
        `admin/auth/v1/all/leads?page=${page}&search=${search}&${params}`,
    }),
    exporter: builder.mutation({
      query: ({type}) => ({
        url: `admin/auth/v1/export/${type}`,
        responseHandler: response =>
          response.blob().then(blob => URL.createObjectURL(blob)),
        method: "POST",
      }),
    }),

    exporterAudit: builder.mutation({
      query: id => ({
        url: `admin/auth/v1/get/whistle/blower/doc/${id}`,
        responseHandler: response =>
          response.blob().then(blob => URL.createObjectURL(blob)),
        method: "POST",
      }),
    }),

    getOneSubscribe: builder.query({
      query: id => `admin/auth/v1/get/particular/request/${id}`,
      transformResponse: response => response.data,
    }),

    addIndividualOffice: builder.mutation({
      query: credentials => ({
        url: "admin/auth/v1/create/office/location",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["office"],
    }),
  }),
});
export const {
  useGetMarketerQuery,
  useGetAllLoyaltyQuery,
  useAddLoyaltyMutation,
  useGetOneLoyaltyQuery,
  useDeleteLoyaltyMutation,
  useExporterMutation,
  useExporterAuditMutation,
  useGetOneCSRQuery,
  useGetAllLeedsQuery,
  useGetOneMarketerQuery,
  useGetAdminQuery,
  useDeleteHouseMutation,
  useGetOneAdminQuery,
  useAddAdminMutation,
  useDeleteProductMutation,
  useDeleteAdminMutation,
  useDeleteOfficeMutation,
  useEditOfficeMutation,
  useGetAllSubscribeQuery,
  useGetOneSubscribeQuery,
  useGetAllApplicationQuery,
  useGetOneApplicationQuery,
  useGetAllFeedbackQuery,
  useGetOneFeedbackQuery,
  useLoginMutation,
  useGetAllFaqsQuery,
  useAddFaqMutation,
  useEditFaqMutation,
  useDeleteFaqMutation,
  useGetAllPromoQuery,
  useActivatePromoMutation,
  useDeletePromoMutation,
  useDeactivatePromoMutation,
  useGetOnePromoQuery,
  useAddPromoMutation,
  useGetAllEventQuery,
  useGetOneEventQuery,
  useActivateEventMutation,
  useDeleteEventMutation,
  useAddIndividualOfficeMutation,
  useAddProductMutation,
  useEditPropertyMutation,
  useAddEventMutation,
  useAddPropertyMDMutation,
  useAddCsrMutation,
  useGetDashboardQuery,
  useAddHousingMutation,
  useAddEstateMutation,
  useDeleteEstateMutation,
  useRestoreEstateMutation,
  useGetAllPropertiesQuery,
  useGetOnePropertyQuery,
  useGetWhistleBlowersQuery,
  useGetAllCustomersQuery,
  useGetSingleCustomerQuery,
  useCreateVacancyMutation,
  useGetAllOfficesQuery,
  useGetAllCSRQuery,
  useDeleteCsrMutation,
  useDeleteVacancyMutation,
  useGetAllVacancyQuery,
  useChangeAdminRoleMutation,
  useGetOneVacancyQuery,
  useChangePasswordMutation,
  useChangeDpMutation,
  useEditProfileMutation,
} = authApi;
