import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import "./style.scss";

const EachStateItem = ({office, loadEdit, deleteOfficeHandle}) => {
  return (
    <div className="pd_eachState">
      <div className="topTile">
        <p className="topic">{office.office_name}</p>
        <div className="btnRound">
          <div className="round">
            <IconButton
              onClick={() => {
                deleteOfficeHandle(office.id);
              }}
              aria-label="delete"
            >
              <DeleteIcon color="red" sx={{fontSize: 16}} />
            </IconButton>
          </div>
          <div className="round1">
            <IconButton
              onClick={() => {
                loadEdit(
                  office.state,
                  office.office_name,
                  office.office_address,
                  office.office_email,
                  office.office_phone_no,

                  office.id
                );
              }}
              aria-label="delete"
            >
              <EditIcon sx={{fontSize: 16}} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="btmTile">
        <div className="each">
          <LocationOnIcon sx={{fontSize: 16}} color="iconColor2" />
          <p>{office.office_address}</p>
        </div>
        <div className="each">
          <EmailIcon sx={{fontSize: 16}} color="iconColor2" />
          <p>{office.office_email}</p>
        </div>
        <div className="each">
          <PhoneIcon sx={{fontSize: 16}} color="iconColor2" />
          <p>{office.office_phone_no}</p>
        </div>
      </div>
    </div>
  );
};

export default EachStateItem;
