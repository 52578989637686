import {Button} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";

import InputField from "../../../components/Input";
import Textbox from "../../../components/Input/Textbox";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import UploadMultipleFile from "../../../components/UploadImgField/UploadMultipleFile";
import {useAddEventMutation} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import {useState} from "react";
import "./style.scss";
import {useNavigate} from "react-router-dom";
const AddEvent = () => {
  const methods = useForm();
  const [addEvent, {isLoading}] = useAddEventMutation();
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();

  const onSubmit = async data => {
    const payload = {
      ...data,
      gallery: gallery,
    };
    try {
      // call login trigger from rtk query
      await addEvent(payload).unwrap();
      // set user details and token in the state

      toaster("success", "Successful");
      navigate("/publish/");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="pd_add_promo">
      <TitleWithBackBtn title="Create Event" />

      <div className="pg_content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              type="text"
              name="title"
              placeholder="Enter Event Title"
              label="Event Title"
            />
            <InputField
              type="text"
              name="address"
              placeholder="Enter Event Address"
              label="Event Address"
            />
            <InputField
              type="datetime-local"
              name="schedule"
              placeholder="Enter Event Time"
              label="Event Time"
            />

            <Textbox
              placeholder={"Enter Summary"}
              name={"description"}
              label={"Event Summary"}
              errMsg={"invalid field"}
            />
            <UploadMultipleFile
              text="Upload Multiple Pictures"
              name="gallery"
              id="multi"
              folder="Mobile/Events"
              labelText="Media"
              max={10}
              images={gallery}
              setImages={setGallery}
            />
            <div className="btn-wrap">
              <Button type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn btnText="Publish" isLoading={isLoading} />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddEvent;
