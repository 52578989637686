import {FormProvider, useForm} from "react-hook-form";
import InputField from "../../../components/Input";
import Textbox from "../../../components/Input/Textbox";
import {Button} from "@mui/material";
import "./style.scss";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import {useState} from "react";
import Modal from "../../../components/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  useGetAllFaqsQuery,
  useAddFaqMutation,
  useEditFaqMutation,
  useDeleteFaqMutation,
} from "../../../services/api";
import Loader from "../../../components/Loader";
import {toaster} from "../../../utils/utils";
import ErrorMsg from "../../../components/ErrorMsg";
import {LoadingButton} from "@mui/lab";

const FAQ = () => {
  const {data: faqs = [], isLoading, isError, error} = useGetAllFaqsQuery();
  const [addFaq, {isLoading: isAddLoading}] = useAddFaqMutation();
  const [editFaq, {isLoading: isEditLoading}] = useEditFaqMutation();
  const [deleteFaq, {isLoading: isDeleteLoading}] = useDeleteFaqMutation();

  const methods = useForm({
    mode: "all",
  });
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState("");
  const editMethods = useForm({
    mode: "all",
  });

  const closeModal = () => {
    setModal(!modal);
    methods.reset();
  };

  const closeEditModal = () => {
    setEditModal(!editModal);
  };

  // add faq handler
  const onSubmit = async data => {
    try {
      const response = await addFaq(data).unwrap();
      if (response.status === "success") toaster("success", "Faq Added");
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  const onEditSubmit = async (data, id) => {
    const payload = {
      faq_id: id,
      title: data.title,
      description: data.description,
    };
    try {
      const response = await editFaq(payload).unwrap();
      if (response.status === "success") toaster("success", "Faq Edited");
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  const deleteFaqHandle = async id => {
    try {
      const response = await deleteFaq(id).unwrap();
      if (response.status === "success") toaster("success", "Faq Deleted");
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="pd_faq">
      <div className="heading">
        <p className="pg-title">Frequently Asked Questions</p>
        <Button onClick={closeModal} variant="contained">
          Add FAQ
        </Button>
      </div>
      <Modal openModal={modal} closeModal={closeModal}>
        <h2>Add Faq</h2>
        <FormProvider {...methods}>
          <form
            className="createUserForm"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div style={{width: "680px"}}>
              <InputField
                type="text"
                name={`title`}
                placeholder="Enter Question"
                label={"Question"}
                errMsg="invalid question input"
              />
              <Textbox
                placeholder={"Enter Answer"}
                name={`description`}
                label={`Answer`}
                errMsg={"This field is required"}
              />
            </div>

            <div className="btn-wrap">
              <Button onClick={closeModal} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn
                isLoading={isAddLoading}
                disabled={!methods.formState.isValid}
                btnText={"Add Faq"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal openModal={editModal} closeModal={closeEditModal}>
        <h2>Edit Faq</h2>
        <FormProvider {...editMethods}>
          <form
            className="createUserForm"
            onSubmit={editMethods.handleSubmit(data =>
              onEditSubmit(data, editId)
            )}
          >
            <div style={{width: "680px"}}>
              <InputField
                type="text"
                name={`title`}
                placeholder="Enter Question"
                label={"Question"}
                errMsg="invalid question input"
              />
              <Textbox
                placeholder={"Enter Answer"}
                name={`description`}
                label={`Answer`}
              />
            </div>

            <div className="btn-wrap">
              <Button onClick={closeEditModal} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn
                disabled={!editMethods.formState.isValid}
                btnText={"Save Changes"}
                isLoading={isEditLoading}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
      <div className="accordion_cover">
        {faqs.map((faq, i) => {
          const {title, description, id} = faq;
          return (
            <Accordion key={id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="accTop">{title}</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="accInn">{description}</p>
                <div className="buttonBox">
                  <Button
                    onClick={() => {
                      editMethods.setValue("title", title);
                      editMethods.setValue("description", description);
                      setEditId(id);
                      closeEditModal();
                    }}
                    color="textClr"
                    variant="text"
                  >
                    Edit
                  </Button>
                  <LoadingButton
                    loading={isDeleteLoading}
                    onClick={() => {
                      deleteFaqHandle(id);
                    }}
                    color="red"
                    variant="text"
                  >
                    Delete
                  </LoadingButton>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <div className="coverForm"></div>
    </div>
  );
};

export default FAQ;
