import {Link, useMatch} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/icons/adronLogo.svg";
import {ReactComponent as Prop} from "../../assets/icons/sidebar/prop.svg";
import {ReactComponent as Sale} from "../../assets/icons/sidebar/sale.svg";
import {ReactComponent as Client} from "../../assets/icons/sidebar/client.svg";
import {ReactComponent as Search} from "../../assets/icons/sidebar/search.svg";
import {ReactComponent as Eye} from "../../assets/icons/sidebar/eye.svg";
import {ReactComponent as Publish} from "../../assets/icons/sidebar/publish.svg";
import {ReactComponent as Master} from "../../assets/icons/sidebar/master.svg";
import {ReactComponent as Dash} from "../../assets/icons/sidebar/dash.svg";
import "./style.scss";

function CustomLink({children, to, ...props}) {
  let match = useMatch(to !== "" ? `/` + to + "/*" : to);
  return (
    <Link className={`item ${match ? "active" : ""}`} to={to} {...props}>
      {children}
    </Link>
  );
}

const SideBar = () => {
  return (
    <div className="sidebar dk-sidebar">
      <div className="inner">
        <Logo className="logo" />

        <CustomLink to="">
          <Dash className="iconSide" />
          <p>Dashboard</p>
        </CustomLink>
        <div className="line"></div>

        <CustomLink to="properties">
          <Prop className="iconSide" />
          <p>Properties</p>
        </CustomLink>
        <CustomLink to="sales">
          <Sale className="iconSide" />
          <p>Sales</p>
        </CustomLink>
        <CustomLink to="client-services">
          <Client className="iconSide" />
          <p>Client Service</p>
        </CustomLink>
        <CustomLink to="hr">
          <Search className="iconSide" />
          <p>Human Resource</p>
        </CustomLink>
        <CustomLink to="audit">
          <Eye className="iconSide" />
          <p>Audit</p>
        </CustomLink>
        <CustomLink to="publish">
          <Publish className="iconSide" />
          <p>Publish</p>
        </CustomLink>
        <div className="line ext"></div>
        <CustomLink to="master-data">
          <Master className="iconSide" />
          <p>Master Data</p>
        </CustomLink>
      </div>
    </div>
  );
};

export default SideBar;
