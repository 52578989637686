import "./style.scss";
import Carousel from "../../../components/Carousel";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import {
  useDeleteProductMutation,
  useGetOnePropertyQuery,
} from "../../../services/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {toaster} from "../../../utils/utils";
import {LoadingButton} from "@mui/lab";
import moment from "moment";

const ViewEachProduct = () => {
  const {id} = useParams();
  const {
    data: product,
    isLoading,
    isError,
    error,
  } = useGetOnePropertyQuery(`product/by/${id}`);
  const navigate = useNavigate();
  const [deleteProduct, {isLoading: delLoading}] = useDeleteProductMutation();
  const deleteHandler = async () => {
    try {
      // call login trigger from rtk query
      const response = await deleteProduct(id).unwrap();

      toaster("success", response.message);
      navigate("/properties/products");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd-view">
      <div className="heading topSide">
        <TitleWithBackBtn title="View Product" />
        <div className="btn-wrap">
          <Button
            component={Link}
            to={`/properties/products/edit/${id}`}
            variant="contained"
            color="primary20"
            endIcon={<EditIcon color="primary" />}
          >
            Edit
          </Button>
          <LoadingButton
            variant="contained"
            color="red0"
            endIcon={<DeleteIcon color="red" />}
            loading={delLoading}
            loadingPosition="end"
            className="delBtn"
            onClick={deleteHandler}
          >
            Delete
          </LoadingButton>
        </div>
      </div>
      <div className="aboutEstate">
        <div className="carousel">
          <Carousel>
            {product?.gallery?.map((el, i) => (
              <img key={"image-" + i} src={el} alt={"image-" + i} />
            ))}
          </Carousel>
        </div>
        <div className="estateAbout">
          <p className="name">{product?.name}</p>
          {/* <p className="price">{toCurrency(product?.amount)}</p> */}
        </div>
        <div className="hotSide">
          <p className={product?.tag.toLowerCase()}>{product?.tag}</p>
          <p className={"blue"}>Product</p>
          <p className={product?.property_status.split(" ")[0].toLowerCase()}>
            {product?.property_status}
          </p>
        </div>

        <div className="desc">
          <p className="head">Description</p>
          <p className="story">{product?.description}</p>
        </div>

        <div className="date">
          <p className="head">Publish Date</p>
          <p className="dateText">
            {moment(product?.created_at).format("Do MMMM YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewEachProduct;
