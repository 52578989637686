import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Demo from "./pages/Demo";
import FAQ from "./pages/MasterDataPages/Faq";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import MasterDataRoles from "./pages/MasterDataPages/Roles";
import CreateRole from "./pages/MasterDataPages/CreateRole";
import DashBoardLayout from "./templates/DashBoardLayout/";
import MasterDataLayout from "./templates/MasterDataLayout";
import PropertiesLayout from "./templates/PropertiesLayout";
import PublishLayout from "./templates/PublishLayout";
import Users from "./pages/MasterDataPages/Users";
import CPS from "./pages/MasterDataPages/CPS";
import AddCPS from "./pages/MasterDataPages/CPS/AddCPS";
import CPSDetails from "./pages/MasterDataPages/CPS/CPSDetails";
import Profile from "./pages/Profile";
import {useGetUser} from "./hooks/getUserHook";
import PropertiesEstates from "./pages/Properties/Estates";
import AddEstate from "./pages/Properties/Estates/AddEstate";
import EditEstate from "./pages/Properties/Estates/EditEstate";
import PropertiesHousing from "./pages/Properties/Housing";
import PropertiesProduct from "./pages/Properties/Products";
import ViewEstate from "./pages/Properties/Estates/view";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Publish/Events";
import Promos from "./pages/Publish/Promos";
import CSR from "./pages/Publish/CSR";
import AddPromo from "./pages/Publish/Promos/addPromo";
import ViewPromo from "./pages/Publish/Promos/viewPromo";
import AddEvent from "./pages/Publish/Events/AddEvent";
import ViewEvent from "./pages/Publish/Events/ViewEvent";

import SubscribeForm from "./pages/Sales/SubscribeForm";
import Marketers from "./pages/Sales/Marketers";
import SalesLayout from "./templates/SalesLayout";
import AuditLayout from "./templates/AuditLayout";
import Audit from "./pages/Audit";
import AddCsr from "./pages/Publish/CSR/AddCsr";
import ViewCsr from "./pages/Publish/CSR/ViewCsr";
import HrLayout from "./templates/HrLayout";
import HrApplication from "./pages/Hr";
import ViewApplication from "./pages/Hr/viewApplication";
import Vacancy from "./pages/Hr/vacancy";
import ViewVacancy from "./pages/Hr/vacancy/viewVacancy";
import CreateVacancy from "./pages/Hr/vacancy/createVacancy";
import Office from "./pages/ClientServices/Office";
import ClientServiceLayout from "./templates/ClientServiceLayout";
import Leads from "./pages/Sales/Leads";
import AddProduct from "./pages/Properties/Products/AddProduct";
import AddHousing from "./pages/Properties/Housing/AddHousing";
import Feedback from "./pages/ClientServices/Feedback";
import ViewFeedback from "./pages/ClientServices/Feedback/ViewFeedback";
import Customers from "./pages/ClientServices/Customers";
import ViewCustomer from "./pages/ClientServices/Customers/ViewCustomer";
import ViewMarketer from "./pages/Sales/Marketers/ViewMarketer";
import ViewSubscribeForm from "./pages/Sales/SubscribeForm/ViewSubscribeForm";
import ViewEstateEach from "./pages/Properties/Estates/viewEstate";
import ViewHousingDetails from "./pages/Properties/Housing/ViewHousing";
import ViewEachProduct from "./pages/Properties/Products/ViewEachProduct";
import Loyalty from "./pages/Publish/Loyalty";
import AddLoyalty from "./pages/Publish/Loyalty/AddLoyalty";
import ViewLoyalty from "./pages/Publish/Loyalty/ViewLoyalty";
import SingleUser from "./pages/MasterDataPages/Users/SingleUser";
import EditHousing from "./pages/Properties/Housing/EditHousing";
import EditProduct from "./pages/Properties/Products/EditProduct";
import ScrollToTop from "./components/scrollToTop";

function AllRoutes() {
  const {user} = useGetUser();

  const PrivateRoute = ({children}) => {
    let location = useLocation();
    return (
      <>
        {user ? (
          children
        ) : (
          <Navigate
            to={`/login?redirectTo=${location.pathname}`}
            state={{from: location}}
          />
        )}
      </>
    );
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* put all routes for dashboard inside this particular route */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashBoardLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<Dashboard />} />
          {/* <Route path="properties" element={<h1>Properties</h1>} /> */}
          <Route path="sales" element={<h1>Sales</h1>} />

          <Route path="publish" element={<h1>Publish</h1>} />
          <Route path="profile" element={<Profile />} />

          {/* demo page: path would be /dashboard/demo */}
          <Route path="demo" element={<Demo />} />

          <Route path="master-data" element={<MasterDataLayout />}>
            {/* the empty path denotes the the component rendered is the index page i.e /dashboard/master-data */}
            {/* <Route path="" element={<Properties />} /> */}
            {/* the path here in the browser would be /dashboard/master-data/role-mgt */}
            <Route path="" element={<MasterDataRoles />} />
            <Route path="create-role" element={<CreateRole />} />
            <Route path="faq" element={<FAQ />} />
            {/* <Route path="tnc" element={<TnC />} /> */}
            <Route path="cps" element={<CPS />} />
            <Route path="cps/add" element={<AddCPS />} />
            <Route path="cps/details" element={<CPSDetails />} />
            {/* <Route path="faq/inner" element={<InnerFaq />} /> */}
            {/* <Route path="tnc/inner" element={<InnerTnC />} /> */}
            <Route path="administrators" element={<Users />} />
            <Route path="administrators/:id" element={<SingleUser />} />
          </Route>

          <Route path="properties" element={<PropertiesLayout />}>
            <Route path="" element={<PropertiesEstates />} />
            <Route path="products" element={<PropertiesProduct />} />
            <Route path="housing" element={<PropertiesHousing />} />
            <Route path="view" element={<ViewEstate />} />{" "}
            <Route path="viewestate/:id" element={<ViewEstateEach />} />
            <Route path="products/add" element={<AddProduct />} />
            <Route path="products/edit/:id" element={<EditProduct />} />
            <Route path="housing/add" element={<AddHousing />} />
            <Route path="housing/edit/:id" element={<EditHousing />} />
            <Route path="housing/:id" element={<ViewHousingDetails />} />
            <Route path="estate/add" element={<AddEstate />} />
            <Route path="estate/edit/:id" element={<EditEstate />} />
            <Route path="products/:id" element={<ViewEachProduct />} />
          </Route>
          <Route path="sales" element={<SalesLayout />}>
            <Route path="" element={<SubscribeForm />} />
            <Route path="subscribe/view/:id" element={<ViewSubscribeForm />} />
            <Route
              path="leads/subscribe/view/:id"
              element={<ViewSubscribeForm />}
            />
            <Route path="marketers" element={<Marketers />} />
            <Route path="marketers/view/:id" element={<ViewMarketer />} />
            <Route path="leads" element={<Leads />} />
          </Route>
          <Route path="publish" element={<PublishLayout />}>
            <Route path="" element={<Events />} />
            <Route path="event/add" element={<AddEvent />} />
            <Route path="view/:id" element={<ViewEvent />} />
            <Route path="promos" element={<Promos />} />
            <Route path="promos/add" element={<AddPromo />} />
            <Route path="promos/view/:id" element={<ViewPromo />} />
            <Route path="csr" element={<CSR />} />
            <Route path="csr/add" element={<AddCsr />} />
            <Route path="csr/view/:id" element={<ViewCsr />} />
            <Route path="loyalty" element={<Loyalty />} />
            <Route path="loyalty/add" element={<AddLoyalty />} />
            <Route path="loyalty/view/:id" element={<ViewLoyalty />} />
          </Route>
          <Route path="audit" element={<AuditLayout />}>
            <Route path="" element={<Audit />} />
          </Route>
          <Route path="hr" element={<HrLayout />}>
            <Route path="" element={<HrApplication />} />
            <Route path="viewapplication/:id" element={<ViewApplication />} />
            <Route path="vacancy" element={<Vacancy />} />
            <Route path="vacancy/view/:id" element={<ViewVacancy />} />
            <Route path="vacancy/create-vacancy" element={<CreateVacancy />} />
          </Route>
          <Route path="client-services" element={<ClientServiceLayout />}>
            {/* <Route path="" element={<HrApplication />} /> */}
            <Route path="" element={<Feedback />} />
            <Route path="feedbacks/view/:id" element={<ViewFeedback />} />
            <Route path="office" element={<Office />} />
            <Route path="customer" element={<Customers />} />
            <Route
              path="customer/view/:customerId"
              element={<ViewCustomer />}
            />
          </Route>
        </Route>

        {/* Other routes not using admin layout should stay here e.g:*/}
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
