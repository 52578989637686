import {useForm, FormProvider} from "react-hook-form";
import {useState} from "react";
import {Stepper, StepButton, Step, Button} from "@mui/material";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import "../Estates/style2.scss";
import Details from "../AddPropertyForm/Details";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import ViewProduct from "./view";
import {useAddProductMutation} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import {useNavigate} from "react-router-dom";

export const STEP_TITLES = ["Details", "Done"];
const TOTAL_STEPS = 1;

const AddProduct = () => {
  const methods = useForm({
    mode: "all",
    defaultValues: {
      features: [],
    },
    shouldUnregister: true,
  });

  const [addProduct, {isLoading}] = useAddProductMutation();
  const navigate = useNavigate();

  const onSubmit = async data => {
    const credentials = {
      ...data,
      gallery: image,
      featured: parseInt(data.featured),
    };
    try {
      let res = await addProduct(credentials).unwrap();
      if (res.status === "success") {
        navigate("/properties/products");
        toaster("success", res.message);
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };
  const [image, setImage] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = step => {
    if (methods.formState.isValid) {
      setActiveStep(step);
    } else {
      console.log(methods.formState.errors, "not valid");
    }
  };

  return (
    <div className="addProperty">
      <header className="heading">
        <TitleWithBackBtn title="Add Product" />
      </header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stepper className="stepper" activeStep={activeStep}>
            {STEP_TITLES.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton
                    className={`${activeStep === index ? "active" : ""}`}
                    type="button"
                    onClick={() => handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div
            className={`form-container ${
              activeStep === TOTAL_STEPS ? "p0" : ""
            } `}
          >
            {activeStep >= 0 && (
              <Details
                type={3}
                setImgUrls={setImgUrls}
                images={image}
                setImages={setImage}
                display={activeStep === 0}
                propertyType={"estates"}
              />
            )}
            {activeStep >= 1 && (
              <ViewProduct
                isFormView={false}
                images={imgUrls}
                product={methods.getValues()}
                display={activeStep === 1}
              />
            )}
            <div className="btn-wrap">
              <Button
                onClick={() => {
                  return activeStep > 0 ? setActiveStep(activeStep - 1) : null;
                }}
                type="button"
                variant="outlined"
              >
                {activeStep === 0
                  ? "Cancel"
                  : `Previous:${STEP_TITLES[activeStep - 1]}`}
              </Button>
              <Button
                onClick={() => handleStep(activeStep + 1)}
                type="button"
                variant="contained"
                style={{
                  display: activeStep < TOTAL_STEPS ? "flex" : "none",
                }}
              >
                Next:{STEP_TITLES[activeStep + 1]}
              </Button>
              <SubmitBtn
                btnText="Publish"
                disabled={!methods.formState.isValid}
                isLoading={isLoading}
                style={{
                  display: activeStep === TOTAL_STEPS ? "flex" : "none",
                }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddProduct;
