import {useCallback, useState} from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import "./style.scss";
import IconButton from "@mui/material/IconButton";
import {deleteImg, uploadImg} from "../../services/UploadImg";
import {toaster} from "../../utils/utils";
import {CircularProgress} from "@mui/material";
import {useDropzone} from "react-dropzone";

const UploadMultipleFile = ({
  id,
  name,
  required = true,
  labelText,
  max,
  setImages,
  images,
  setImgUrls,
  folder = "Mobile",
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelLoading, setDelIsLoading] = useState("");
  const [error, setError] = useState("");
  const [delToken, setDelToken] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);

  const onDrop = useCallback(
    acceptedFiles => {
      const uploader = async files => {
        if (files) {
          if (images.length < max) {
            setIsLoading(true);
            try {
              for (const file of files) {
                let url = await uploadImg(
                  file,
                  process.env.REACT_APP_CLOUDINARY_PRESET,
                  folder
                );
                setImages(prev => [...prev, url.public_id]);
                setImgUrl(prev => [...prev, url.secure_url]);
                if (setImgUrls) setImgUrls(prev => [...prev, url.secure_url]);
                setDelToken(prev => [...prev, url.delete_token]);
              }
            } catch (err) {
              setError("Image could not be uploaded");
              toaster("error", "Image could not be uploaded");
            } finally {
              setIsLoading(false);
            }
          } else {
            setError("Max images exceeded");
          }
        } else {
          setIsSelected(false);
        }
      };

      uploader(acceptedFiles);
    },
    [folder, images.length, max, setImages, setImgUrls]
  );

  const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
    onDrop,
    noClick: true,
    accept: "image/png, image/gif, image/jpeg",
    multiple: true,
  });

  const delImg = async (url, token, img) => {
    setDelIsLoading(url);
    try {
      let res = await deleteImg(token);

      if (res.result === "ok") {
        let newImgUrl = imgUrl.filter(el => url !== el);
        let newImages = images.filter(el => img !== el);
        setImgUrl(newImgUrl);
        setImages(newImages);
        setError("");
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setDelIsLoading("");
    }
  };

  return (
    <div className="form-group">
      {labelText && (
        <label htmlFor={id || name}>
          {labelText} {imgUrl.length}/{max}
        </label>
      )}
      <div className="images-wrap">
        <div
          className="upload-file"
          {...getRootProps({
            onClick: e => {
              e.stopPropagation();
            },
          })}
        >
          <input
            type="file"
            onClick={() => setIsSelected(false)}
            {...getInputProps()}
            name={name}
            accept="image/png, image/gif, image/jpeg"
          />
          <label
            style={{
              borderColor: isDragActive ? "#1E791C" : "#dfe1e6",
            }}
            className={`${isSelected && "picSelected"}`}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  borderColor: isDragActive ? "#1E791C" : "#97a0af",
                }}
                onClick={open}
              >
                <p>
                  {isDragActive ? "Drop the images here" : "Drag images here"}
                </p>
                <p>Or</p>
                <p>Select Images</p>
              </div>
            )}
          </label>
          {error && <div className="input-err-msg">{error}</div>}
        </div>
        <>
          {imgUrl.map((el, i) => (
            <div key={el + i} className="upload-file">
              <img className="img-slt" src={el} alt=" selected" />
              {isDelLoading === el && <CircularProgress className="progress" />}
              <IconButton
                className="edit-icon"
                onClick={() => delImg(el, delToken[i], images[i])}
                aria-label="delete image"
              >
                <CancelIcon color="textClr80" />
              </IconButton>
            </div>
          ))}
        </>
      </div>
    </div>
  );
};
export default UploadMultipleFile;
