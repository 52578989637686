import {useState} from "react";
import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import "./style.scss";
import {FormProvider, useForm} from "react-hook-form";
import EachProduct from "../../../components/eachProduct";
import {useGetAllPropertiesQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import {Button, Pagination} from "@mui/material";
import EmptyResponsePage from "../../../components/EmptyResponsePage";
import FilterSelect from "../../../components/Input/FilterSelect";
import {convertObjToParams} from "../../../utils/utils";

const PropertiesProduct = () => {
  const [page, setPage] = useState(1);

  const [params, setParams] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const filterMethods = useForm();

  const {
    data = [],
    isFetching,
    isError,
    error,
  } = useGetAllPropertiesQuery({
    name: "product",
    page,
    params,
    search: searchVal,
  });

  const handleFilterSubmit = data => {
    setParams(convertObjToParams(data));
  };

  // loading state
  if (isFetching) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd-product pd-property">
      <PageTitleAddBtn
        title={"Products"}
        isSearch
        setSearchVal={setSearchVal}
        btnText={"Add Product"}
        to={"/properties/products/add"}
      />

      <div className="filterSide">
        <FormProvider {...filterMethods}>
          <form onSubmit={filterMethods.handleSubmit(handleFilterSubmit)}>
            <div className="selectFilter">
              <FilterSelect
                label={"Status"}
                name="property_status"
                selectOption={["For Sale", "Sold Out"]}
              />
              <Button type="submit" variant="outlined">
                Apply Filter
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      <div className="productBox">
        {data.data.length > 0 ? (
          <>
            {data.data.map(item => {
              return <EachProduct product={item} key={item.id} />;
            })}
            {data.meta.last_page > 1 && (
              <Pagination
                color="primary"
                count={data.meta.last_page}
                defaultPage={1}
                page={page}
                onChange={(e, value) => setPage(value)}
                shape="rounded"
              />
            )}
          </>
        ) : (
          <EmptyResponsePage
            message={
              "No Product Created Yet, Click 'Add Housing' To Add A New Series"
            }
          />
        )}
      </div>
    </div>
  );
};

export default PropertiesProduct;
