import {useForm, FormProvider} from "react-hook-form";
import {useState} from "react";
import "../Estates/style2.scss";
import {Stepper, StepButton, Step, Button} from "@mui/material";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import Details from "../AddPropertyForm/Details";
import Customization2 from "../AddPropertyForm/Customization2";
import {makeArr, removeEmpty, toaster} from "../../../utils/utils";
import {useAddHousingMutation} from "../../../services/api";

import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {useNavigate} from "react-router-dom";
import ViewHousingForm from "./ViewHousingForm";
export const STEP_TITLES = ["Details", "Customization", "Done"];
const TOTAL_STEPS = 2;

const AddHousing = () => {
  const methods = useForm({
    mode: "all",
    defaultValues: {
      features: [],
    },
    shouldUnregister: true,
  });

  const [addHousing, {isLoading}] = useAddHousingMutation();
  const navigate = useNavigate();
  const onSubmit = async data => {
    const credentials = {
      ...data,
      configurations: makeArr(data.configurations, "configurationTitle"),
      gallery: image,
      // brochure: file,
      features: makeArr(data.features, "featureName"),
      featured: parseInt(data.featured),
    };
    let entries = Object.entries(removeEmpty(credentials));
    let formData = new FormData();

    for (let i = 0; i < entries.length; i++) {
      if (`${entries[i][0]}` === "brochure") {
        formData.append(`${entries[i][0]}`, entries[i][1][0]);
      } else if (entries[i][1] instanceof Array) {
        for (let j = 0; j < entries[i][1].length; j++) {
          formData.append(`${entries[i][0]}[]`, entries[i][1][j]);
        }
      } else {
        formData.append(`${entries[i][0]}`, entries[i][1]);
      }
    }
    try {
      let res = await addHousing(formData).unwrap();
      if (res.status === "success") {
        navigate("/properties/housing");
        toaster("success", res.message);
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState([]);

  const [imgUrls, setImgUrls] = useState([]);

  // const [submitEstate, {isLoading}] = useAddEstateMutation();
  const handleStep = step => {
    if (methods.formState.isValid) {
      setActiveStep(step);
    } else {
      console.log(methods.formState.errors, "not valid");
    }
  };

  return (
    <div className="addProperty">
      <header className="heading">
        <TitleWithBackBtn title="Add Housing Series" />
      </header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stepper className="stepper" activeStep={activeStep}>
            {STEP_TITLES.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton
                    className={`${activeStep === index ? "active" : ""}`}
                    type="button"
                    onClick={() => handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div
            className={`form-container ${
              activeStep === TOTAL_STEPS ? "p0" : ""
            } `}
          >
            {activeStep >= 0 && (
              <Details
                setImgUrls={setImgUrls}
                images={image}
                setImages={setImage}
                type={1}
                display={activeStep === 0}
              />
            )}
            {activeStep >= 1 && (
              <Customization2 type={1} display={activeStep === 1} />
            )}
            {activeStep >= 2 && (
              <ViewHousingForm
                data={{
                  ...methods.getValues(),
                  configurations: makeArr(
                    methods.getValues().configurations,
                    "configurationTitle"
                  ),
                  gallery: imgUrls,
                  featured: parseInt(methods.getValues().featured),
                }}
                display={activeStep === 2}
              />
            )}

            <div className="btn-wrap">
              <Button
                onClick={() => {
                  return activeStep > 0 ? setActiveStep(activeStep - 1) : null;
                }}
                type="button"
                variant="outlined"
              >
                {activeStep === 0
                  ? "Cancel"
                  : `Previous:${STEP_TITLES[activeStep - 1]}`}
              </Button>
              <Button
                onClick={() => handleStep(activeStep + 1)}
                type="button"
                variant="contained"
                style={{
                  display: activeStep < TOTAL_STEPS ? "flex" : "none",
                }}
                disabled={!methods.formState.isValid || image.length <= 0}
              >
                Next:{STEP_TITLES[activeStep + 1]}
              </Button>
              <SubmitBtn
                btnText="Publish"
                disabled={!methods.formState.isValid || image.length <= 0}
                isLoading={isLoading}
                style={{
                  display: activeStep === TOTAL_STEPS ? "flex" : "none",
                }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddHousing;
