import "./style.scss";
const DashCard = ({ bg, title, img, number }) => {
  return (
    <div className={`pd_card ${bg}`}>
      <div className="imageCard">
        <img src={img} alt="card" />
      </div>
      <div className="text">
        <p className="title">Total {title}</p>
        <p className="num">{number}</p>
      </div>
      <div className="circle big"></div>
      <div className="circle small"></div>
    </div>
  );
};

export default DashCard;
