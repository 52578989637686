import {Button} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FeedbackTable from "./FeedbackTable";
import "./style.scss";
import {useExporterMutation} from "../../../services/api";
import {downloader, toaster} from "../../../utils/utils";
import {useState} from "react";
import SearchInput from "../../../components/SearchInput";
const Feedback = () => {
  const [getFile] = useExporterMutation();
  const [search, setSearch] = useState("");
  const download = async () => {
    try {
      const response = await getFile({type: "feedbacks"}).unwrap();

      downloader(response, "feedbacks");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="feedback-pg table-pg-layout">
      <div className="heading">
        <div className="titleFlex">
          <h1 className="title">Feedbacks</h1>
          <SearchInput setSearchVal={setSearch} />
        </div>
        <Button color="primary" variant="outlined" onClick={download}>
          Export
        </Button>
      </div>
      <FeedbackTable search={search} />
    </div>
  );
};

export default Feedback;
