import {Stack} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import DeleteIcon from "@mui/icons-material/Delete";
import PersonCard from "../../../components/personCard";
import {useParams} from "react-router-dom";
import {useGetOneFeedbackQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import moment from "moment";

const ViewFeedback = () => {
  const {id} = useParams();

  const {
    data: feedback = null,
    isLoading,
    isError,
    error,
  } = useGetOneFeedbackQuery(id);

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="view-details">
      <div className="inner">
        <div className="heading">
          <TitleWithBackBtn title="View Feedback" />
          <Stack justifyContent={"flex-end"} direction="row" spacing={2}>
            {/* <Button
              color="primary"
              variant="outlined"
              
            >
              Export
            </Button>
            <Button
              variant="contained"
              color="red0"
              endIcon={<DeleteIcon color="red" />}
            >
              Delete
            </Button> */}
          </Stack>
        </div>
        <PersonCard
          name={feedback && feedback.user_name}
          email={feedback && feedback.user_email}
          phone={feedback && feedback.user_phone}
          head={"Feedback"}
        >
          <div className="each">
            <p className="left">Feedback Type:</p>
            <p className="right">{feedback && feedback.feedback_type}</p>
          </div>
          <div className="each">
            <p className="left">Emotion:</p>
            <p className="right">{feedback && feedback.feelings}</p>
          </div>
          <div className="each">
            <p className="left">Date:</p>
            <p className="right">
              {feedback && moment(feedback.created_at).format("L")}
            </p>
          </div>
          <div className="each">
            <p className="left">Message:</p>
            <p className="right">{feedback && feedback.message}</p>
          </div>
        </PersonCard>
      </div>
    </div>
  );
};

export default ViewFeedback;
