import {Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.scss";
import locate from "../../../assets/icons/location.svg";
// import DownloadIcon from "@mui/icons-material/Download";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import Carousel from "../../../components/Carousel";
import {getYtId, toaster} from "../../../utils/utils";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  useDeleteHouseMutation,
  useGetOnePropertyQuery,
} from "../../../services/api";
import {LoadingButton} from "@mui/lab";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
const ViewHousingDetails = () => {
  const {id} = useParams();
  const {data, isLoading, isError, error} = useGetOnePropertyQuery(
    `home/series/by/${id}`
  );
  const navigate = useNavigate();
  const [deleteHouse, {isLoading: delLoading}] = useDeleteHouseMutation();
  const deleteHandler = async () => {
    try {
      // call login trigger from rtk query
      const response = await deleteHouse(id).unwrap();

      toaster("success", response.message);
      navigate("/properties/housing");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data?.message);
    }
  };

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd-view">
      <div className="heading topSide">
        <TitleWithBackBtn title="View Housing Series" />
        <div className="btn-wrap">
          <Button
            component={Link}
            to={`/properties/housing/edit/${id}`}
            variant="contained"
            color="primary20"
            endIcon={<EditIcon color="primary" />}
          >
            Edit
          </Button>
          <LoadingButton
            variant="contained"
            color="red0"
            endIcon={<DeleteIcon color="red" />}
            loading={delLoading}
            loadingPosition="end"
            className="delBtn"
            onClick={deleteHandler}
          >
            Delete
          </LoadingButton>
        </div>
      </div>
      <div className="aboutEstate">
        <div className="carousel">
          <Carousel>
            {data?.gallery?.map((el, i) => (
              <img key={"image-" + i} src={el} alt={"image-" + i} />
            ))}
          </Carousel>
        </div>
        <div className="estateAbout">
          <p className="name">{data?.name}</p>
        </div>
        <div className="hotSide">
          <p className={data?.tag.toLowerCase()}>{data?.tag}</p>
          <p className={"blue"}>Housing</p>
          <p className={data?.property_status.split(" ")[0].toLowerCase()}>
            {data?.property_status}
          </p>
        </div>
        <div className="location">
          <img src={locate} alt="locate" />
          {data?.location}
        </div>
        <div className="desc">
          <p className="head">Description</p>
          <p className="story">{data?.description}</p>
        </div>

        <div className="feature">
          <p className="head">Features</p>
          <div className="images">
            <div className="images story">
              {data?.features?.map(el => {
                return (
                  <span style={{marginRight: 8}} key={el}>
                    {el}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          href={data?.brochure}
          download
          color="primary20"
          endIcon={<FileDownloadIcon color="primary" />}
        >
          Brochure
        </Button>
        <div className="video">
          <div className="eachVideo">
            <p className="head">Property Address</p>
            <div className="map">
              <div
                style={{
                  overflow: "hidden",
                  background: "none !important",
                  height: "100%",
                  width: "100%",
                }}
                className="gmap_canvas"
              >
                <iframe
                  title="maps"
                  width="100%"
                  height="100%"
                  id="gmap_canvas"
                  src={`https://maps.google.com/maps?q=${data?.address},${data?.location}=&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                <br />
                <a href="https://www.embedgooglemap.net">
                  embedded google search
                </a>
              </div>
            </div>
          </div>
          {data?.youtube_url && (
            <div className="eachVideo">
              <p className="head">Documentary</p>
              <div className="map">
                <iframe
                  title="youtube url"
                  height="173px"
                  width="100%"
                  src={`https://www.youtube.com/embed/${getYtId(
                    data?.youtube_url
                  )}`}
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <div className="accordion">
          <ul className="accordion-list acc" style={{listStyleType: "disc"}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="accTop">Configurations</p>
              </AccordionSummary>
              {data?.configuration_type?.map((item, i) => {
                return (
                  <AccordionDetails key={item + i}>
                    <li className="accInn">{item}</li>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          </ul>
        </div>
        <div className="date">
          <p className="head">Publish Date</p>
          {moment(data?.created_at).format("Do MMMM YYYY")}
        </div>
      </div>
    </div>
  );
};

export default ViewHousingDetails;
