import {Button, Stack} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonCard from "../../../components/personCard";
import {useParams} from "react-router-dom";
import {useGetSingleCustomerQuery} from "../../../services/api";
import moment from "moment";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";

const ViewCustomer = () => {
  const {customerId} = useParams();
  const {data, isLoading, isError, isSuccess, error} =
    useGetSingleCustomerQuery(customerId);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="view-details">
      {isSuccess && (
        <div className="inner">
          <div className="heading">
            <TitleWithBackBtn title="View Customer" />
            <Stack justifyContent={"flex-end"} direction="row" spacing={2}>
              {/* <Button
                color="primary"
                variant="outlined"
                
              >
                Export
              </Button> */}
              <Button
                variant="contained"
                color="red0"
                endIcon={<DeleteIcon color="red" />}
              >
                Delete
              </Button>
            </Stack>
          </div>
          <PersonCard
            avatar={data.display_picture}
            name={data.fullname}
            email={data.email}
            phone={data.phone_number}
            head={"Other Details"}
          >
            <div className="each">
              <p className="left">Location:</p>
              <p className="right">Lagos</p>
            </div>
            <div className="each">
              <p className="left">Referral Code:</p>
              <p className="right">{data.referral_code}</p>
            </div>
            <div className="each">
              <p className="left">Date Registered:</p>
              <p className="right">
                {moment(data.created_at).format("DD-MM-YYYY")}
              </p>
            </div>
          </PersonCard>
        </div>
      )}
    </div>
  );
};

export default ViewCustomer;
