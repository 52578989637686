import SelectField from "../../../components/Input/SelectField";
import InputField from "../../../components/Input";
import {states} from "../../../utils/data";
import Textbox from "../../../components/Input/Textbox";
import UploadMultipleFile from "../../../components/UploadImgField/UploadMultipleFile";
import {FormLabel, MenuItem, Select} from "@mui/material";
import {useNavigate} from "react-router-dom";
import UploadPdf2 from "../../../components/Input/UploadPdf2";

const PROPERTY_TYPES = [
  {
    label: "Select Type",
    value: "",
  },
  {
    label: "Housing Series",
    value: "housing/add",
  },
  {
    label: "Estates",
    value: "estate/add",
  },
  {
    label: "Product",
    value: "products/add",
  },
];

const Details = ({
  display,
  type = 0,
  images,
  setImages,
  setImgUrls,

  isEditType = false,
}) => {
  let navigate = useNavigate();
  let folder;
  switch (type) {
    case 1:
      folder = "Mobile/Housing";
      break;
    case 2:
      folder = "Mobile/Estates";
      break;
    case 3:
      folder = "Mobile/Products";
      break;
    default:
      folder = "Mobile/Properties";
      break;
  }

  return (
    <div style={{display: display ? "block" : "none"}}>
      <div className="form-group-wrap form-group-wrap-3">
        <div className="form-group select-group">
          <FormLabel>Property Type</FormLabel>
          <Select
            className="select-mui"
            onChange={e => {
              navigate(`/properties/${e.target.value}`);
            }}
            displayEmpty
            defaultValue={PROPERTY_TYPES[type].value}
          >
            {PROPERTY_TYPES.map(item => {
              return (
                <MenuItem
                  key={item.value}
                  className="menu-item"
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <SelectField
          name="tag"
          label="Property Tag"
          errMsg="invalid field"
          selectOption={[
            {
              label: "Select Tag",
              value: "",
            },
            "New",
            "Hot",
          ]}
          required={!isEditType}
        />
        <SelectField
          name="property_status"
          label="Property status"
          errMsg="invalid field"
          selectOption={[
            {
              label: "Select Status",
              value: "",
            },
            "For Sale",
            "Sold Out",
          ]}
          required={!isEditType}
        />
      </div>
      <InputField
        type="text"
        name="name"
        placeholder="Enter Property Name"
        label="Property Name"
        required={!isEditType}
      />
      <Textbox
        placeholder={"Enter Description"}
        name={"description"}
        label={"Description"}
        maxLength={1800}
        errMsg={"invalid field"}
        required={!isEditType}
      />
      {type === 3 && (
        <SelectField
          name="featured"
          label="Featured"
          errMsg="invalid field"
          selectOption={[
            {
              label: "Is property featured?",
              value: "",
            },
            {
              label: "Yes",
              value: 1,
            },
            {
              label: "No",
              value: 0,
            },
          ]}
          required={!isEditType}
        />
      )}
      {type !== 3 && (
        <>
          <InputField
            type="url"
            name="youtube_url"
            placeholder="Enter Documentary URL"
            label="Documentary"
            required={false}
          />
          {type === 1 && (
            <UploadPdf2
              name="brochure"
              filesAccepted={[".pdf"]}
              label={"Brochure (PDF)"}
              placeholder="Upload Brochure"
              required={!isEditType}
            />
          )}
          <InputField
            type="text"
            name="address"
            placeholder="Enter Address"
            label="Address"
            required={!isEditType}
          />
          <div className="form-group-wrap form-group-wrap-3">
            <SelectField
              name="featured"
              label="Featured"
              errMsg="invalid field"
              selectOption={[
                {
                  label: "Is property featured?",
                  value: "",
                },
                {
                  label: "Yes",
                  value: 1,
                },
                {
                  label: "No",
                  value: 0,
                },
              ]}
              required={!isEditType}
            />
            <SelectField
              name="location"
              label="State"
              errMsg="invalid field"
              required={type !== 1 && isEditType === false}
              selectOption={[
                {
                  label: "Select State",
                  value: "",
                },
                ...states.map(el => ({
                  label: el,
                  value: el,
                })),
              ]}
            />
            {type !== 1 && (
              <InputField
                type="number"
                name="amount"
                placeholder="₦0.00"
                label="Amount"
                required={type !== 1 && isEditType === false}
              />
            )}
          </div>
        </>
      )}
      <UploadMultipleFile
        text="Upload Multiple Pictures"
        name="image"
        id="multi"
        folder={folder}
        labelText="Upload Pictures"
        max={10}
        images={images}
        setImages={setImages}
        setImgUrls={setImgUrls}
      />
    </div>
  );
};

export default Details;
