import {Button} from "@mui/material";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";

import InputField from "../../../components/Input";
import Textbox from "../../../components/Input/Textbox";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import UploadMultipleFile from "../../../components/UploadImgField/UploadMultipleFile";
import {useAddLoyaltyMutation} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import "./style.scss";
const AddLoyalty = () => {
  const methods = useForm();
  const [addLoyalty, {isLoading}] = useAddLoyaltyMutation();
  const [image, setImage] = useState([]);
  const navigate = useNavigate();
  const onSubmit = async data => {
    const payload = {...data, gallery: image};
    try {
      await addLoyalty(payload).unwrap();

      toaster(
        "success",
        "Corporate Social Responsibility successfully Published!"
      );
      navigate("/publish/loyalty/");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="pd_add_promo">
      <TitleWithBackBtn title="Add Coporate Social Responsibility" />

      <div className="pg_content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              type="text"
              name="title"
              placeholder="Enter Event Title"
              label="Loyalty Title"
            />
            <Textbox
              placeholder={"Enter Summary"}
              name={"summary"}
              label={"Loyalty Summary"}
              errMsg={"invalid field"}
            />
            <UploadMultipleFile
              text="Upload Multiple Pictures"
              name="gallery"
              id="multi"
              folder="Mobile/Loyalty"
              labelText="Media"
              max={10}
              images={image}
              setImages={setImage}
            />
            <div className="btn-wrap">
              <Button type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn btnText="Publish" isLoading={isLoading} />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddLoyalty;
