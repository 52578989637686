import {Grid, IconButton} from "@mui/material";
import Carousel from "../../../components/Carousel";
import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./style.scss";
import DropDownWrapper from "../../../components/DropDownWrapper";
import {Link} from "react-router-dom";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteCsrMutation, useGetAllCSRQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import {toaster} from "../../../utils/utils";
import {useState} from "react";
import EmptyResponsePage from "../../../components/EmptyResponsePage";
import {LoadingButton} from "@mui/lab";
import TablePagination from "../../../components/TablePagination";
const EachCSR = ({item}) => {
  const [deleteCsr, {isLoading: delLoading}] = useDeleteCsrMutation();

  const deleteHandler = async id => {
    try {
      // call login trigger from rtk query
      const response = await deleteCsr(id).unwrap();

      toaster("success", response.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="card data-card">
      <Carousel showArrows={false}>
        {item.gallery.map(el => (
          <div key={el} className="card-media">
            <img src={el} alt="data" />
          </div>
        ))}
      </Carousel>
      <DropDownWrapper
        className="more-actions"
        action={
          <IconButton className="more-action-btn" aria-label="actions">
            <MoreVertIcon />
          </IconButton>
        }
      >
        <Link to={`view/${item.id}`} className="btn-noBg">
          <VisibilityIcon color="iconColor" /> View CSR
        </Link>

        <LoadingButton
          onClick={() => {
            deleteHandler(item.id);
          }}
          startIcon={<DeleteIcon color="red" />}
          loading={delLoading}
          loadingPosition="start"
          disabled={delLoading}
        >
          <span className="clr-red-100">Delete CSR</span>
        </LoadingButton>
      </DropDownWrapper>
      <div className="card-body">
        <h4>{item.title}</h4>
        <p className="clr-textClr-40">Summary</p>
        <p className="summary-txt">{item.summary}</p>
        <p className="publish-date">
          <span className="clr-textClr-40">Publish Date:</span>{" "}
          <span>{moment(item.created_at).format("DD-MM-YYY")}</span>
        </p>
      </div>
    </div>
  );
};

const CSR = () => {
  const [page, setPage] = useState(1);

  const {data, isLoading, isError, error, isSuccess} = useGetAllCSRQuery(page);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="data-pg">
      <PageTitleAddBtn
        title={"Corporate Social Responsibility"}
        btnText={"Add CSR"}
        to={"add"}
      />
      {isSuccess &&
        (data.data.length ? (
          <>
            <Grid container mt={1} spacing={4}>
              {data.data.map(item => {
                return (
                  <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
                    <EachCSR item={item} />
                  </Grid>
                );
              })}
            </Grid>
            <TablePagination page={page} setPage={setPage} data={data} />
          </>
        ) : (
          <EmptyResponsePage
            message={
              "No CSR has Created Yet, Click 'Add CSR' To Add A New Series"
            }
          />
        ))}
    </div>
  );
};

export default CSR;
