import {Outlet} from "react-router-dom";
// import {CustomSideLink} from "../../components/Sidebar";
import {CustomSideLink} from "../../components/CustomSideLink";
import "../MasterDataLayout/style.scss";

const AuditLayout = () => {
  return (
    <div className="pg-layout">
      <div className="drop-down-links">
        <CustomSideLink baseUrl={"audit"} to="">
          Whistle Blowing
        </CustomSideLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default AuditLayout;
