import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {useState} from "react";
import {getComparator, stableSort, truncateString} from "../../../utils/utils";
import InputField from "../../../components/Input";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import {IconButton} from "@mui/material";
import DropDownWrapper from "../../../components/DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Link} from "react-router-dom";
import {useGetAllFeedbackQuery} from "../../../services/api";
import LoadingTable from "../../../components/loadingTable";
import ErrorMsg from "../../../components/ErrorMsg";
import moment from "moment";
import TablePagination from "../../../components/TablePagination";
import {Button} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {convertObjToParams} from "../../../utils/utils";
import FilterSelect from "../../../components/Input/FilterSelect";
import FileUploadIcon from "@mui/icons-material/FileUpload";
const SubscribeDropDown = ({id, email}) => (
  <DropDownWrapper
    className="more-actions"
    action={
      <IconButton className="more-action-btn" aria-label="actions">
        <MoreVertIcon />
      </IconButton>
    }
  >
    <Link to={`feedbacks/view/${id}`} className="btn-noBg">
      <VisibilityIcon color="iconColor" /> View Feedback
    </Link>

    <a href={`mailto:${email}`} className="btn-noBg">
      <FileUploadIcon color="iconColor" /> Send Mail
    </a>
    {/* <button className="btn-noBg">
      <FileUploadIcon color="iconColor" /> Send Mail
    </button> */}
    {/* <button className="btn-noBg">
      <DeleteIcon color="red" />
      <span className="clr-red-100">Delete</span>
    </button> */}
  </DropDownWrapper>
);

const headCells = [
  {
    id: "user_name",
    label: "Customer Name",
  },
  {
    id: "feedback_type",
    label: "Feedback Type",
  },
  {
    id: "feelings",
    label: "Emotion",
  },
  {
    id: "created_at",
    label: "Date",
  },
  {
    id: "message",
    label: "Message",
  },
  {
    id: "action",
    label: "Action",
  },
];

export default function FeedbackTable({search}) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);

  const [params, setParams] = useState("");
  const filterMethods = useForm();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const {
    data: feedback = [],
    isLoading,
    isError,
    error,
  } = useGetAllFeedbackQuery({page, search, params});

  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <>
      <div className="filterSide">
        <FormProvider {...filterMethods}>
          <form
            onSubmit={filterMethods.handleSubmit(data =>
              setParams(convertObjToParams(data))
            )}
          >
            <div className="selectFilter">
              <FilterSelect
                label={"Feedback Type"}
                name="feedback_type"
                selectOption={["comment", "inquiry", "suggestion"]}
              />
              <FilterSelect
                label={"Feelings"}
                name="feelings"
                selectOption={[
                  "awesome",
                  "happy",
                  "indifferent",
                  "fair",
                  "bad",
                ]}
              />
              <InputField
                type="date"
                required={false}
                name="date"
                placeholder=""
                id="date"
              />

              <Button type="submit" variant="outlined">
                Apply Filter
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <Box sx={{width: "100%"}}>
        <Paper
          style={{boxShadow: "none", borderRadius: "8px"}}
          sx={{width: "100%", mb: 2}}
        >
          <TableContainer>
            <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
              <EnhancedTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={feedback.data.length}
                align="left"
              />
              <TableBody>
                {stableSort(feedback.data, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left">
                          <p>{row.user_name}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={{textTransform: "capitalize"}}>
                            {row.feedback_type}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={{textTransform: "capitalize"}}>
                            {row.feelings}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p>{moment(row.created_at).format("L")}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="msg">
                            {truncateString(row.message, 20)}
                          </p>
                        </TableCell>
                        <TableCell className="action" align="left">
                          <SubscribeDropDown
                            id={row.id}
                            email={row.user_email}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination page={page} setPage={setPage} data={feedback} />
      </Box>
    </>
  );
}
