import { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  AvatarGroup,
  Avatar,
  Pagination,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ReactComponent as UpDownIcon } from "../../../assets/icons/upDownIcon.svg";
import DropDownWrapper from "../../../components/DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

function createData(roleName, department, description) {
  return {
    roleName,
    department,
    description,
  };
}

const rows = [
  createData(
    "Super Admin",
    "Manager",
    "Access to all section of the dashboard"
  ),
  createData(
    "Admin",
    "Admin",
    "Access to create, view & edit properties, Access to create,view & delete from the publish section, Creating and deleting of Admins"
  ),
  createData(
    "Auditor",
    "Auditor",
    "Access to view & delete whistle blowing forms"
  ),
  createData(
    "Client Service",
    "Client Service",
    "Access to customer tables, feedbacks & office location"
  ),
  createData(
    "Human Resource",
    "Human Resource",
    "Creating of vacancy, Access to view and download applications"
  ),
  createData(
    "Sales",
    "Sales",
    "Access to property  inquiry form, Access to marketer table"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "roleName",
    label: "Role Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "description",
    label: "Description",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.label !== "Actions" ? "left" : "right"}
            style={{ fontWeight: "700" }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== "Actions" ? (
              <TableSortLabel
                active={true}
                direction={orderBy === headCell.id ? "desc" : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={UpDownIcon}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("roleName");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        style={{ boxShadow: "none", borderRadius: "8px" }}
        sx={{ width: "100%", mb: 2 }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-${index}`;

                  return (
                    <TableRow tabIndex={-1} key={row.roleName}>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.roleName}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.department}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <div className="pagination-wrap">
        <p>Showing 10 result of 240 records</p>
        <Pagination
          color="primary"
          count={10}
          defaultPage={1}
          shape="rounded"
        />
      </div> */}
    </Box>
  );
}
