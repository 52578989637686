import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import {useState} from "react";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import {getComparator, stableSort} from "../../../utils/utils";
import {Button, IconButton} from "@mui/material";
import DropDownWrapper from "../../../components/DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
// import DeleteIcon from "@mui/icons-material/Delete";
import {Link} from "react-router-dom";
import LoadingTable from "../../../components/loadingTable";
import ErrorMsg from "../../../components/ErrorMsg";
import {useGetMarketerQuery} from "../../../services/api";

const headCells = [
  {
    id: "fullname",
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "phone_number",
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email Address",
  },
  {
    id: "location",
    disablePadding: false,
    label: "Location",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Action",
  },
];

export default function MarketersTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  // get marketer
  const {
    data: marketer = [],
    isLoading,
    isError,
    error,
  } = useGetMarketerQuery(1);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <Box sx={{width: "100%"}}>
      <Paper
        style={{boxShadow: "none", borderRadius: "8px"}}
        sx={{width: "100%", mb: 2}}
      >
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={marketer.data.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(marketer.data, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="left">
                        <div className="user-profile-cell">
                          <Avatar
                            className="user-avatar"
                            style={{
                              width: 30,
                              height: 30,
                              backgroundColor: "primary-20.main",
                              color: "primary",
                            }}
                            alt="users profile"
                            src={row.display_picture}
                          />
                          <span>{row.fullname || "N/A"}</span>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {row.phone_number || "N/A"}
                      </TableCell>
                      <TableCell align="center">{row.email || "N/A"}</TableCell>
                      <TableCell align="center">
                        {row.location || "N/A"}
                      </TableCell>
                      <TableCell className="action" align="center">
                        <DropDownWrapper
                          className="more-actions"
                          action={
                            <IconButton
                              className="more-action-btn"
                              aria-label="actions"
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        >
                          <Link to={`view/${row.id}`} className="btn-noBg">
                            <VisibilityIcon color="iconColor" /> View Marketer
                          </Link>
                          {/* <button className="btn-noBg">
                            <MailIcon color="iconColor" />
                            Send Mail
                          </button> */}
                          <Button
                            startIcon={<MailIcon color="iconColor" />}
                            className="action-btn btn-noBg"
                            size="small"
                            href={`mailto:${row.email}`}
                          >
                            Send Mail
                          </Button>
                        </DropDownWrapper>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <TablePagination page={page} setPage={setPage} data={marketer} /> */}
    </Box>
  );
}
