import {useForm, FormProvider} from "react-hook-form";
import {useState} from "react";
import "../Estates/style2.scss";
import {Stepper, StepButton, Step, Button} from "@mui/material";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import Details from "../AddPropertyForm/Details";
import Customization2 from "../AddPropertyForm/Customization2";
import {makeArr, removeEmpty, toaster} from "../../../utils/utils";
import {
  useEditPropertyMutation,
  useGetOnePropertyQuery,
} from "../../../services/api";

import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
export const STEP_TITLES = ["Details", "Customization"];
const TOTAL_STEPS = 1;

const EditHousing = () => {
  const methods = useForm({
    mode: "all",
    shouldUnregister: true,
  });

  const {id} = useParams();

  const {
    data: housing = null,
    isLoading: isGetLoading,
    isError,
    error,
  } = useGetOnePropertyQuery(`home/series/by/${id}`);
  const [editHousing, {isLoading}] = useEditPropertyMutation();
  const navigate = useNavigate();
  const onSubmit = async data => {
    const credentials = {
      ...data,
      configurations: makeArr(data.configurations, "configurationTitle"),
      gallery: image,
      features: makeArr(data.features, "featureName"),
      featured: parseInt(data.featured),
    };

    const filledEstate = Object.keys(housing)
      .filter(
        key =>
          !key.includes("payment_plan") &&
          !key.includes("longitude") &&
          !key.includes("latitude") &&
          !key.includes("id") &&
          !key.includes("type") &&
          !key.includes("created_at") &&
          !key.includes("gallery") &&
          !key.includes("brochure") &&
          key !== "status"
      )
      .reduce((cur, key) => {
        return Object.assign(cur, {[key]: housing[key]});
      }, {});

    // update the existing data with the new one user filled
    let entries = Object.entries({
      ...Object.assign(
        {
          ...filledEstate,
          configurations: Object.values(housing.configuration_type),
          featured: housing.featured ? 1 : 0,
        },
        {...removeEmpty(credentials)}
      ),
    });
    //   gallery: housing.gallery.map(
    // el => el.split(/upload\/(?:v\d+\/)?([^.]+)/)[1]
    // ),

    // generate a formData for final posting (mainly for brochure)
    let formData = new FormData();
    for (let i = 0; i < entries.length; i++) {
      if (`${entries[i][0]}` === "brochure") {
        if (typeof entries[i][1] === "string") {
          formData.append(`${entries[i][0]}`, entries[i][1]);
        } else {
          formData.append(`${entries[i][0]}`, entries[i][1][0]);
        }
      } else if (entries[i][1] instanceof Array) {
        for (let j = 0; j < entries[i][1].length; j++) {
          formData.append(`${entries[i][0]}[]`, entries[i][1][j]);
        }
      } else {
        formData.append(`${entries[i][0]}`, entries[i][1]);
      }
    }

    try {
      let res = await editHousing({
        body: formData,
        url: `home/series/${id}`,
      }).unwrap();
      if (res.status === "success") {
        navigate("/properties/housing");
        toaster("success", res.message);
      } else {
        toaster("error", res.message);
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState([]);
  const handleStep = step => {
    setActiveStep(step);
    console.warn("feilds not valid");
  };
  if (isGetLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="addProperty">
      <header className="heading">
        <TitleWithBackBtn title={`Edit Housing Series`} />
      </header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stepper className="stepper" activeStep={activeStep}>
            {STEP_TITLES.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton
                    className={`${activeStep === index ? "active" : ""}`}
                    type="button"
                    onClick={() => handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div className="form-container">
            {activeStep >= 0 && (
              <Details
                images={image}
                setImages={setImage}
                type={1}
                display={activeStep === 0}
                isEditType={true}
              />
            )}
            {activeStep >= 1 && (
              <Customization2 type={1} display={activeStep === 1} />
            )}
            <div className="btn-wrap">
              <Button
                onClick={() => {
                  return activeStep > 0 ? setActiveStep(activeStep - 1) : null;
                }}
                type="button"
                variant="outlined"
              >
                {activeStep === 0
                  ? "Cancel"
                  : `Previous:${STEP_TITLES[activeStep - 1]}`}
              </Button>
              <Button
                onClick={() => handleStep(activeStep + 1)}
                type="button"
                variant="contained"
                style={{
                  display: activeStep < TOTAL_STEPS ? "flex" : "none",
                }}
                // disabled={!methods.formState.isValid || image.length <= 0}
              >
                Next:{STEP_TITLES[activeStep + 1]}
              </Button>
              <SubmitBtn
                btnText="Save"
                // disabled={!methods.formState.isValid || image.length <= 0}
                isLoading={isLoading}
                style={{
                  display: activeStep === TOTAL_STEPS ? "flex" : "none",
                }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditHousing;
