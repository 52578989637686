import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {useState} from "react";
import {Link} from "react-router-dom";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import {getComparator, stableSort, toaster} from "../../../utils/utils";
import {useDeleteAdminMutation, useGetAdminQuery} from "../../../services/api";
import LoadingTable from "../../../components/loadingTable";
import ErrorMsg from "../../../components/ErrorMsg";
import TablePagination from "../../../components/TablePagination";

const headCells = [
  {
    id: "name",
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "number",
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email Address",
  },
  {
    id: "role",
    disablePadding: false,
    label: "Role",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Action",
  },
];

export default function UsersTable({search}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [delAdmin] = useDeleteAdminMutation();
  const deleteAdmin = async id => {
    try {
      let res = await delAdmin(id).unwrap();
      if (res.status === "success") toaster("success", res.message);
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };

  // get admins
  const {
    data: admin = [],
    isLoading,
    isError,
    error,
  } = useGetAdminQuery({page, search});

  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <Box sx={{width: "100%"}}>
      <Paper
        style={{boxShadow: "none", borderRadius: "8px"}}
        sx={{width: "100%", mb: 2}}
      >
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={admin.data.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(admin.data, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="left">
                        <div className="user-profile-cell">
                          <Avatar
                            className="user-avatar"
                            style={{
                              width: 30,
                              height: 30,
                              backgroundColor: "primary-20.main",
                              color: "primary",
                            }}
                            alt="users profile"
                            src={row.display_picture}
                          />
                          <span>{`${row.firstname} ${row.lastname}`}</span>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {row.phone_number || "N/A"}
                      </TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.roles[0]}</TableCell>
                      <TableCell className="action" align="center">
                        <div className="btn-wrap">
                          <Button
                            className="action-btn"
                            size="small"
                            component={Link}
                            to={`${row.id}`}
                          >
                            View Profile
                          </Button>

                          <Button
                            className="action-btn bg-primary-20"
                            size="small"
                            href={`mailto:${row.email}`}
                          >
                            Send Mail
                          </Button>
                          <Button
                            className="action-btn"
                            size="small"
                            color="red"
                            onClick={() => {
                              deleteAdmin(row.id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination page={page} setPage={setPage} data={admin} />
    </Box>
  );
}
