import "./style.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchInput from "../SearchInput";
const PageTitleAddBtn = ({
  btnText,
  title,
  action,
  to,
  isSearch,
  subText,
  setSearchVal,
}) => {
  return (
    <div className="page_head">
      <div className="titleFlex">
        <h1 className="title">
          {title}{" "}
          {subText && (
            <>
              <br /> <span className="sub">{subText}</span>{" "}
            </>
          )}{" "}
        </h1>
        {isSearch && <SearchInput setSearchVal={setSearchVal} />}
      </div>
      {btnText &&
        (to ? (
          <Button
            variant="contained"
            endIcon={<AddIcon color="white" />}
            component={Link}
            to={to}
          >
            {btnText}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={action}
            endIcon={<AddIcon color="white" />}
          >
            {btnText}
          </Button>
        ))}
    </div>
  );
};

export default PageTitleAddBtn;
