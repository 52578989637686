import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link } from "react-router-dom";
import RolesTable from "./RolesTable";
import "./style.scss";
const MasterDataRoles = () => {
  return (
    <div className="roles-pg table-pg-layout">
      <div className="heading">
        <h1 className="pg-title">Roles</h1>
        {/* <Button
          component={Link}
          to="create-role"
          variant="contained"
          endIcon={<AddOutlinedIcon color="white" />}
        >
          Create Role
        </Button> */}
      </div>
      <RolesTable />
    </div>
  );
};

export default MasterDataRoles;
