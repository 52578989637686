import {Button} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import DeleteIcon from "@mui/icons-material/Delete";
import Carousel from "../../../components/Carousel";
import {useNavigate, useParams} from "react-router-dom";
import {
  useDeleteEventMutation,
  useGetOneEventQuery,
} from "../../../services/api";
import {toaster} from "../../../utils/utils";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";

const ViewEvent = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    data: event = null,
    isLoading,
    isError,
    error,
  } = useGetOneEventQuery(id);

  const [deleteEvent] = useDeleteEventMutation();
  const deleteHandler = async () => {
    try {
      const response = await deleteEvent(id).unwrap();
      if (response.status === "success") {
        toaster("success", response.message);
        navigate("/publish");
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_view_promo">
      <header className="heading">
        <TitleWithBackBtn title={"View Event"} />
        <Button
          variant="contained"
          color="red20"
          endIcon={<DeleteIcon color="red" />}
          onClick={deleteHandler}
        >
          Delete
        </Button>
      </header>
      <div className="pg_content_view card">
        <div className="carousel">
          <Carousel>
            {event?.gallery.map((el, i) => (
              <img src={el} key={el + i} alt="event" />
            ))}
          </Carousel>
        </div>
        <br />
        <h3 className="name">{event?.title}</h3>
        <div className="details">
          <p className="clr-textClr-40 promDet">Event Description</p>
          <p className="story">{event?.description}</p>
          <br />
          <p className="clr-textClr-40 promDet">Event Date</p>
          <p>{event?.event_date}</p>
          <br />
          <p className="clr-textClr-40 promDet">Event Time</p>
          <p>{event?.event_time}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewEvent;
