import add_circle from "../../assets/icons/add_circle.svg";
import "./style.scss";
const AddBtn = ({btnText, onClick}) => {
  return (
    <button onClick={onClick} type="button" className="addBtn">
      <p>{btnText}</p>
      <img src={add_circle} alt="add_circle" />
    </button>
  );
};

export default AddBtn;
