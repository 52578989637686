import DashCard from "../../components/dashCard";
import PageTitleAddBtn from "../../components/pageTitleAddBtn";
import "./style.scss";
import est from "../../assets/icons/est.svg";
import hou from "../../assets/icons/hou.svg";
import prodd from "../../assets/icons/prodd.svg";
import prop from "../../assets/icons/prop.svg";
import {Avatar, Button} from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
// import EachEstate from "../../components/eachEstate";
import {Pie} from "react-chartjs-2";
import {Chart as SecChart, registerables} from "chart.js";
// import people from "../../assets/icons/people.png";
import {useEffect, useState} from "react";
import locate from "../../assets/icons/location.svg";
import mes from "../../assets/icons/mes.svg";
import eye from "../../assets/icons/eye.svg";
import {useGetDashboardQuery} from "../../services/api";
import moment from "moment";
import {truncateString} from "../../utils/utils";
import {useNavigate, Link} from "react-router-dom";
import Loader from "../../components/Loader";
import ErrorMsg from "../../components/ErrorMsg";
import EachEstate from "../../components/eachEstate";
import Chart from "react-apexcharts";
const Dashboard = () => {
  const {data: dash = null, isLoading, isError, error} = useGetDashboardQuery();

  const pieData = {
    labels: dash && dash.chart2.labels,

    datasets: [
      {
        label: "",
        weight: 1,
        backgroundColor: ["#91FED1", "#BFDBFE", "#FFF6C2"],
        data: dash && dash.chart2.data,
      },
    ],
  };
  SecChart.register(...registerables);

  const [options, setOptions] = useState(null);

  const [series, setSeries] = useState(null);

  useEffect(() => {
    if (dash) {
      const options = {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: dash.chart2.labels,
        },
        colors: ["#8d4c4c", "#674383", "#9c9258"],
        plotOptions: {
          bar: {
            distributed: true,
            columnWidth: 30,
          },
        },
        grid: {show: false},
      };
      const series = [
        {
          name: "series-1",
          data: dash.chart2.data,
        },
      ];

      setOptions(options);
      setSeries(series);
    }
  }, [dash]);

  const navigate = useNavigate();
  const handleMove = () => {
    navigate("/publish/promos");
  };

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="pd-dashboard">
      <PageTitleAddBtn
        title={"Dashboard"}
        subText={"Welcome to Adron Homes Admin Panel."}
      />
      <div className="cards-wrap">
        <div className="cardArea">
          <DashCard
            bg={"green"}
            img={prop}
            title={"Properties"}
            number={dash && dash.properties}
          />
          <DashCard
            bg={"blue"}
            img={est}
            title={"Estates"}
            number={dash && dash.estates}
          />
          <DashCard
            bg={"purple"}
            img={prodd}
            title={"Products"}
            number={dash && dash.products}
          />
          <DashCard
            bg={"red"}
            img={hou}
            title={"Housing Series"}
            number={dash && dash.home_series}
          />
        </div>
      </div>
      <div className="statArea">
        <div className="statistics">
          <div className="top">
            <p className="title">Statistics</p>
          </div>{" "}
          <div className="bottom">
            {/* {options && series && (
              <Chart
                options={options2}
                series={series2}
                type="pie"
                // width={360}
              />
            )}{" "} */}
            <Pie
              data={pieData}
              options={{
                title: {
                  display: false,

                  fontSize: 10,
                },

                legend: {
                  display: false,
                },
              }}
            />
          </div>
        </div>
        <div className="estatePart">
          <div className="top">
            <p>Most Inquired Estate</p>
            <Button
              color="textClr"
              component={Link}
              endIcon={<ArrowCircleRightOutlinedIcon color="primary" />}
              to={`/properties/viewestate/${dash.most_inquired.id}`}
            >
              View Estate
            </Button>
          </div>
          <div className="bottom">
            {dash && <EachEstate isDash item={dash.most_inquired} />}
          </div>
        </div>
      </div>

      <div className="statArea space">
        <div className="statistics">
          <div className="top">
            <p className="title">Feedback Analysis</p>
          </div>{" "}
          <div className="bottom bar">
            {" "}
            {options && series && (
              <Chart options={options} series={series} type="bar" />
            )}
          </div>
        </div>

        <div className="statistics">
          <div className="top">
            <p className="title">Property By State</p>
          </div>{" "}
          <div className="bottom">
            <div className="overBtm">
              <ul>
                {dash &&
                  dash.map_by_state.map((item, i) => {
                    return (
                      <li key={item.location + i}>
                        <span className="loc">{item.location}</span> -{" "}
                        <span className="count">{item.count}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>

        <div className="statistics">
          <div className="top">
            <p className="title">Subscribe Forms</p>
            <Button
              color="textClr"
              endIcon={<ArrowCircleRightOutlinedIcon color="primary" />}
              onClick={() => {
                navigate("/sales");
              }}
            >
              View All
            </Button>
          </div>{" "}
          <div className="bottom">
            {dash &&
              dash.subscribers.map((item, i) => {
                return (
                  <div key={item.display_picture + i} className="each">
                    {/* <img className="people" src={people} alt="people" /> */}
                    <Avatar
                      alt="user"
                      src={item.display_picture}
                      variant="square"
                      sx={{width: 40, height: 40}}
                    />
                    <div className="right">
                      <div className="top">
                        <p>{item.fullname}</p>
                        <p>{item.state || "****"}</p>
                      </div>
                      <div className="top">
                        <p>{"*******"}</p>
                        <p>
                          <img src={locate} alt="locate" />
                          {/* <span>{item.city || "****"}</span> */}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="statArea extra">
        <div className="estatePart">
          <div className="top">
            <p>Promos</p>
            <Button
              color="textClr"
              endIcon={<ArrowCircleRightOutlinedIcon color="primary" />}
              onClick={handleMove}
            >
              View All
            </Button>
          </div>
          <div className="bottom">
            {dash &&
              dash.promos.map(item => {
                return (
                  <div
                    key={item.id}
                    className="eachPromo"
                    style={{flexDirection: "row"}}
                  >
                    <img src={item.image} alt="promo" />
                    <div className="textBox">
                      <p className="topic">{item.name}</p>
                      <div className="prom">
                        <div className="circle"></div>
                        <p>{truncateString(item.description, 120)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="statistics">
          <div className="top">
            <p className="title">New Customers</p>
          </div>{" "}
          <div className="bottom">
            {dash &&
              dash.new_customers.map((item, i) => {
                return (
                  <div key={item.fullname + i} className="each ">
                    <div className="prof">
                      <p>{item.fullname.slice(0, 2)}</p>
                    </div>
                    <div className="right eyeSide">
                      <div className="lefttype">
                        <p className="name">{item.fullname}</p>
                        <div className="string">
                          <p>
                            {/* {item.phone_number} */}
                            ******
                          </p>
                          <div className="circle"></div>
                          <p>{moment(item.created_at).format("L")}</p>
                          <div className="circle"></div>
                          <p>XD2321</p>
                        </div>
                      </div>
                      <div className="righttype">
                        <img src={mes} alt="mes" />
                        <img src={eye} alt="eye" />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
