import "./style.scss";
import Logo from "../../assets/icons/adronLogo.svg";
const AuthLayout = ({children}) => {
  return (
    <div className="pd-login">
      <div className="login_nav">
        <img src={Logo} className="logo" alt="logo" />
      </div>
      <div className="login_body">{children}</div>
    </div>
  );
};

export default AuthLayout;
