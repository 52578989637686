import {useForm, FormProvider} from "react-hook-form";
import {useState} from "react";
import {Button} from "@mui/material";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import "../Estates/style2.scss";
import Details from "../AddPropertyForm/Details";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {
  useEditPropertyMutation,
  useGetOnePropertyQuery,
} from "../../../services/api";
import {removeEmpty, toaster} from "../../../utils/utils";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";

const EditProduct = () => {
  const methods = useForm({
    mode: "all",
    shouldUnregister: true,
  });
  const {id} = useParams();
  const {
    data: product = null,
    isLoading: isGetLoading,
    isError,
    error,
  } = useGetOnePropertyQuery(`product/by/${id}`);
  const [editProduct, {isLoading}] = useEditPropertyMutation();
  const navigate = useNavigate();

  const onSubmit = async data => {
    const credentials = {
      ...data,
      gallery: image,
      featured: parseInt(data.featured),
    };
    const filledEstate = Object.keys(product)
      .filter(
        key =>
          !key.includes("payment_plan") &&
          !key.includes("longitude") &&
          !key.includes("created_at") &&
          !key.includes("latitude") &&
          !key.includes("id") &&
          !key.includes("gallery") &&
          !key.includes("type") &&
          key !== "status"
      )
      .reduce((cur, key) => {
        return Object.assign(cur, {[key]: product[key]});
      }, {});
    try {
      let res = await editProduct({
        body: {
          ...Object.assign(
            {
              ...filledEstate,
            },
            {...removeEmpty(credentials)}
          ),
        },
        url: `product/${id}`,
      }).unwrap();
      if (res.status === "success") {
        navigate("/properties/products");
        toaster("success", res.message);
      }
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
      console.warn(err);
    }
  };
  const [image, setImage] = useState([]);

  if (isGetLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="addProperty">
      <header className="heading">
        <TitleWithBackBtn title={`Edit Product`} />
      </header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-container">
            <Details
              type={3}
              images={image}
              setImages={setImage}
              display={true}
              propertyType={"estates"}
              isEditType={true}
            />
            <div className="btn-wrap">
              <Button type="reset" variant="outlined">
                Cancel
              </Button>
              <SubmitBtn
                btnText="Save"
                disabled={!methods.formState.isValid}
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditProduct;
