import {Outlet} from "react-router-dom";
import {CustomSideLink} from "../../components/CustomSideLink";
import "../MasterDataLayout/style.scss";

const PropertiesLayout = () => {
  return (
    <div className="pg-layout">
      <div className="drop-down-links">
        <CustomSideLink baseUrl={"properties"} to="">
          Estates
        </CustomSideLink>
        <CustomSideLink baseUrl={"properties"} to="housing">
          Housing Series
        </CustomSideLink>
        <CustomSideLink baseUrl={"properties"} to="products">
          Products{" "}
        </CustomSideLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default PropertiesLayout;
