import edit from "../../assets/icons/btnEdit.svg";
import call from "../../assets/icons/call.svg";
import message from "../../assets/icons/Message.svg";
import "./style.scss";
import {useEffect, useState} from "react";
import Modal from "../../components/Modal";
import SubmitBtn from "../../components/SubmitBtn/SubmitBtn";
import InputField from "../../components/Input";
import {FormProvider, useForm} from "react-hook-form";
import {Avatar, Button, CircularProgress} from "@mui/material";
import {uploadImg} from "../../services/UploadImg";

import {
  useChangePasswordMutation,
  useChangeDpMutation,
  useEditProfileMutation,
  useGetOneAdminQuery,
} from "../../services/api";
import {removeEmpty, toaster} from "../../utils/utils";
import Loader from "../../components/Loader";
import ErrorMsg from "../../components/ErrorMsg";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../store/slice/AuthSlice";
const Profile = () => {
  const {
    data: userDt = null,
    isLoading: isLoadingSingle,
    isError,
    error,
  } = useGetOneAdminQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDt) dispatch(setUserDetails({user: userDt.data}));
  }, [dispatch, userDt]);

  const [changePwd, {isLoading}] = useChangePasswordMutation();
  const [changeDp] = useChangeDpMutation();
  const [editProfile, {isLoading: isEditProfileLoading}] =
    useEditProfileMutation();
  const [isPwdLoading, setIsPwdLoading] = useState(false);
  const methods = useForm({mode: "all"});
  const pwdMethods = useForm({mode: "all"});
  const [modal, setModal] = useState(false);

  // password change
  const [passwordModal, setPasswordModal] = useState(false);
  const closePassWordModal = () => {
    setPasswordModal(!passwordModal);
    pwdMethods.reset();
  };
  const newPassword = pwdMethods.watch("newPassword");
  const onSubmit = async data => {
    try {
      const response = await changePwd({
        body: {
          old_password: data.old_password,
          new_password: data.new_password,
        },
      }).unwrap();

      closePassWordModal();
      toaster("success", "Password changed successfully");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  // edit profile
  const onEditProfile = async data => {
    try {
      const res = await editProfile({
        ...Object.assign(
          {
            firstname: userDt.data.firstname,
            lastname: userDt.data.lastname,
            phone_number: userDt.data.phone_number,
          },
          {...removeEmpty(data)}
        ),
      }).unwrap();
      closeModal();
      toaster(res?.status || "success", res.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  const closeModal = () => {
    setModal(!modal);
    methods.reset();
  };

  // on dp change
  const onDpChange = async e => {
    let url;
    setIsPwdLoading(true);
    try {
      url = await uploadImg(
        e.target.files[0],
        process.env.REACT_APP_CLOUDINARY_PRESET,
        "Profile"
      );
      let res = await changeDp({display_picture: url.public_id}).unwrap();

      toaster(res?.status || "success", res.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    } finally {
      setIsPwdLoading(false);
    }
  };

  if (isLoadingSingle) return <Loader />;
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_profile">
      <div className="heading">
        <h1 className="pg-title">My Profile</h1>
      </div>
      <div className="about_user">
        <div className="pictureBox">
          <Avatar
            alt={userDt.data.firstname + " " + userDt.data.lastname}
            src={userDt.data.display_picture}
            sx={{
              width: 80,
              height: 80,
              borderRadius: "8px",
            }}
          />
          <label className="edit-icon" htmlFor="display_img">
            <input
              disabled={isPwdLoading}
              type="file"
              onChange={onDpChange}
              hidden
              name="display_picture"
              id="display_img"
            />
            {isPwdLoading ? (
              <CircularProgress color="white" className="progress" />
            ) : (
              <img src={edit} alt="edit" />
            )}
          </label>
        </div>

        <p className="userName">
          {userDt.data.firstname + " " + userDt.data.lastname}
        </p>
        <p className="manage">Manage your profile and settings</p>
      </div>
      <div className="profileBox">
        <div className="box-head">
          <p>Personal Information</p>
          <button onClick={() => setModal(!modal)} className="edit">
            Edit
          </button>
        </div>
        <div className="eachDetail">
          <p className="question">Name</p>
          <p className="answer">
            {userDt.data.firstname + " " + userDt.data.lastname}
          </p>
        </div>
        <div className="eachDetail">
          <p className="question">Gender</p>
          <p className="answer">{userDt.data.gender}</p>
        </div>
        <div className="eachDetail contact">
          <p className="question">Contact Info</p>
          <div className="answerLeft">
            <div className="message">
              <img src={message} alt="message" />
              <p>{userDt.data.email}</p>
            </div>
            <div className="message">
              <img src={call} alt="message" />
              <p>{userDt.data.phone_number || "N/A"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="profileBox">
        <div className="box-head">
          <p>Role & Permissions</p>
        </div>
        <div className="eachDetail">
          <p className="question">Role Name</p>
          <p className="answer">{userDt.data?.roles[0]}</p>
        </div>
        <div className="eachDetail">
          <p className="question">Role Type</p>
          <p className="answer">{userDt.data?.roles[0]}</p>
        </div>
      </div>
      <div className="profileBox">
        <div className="box-head">
          <p>Account Configuration</p>
        </div>
        <div className="eachDetail three">
          <p className="question">PASSWORD</p>

          <div className="answer">
            <button
              onClick={() => setPasswordModal(!passwordModal)}
              className="edit"
            >
              Change
            </button>
          </div>
        </div>
        <div className="eachDetail">
          <p className="question">Language</p>
          <p className="answer">English</p>
        </div>
      </div>
      <Modal openModal={modal} closeModal={closeModal}>
        <h2>Edit Information</h2>

        <FormProvider {...methods}>
          <form
            className="createUserForm"
            onSubmit={methods.handleSubmit(onEditProfile)}
          >
            <div style={{width: "680px"}}>
              <div className="form-group-wrap">
                <InputField
                  type="text"
                  name={`firstname`}
                  required={false}
                  placeholder="First Name"
                  label={"First Name"}
                  errMsg="invalid name input"
                />
                <InputField
                  type="text"
                  name={`lastname`}
                  required={false}
                  placeholder="Last Name"
                  label={"Last Name"}
                  errMsg="invalid name input"
                />
              </div>
              <InputField
                type="tel"
                required={false}
                name={`phone_number`}
                placeholder="Enter Phone Number"
                label={"Phone Number"}
                errMsg="invalid phone number"
              />
            </div>

            <div className="btn-wrap">
              <Button onClick={closeModal} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn
                isLoading={isEditProfileLoading}
                disabled={!methods.formState.isValid}
                btnText={"Save Changes"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal openModal={passwordModal} closeModal={closePassWordModal}>
        <h2>Edit Password</h2>

        <FormProvider {...pwdMethods}>
          <form
            className="createUserForm"
            onSubmit={pwdMethods.handleSubmit(onSubmit)}
          >
            <div style={{width: "680px"}}>
              <div className="form-group-wrap">
                <InputField
                  type="password"
                  name="old_password"
                  label="Old Password"
                  placeholder="Enter current password"
                  errMsg="invalid field"
                />

                <InputField
                  type="password"
                  name="newPassword"
                  label="New Password"
                  placeholder="Password (min 8 characters)"
                />
              </div>
              <div className="form-group-wrap">
                <InputField
                  type="password"
                  name="new_password"
                  label="Confirm password"
                  validatePassword={value =>
                    value === newPassword || "The passwords do not match"
                  }
                  placeholder="Confirm Password"
                />
              </div>
            </div>

            <div className="btn-wrap">
              <Button
                onClick={closePassWordModal}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>

              <SubmitBtn
                isLoading={isLoading}
                disabled={!pwdMethods.formState.isValid}
                btnText={"Save Changes"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};

export default Profile;
