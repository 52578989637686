import AuthLayout from "../../templates/AuthLayout";
import "./style.scss";
import Logo from "../../assets/icons/adronLogo.svg";
import {useForm, FormProvider} from "react-hook-form";
import InputField from "../../components/Input";
import {useLocation, useNavigate} from "react-router-dom";
import SubmitBtn from "../../components/SubmitBtn/SubmitBtn";
import {useDispatch} from "react-redux";
import {setUserDetails, setUserToken} from "../../store/slice/AuthSlice";
import {useLoginMutation} from "../../services/api";
import {toaster} from "../../utils/utils";

const Login = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const [login, {isLoading}] = useLoginMutation();
  let navigate = useNavigate();

  // get redirect url from the query
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("redirectTo");

  const onSubmit = async data => {
    try {
      // call login trigger from rtk query
      const response = await login(data).unwrap();
      // set user details and token in the state
      dispatch(setUserDetails({user: response.admin}));
      dispatch(setUserToken({token: response.token}));
      toaster("success", "Login Successful");
      navigate(search ? search : "/profile", {replace: true});
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <AuthLayout>
      <div className="whiteBox">
        <img src={Logo} alt="logo" className="logo" />
        <p className="page_name">Login</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              type="email"
              name="email"
              placeholder=""
              id="password"
              label="Email"
              errMsg="invalid email input"
            />
            <InputField
              type="password"
              name="password"
              placeholder=""
              id="password"
              label="Password"
              errMsg="invalid input"
            />
            <div className="checkSide">
              <input type="checkbox" name="check" id="check" />
              <label htmlFor="check">Remember Me</label>
            </div>

            <SubmitBtn isLoading={isLoading} btnText={"Login"} />
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
};

export default Login;
