import "./style.scss";
import locate from "../../../assets/icons/location.svg";
import Carousel from "../../../components/Carousel";
import {getYtId, toaster, toCurrency} from "../../../utils/utils";
import {
  useDeleteEstateMutation,
  useGetOnePropertyQuery,
} from "../../../services/api";
import {useNavigate, useParams, Link} from "react-router-dom";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import {Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {LoadingButton} from "@mui/lab";
import moment from "moment";

const ViewEstateEach = ({display = true}) => {
  const {id} = useParams();
  const {
    data: estate = null,
    isLoading,
    isError,
    error,
  } = useGetOnePropertyQuery(`estate/${id}`);
  const navigate = useNavigate();

  const [deleteEstate, {isLoading: delLoading}] = useDeleteEstateMutation();
  const deleteHandler = async () => {
    try {
      // call login trigger from rtk query
      const response = await deleteEstate(id).unwrap();

      toaster("success", response.message);
      navigate("/properties");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="pd-view" style={{display: display ? "block" : "none"}}>
      <div className="heading topSide">
        <TitleWithBackBtn title="View Estate" />
        <div className="btn-wrap">
          <Button
            component={Link}
            to={`/properties/estate/edit/${id}`}
            variant="contained"
            color="primary20"
            endIcon={<EditIcon color="primary" />}
          >
            Edit
          </Button>
          <LoadingButton
            variant="contained"
            color="red0"
            endIcon={<DeleteIcon color="red" />}
            loading={delLoading}
            loadingPosition="end"
            className="delBtn"
            onClick={deleteHandler}
          >
            Delete
          </LoadingButton>
        </div>
      </div>
      <div className="aboutEstate">
        <div className="carousel">
          <Carousel>
            {estate &&
              estate?.gallery?.map((el, i) => (
                <img key={"image-" + i} src={el} alt={"image-" + i} />
              ))}
          </Carousel>
        </div>
        <div className="estateAbout">
          <p className="name">{estate && estate?.name}</p>
          <p className="price">{toCurrency(estate && estate?.amount)}</p>
        </div>
        <div className="hotSide">
          <p className={estate?.tag.toLowerCase()}>{estate?.tag}</p>
          <p className={"blue"}>Estate</p>
          <p className={estate?.property_status.split(" ")[0].toLowerCase()}>
            {estate?.property_status}
          </p>
        </div>
        <div className="location">
          <img src={locate} alt="locate" />
          {estate && estate?.location}
        </div>
        <div className="desc">
          <p className="head">Description</p>
          <p className="story">{estate && estate?.description}</p>
        </div>
        <div className="feature">
          <p className="head">Features</p>
          <div className="images">
            <div className="images story">
              {estate?.features?.map(el => {
                return (
                  <span style={{marginRight: 8}} key={el}>
                    {el}
                  </span>
                );
              })}
            </div>
            <p className="lot">{estate && estate?.landMass}</p>
          </div>
        </div>
        <div className="payment">
          <p className="head">Documentation Fees</p>
          {Object.entries(estate?.document_fee)?.map(([key, val]) => (
            <div key={key + val} className="flex">
              <p>{key}</p>
              <p>{toCurrency(val)}</p>
            </div>
          ))}
        </div>
        <div className="payment">
          <p className="head">Payment Plan</p>
          {Object.entries(estate?.payment_plan)?.map(([key, val]) => (
            <div key={key + val} className="flex">
              <p>{key.split("_").join(" ")}</p>
              <p>{toCurrency(val)}</p>
            </div>
          ))}
        </div>
        <div className="doc">
          <p className="head">Available Documents</p>
          <ul>
            {estate && estate?.documents?.map((el, i) => <li key={i}>{el}</li>)}
          </ul>
        </div>
        <div className="video">
          <div className="eachVideo">
            <p className="head">Property Address</p>
            <div className="map">
              <div
                style={{
                  overflow: "hidden",
                  background: "none !important",
                  height: "100%",
                  width: "100%",
                }}
                className="gmap_canvas"
              >
                <iframe
                  title="maps"
                  width="100%"
                  height="100%"
                  id="gmap_canvas"
                  src={`https://maps.google.com/maps?q=${
                    estate && estate?.address
                  },${estate && estate?.location}=&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                <br />
                <a href="https://www.embedgooglemap.net">
                  embedded google search
                </a>
              </div>
            </div>
          </div>
          {estate?.youtube_url && (
            <div className="eachVideo">
              <p className="head">Documentary</p>
              <div className="map">
                <iframe
                  title="youtube url"
                  height="173px"
                  width="100%"
                  src={`https://www.youtube.com/embed/${getYtId(
                    estate?.youtube_url
                  )}`}
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <div className="date">
          <p className="head">Publish Date</p>
          {moment(estate?.created_at).format("Do MMMM YYYY")}
        </div>
      </div>
    </div>
  );
};

export default ViewEstateEach;
