import {
  Button,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {useState} from "react";
import SubscribeTable from "./SubscribeTable";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import "./style.scss";
import {IconButton} from "@mui/material";
import DropDownWrapper from "../../../components/DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {
  useExporterMutation,
  useGetAllSubscribeQuery,
} from "../../../services/api";
import moment from "moment";
import {convertObjToParams, downloader, toaster} from "../../../utils/utils";
import TablePagination from "../../../components/TablePagination";
import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
import FilterSelect from "../../../components/Input/FilterSelect";

export const SubscribeDropDown = ({id}) => (
  <DropDownWrapper
    className="more-actions"
    action={
      <IconButton className="more-action-btn" aria-label="actions">
        <MoreVertIcon />
      </IconButton>
    }
  >
    <Link to={`subscribe/view/${id}`} className="btn-noBg">
      <VisibilityIcon color="iconColor" /> View Form
    </Link>
    {/* <button className="btn-noBg">
      <ReplyIcon style={{ transform: "rotateY(180deg)" }} color="iconColor" />
      Move to leads
    </button> */}
    {/* <button className="btn-noBg">
      <FileUploadIcon color="iconColor" /> Export
    </button> */}
  </DropDownWrapper>
);

const SubscribeForm = () => {
  const filterMethods = useForm();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState("");

  const {
    data: subscribe = [],
    isFetching,
    isSuccess,
  } = useGetAllSubscribeQuery({page, search, params});

  const [alignment, setAlignment] = useState("list");
  const handleViewChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [getFile] = useExporterMutation();
  const download = async () => {
    try {
      const response = await getFile({type: "sales"}).unwrap();

      downloader(response, "sales");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="subscribe-form table-pg-layout">
      <header className="heading">
        <PageTitleAddBtn
          title={"Subscribe Form"}
          isSearch={true}
          setSearchVal={setSearch}
        />
        <div>
          <Stack direction="row" spacing={2}>
            <Button
              color="primary"
              variant="outlined"
              // disabled={selected.length < 1}

              onClick={download}
            >
              Export
            </Button>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleViewChange}
              className="toggleBtn"
            >
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="grid">
                <ViewComfyIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </div>
      </header>
      <div className="filterSide">
        <FormProvider {...filterMethods}>
          <form
            onSubmit={filterMethods.handleSubmit(data =>
              setParams(convertObjToParams(data))
            )}
          >
            <div className="selectFilter">
              <FilterSelect
                label={"Interest"}
                name="interest"
                selectOption={["product", "estate", "home series"]}
              />
              <Button type="submit" variant="outlined">
                Apply Filter
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      {alignment === "list" && (
        <SubscribeTable rows={subscribe.data} isLoading={isFetching} />
      )}
      {isSuccess && (
        <>
          {alignment === "grid" && (
            <>
              <Grid container spacing={4}>
                {subscribe.data.length &&
                  subscribe.data.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Grid key={labelId} item xs={12} sm={6} md={4} lg={4}>
                        <div className="card">
                          <div className="card-media">
                            <img
                              src="/images/eventSlide.png"
                              alt="subscribe form from Oduabanjo"
                            />
                          </div>
                          <SubscribeDropDown id={row.id} />

                          <div className="card-body">
                            <div className="card-row">
                              <p
                              // className={`status ${row.type
                              //   .split(" ")[0]
                              //   .toLowerCase()}`}
                              >
                                {row.type}
                              </p>
                              <h4>{row.property_name}</h4>
                            </div>
                            <div className="card-row">
                              <p className="clr-textClr-40">Name: </p>
                              <p>{row.property_name}</p>
                            </div>
                            <div className="card-row">
                              <p className="clr-textClr-40">Contact: </p>
                              <p>{row.email}</p>
                            </div>
                            <div className="card-row">
                              <p className="clr-textClr-40">Ref Code: </p>
                              <p> {row.referral_code || "N/A"}</p>
                            </div>
                            <div className="card-row">
                              <p className="clr-textClr-40">Date: </p>
                              <p> {moment(row.created_at).format("L")}</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
          <TablePagination page={page} setPage={setPage} data={subscribe} />
        </>
      )}
    </div>
  );
};

export default SubscribeForm;
