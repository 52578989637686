import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {useState} from "react";
import {Link} from "react-router-dom";
import EnhancedTableHead from "../../../components/EnhancedTableHead";
import {getComparator, stableSort} from "../../../utils/utils";
import {useGetAllCustomersQuery} from "../../../services/api";
import ErrorMsg from "../../../components/ErrorMsg";
import LoadingTable from "../../../components/loadingTable";
import TablePagination from "../../../components/TablePagination";

const headCells = [
  {
    id: "fullname",
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "phone_number",
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email Address",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Action",
  },
];

export default function CustomerTable({search}) {
  const [page, setPage] = useState(1);
  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllCustomersQuery({page, search});

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // loading state
  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <Box sx={{width: "100%"}}>
      <Paper
        style={{boxShadow: "none", borderRadius: "8px"}}
        sx={{width: "100%", mb: 2}}
      >
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.data.length}
              headCells={headCells}
            />
            <TableBody>
              {isSuccess &&
                stableSort(data.data, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left">
                          <div className="user-profile-cell">
                            <Avatar
                              className="user-avatar"
                              style={{
                                width: 30,
                                height: 30,
                                backgroundColor: "primary-20.main",
                                color: "primary",
                              }}
                              alt="users profile"
                              src={row.display_picture}
                            />
                            <span>{row.fullname || "N/A"}</span>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {row.phone_number || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {row.email || "N/A"}
                        </TableCell>
                        <TableCell className="action" align="center">
                          <div className="btn-wrap">
                            <Button
                              className="action-btn"
                              size="small"
                              component={Link}
                              to={`view/${row.id}`}
                            >
                              View Profile
                            </Button>
                            <Button
                              className="action-btn bg-primary-20"
                              size="small"
                              href={`mailto:${row.email}`}
                            >
                              Send Message
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination page={page} setPage={setPage} data={data} />
    </Box>
  );
}
