import InputField from "../../../components/Input";
import Textbox from "../../../components/Input/Textbox";
import UploadPdf from "../../../components/Input/UploadPdf";

const Role = ({display, setImage, setImageUrl}) => {
  return (
    <div className="pd_role" style={{display: display ? "block" : "none"}}>
      <UploadPdf
        name="cover_image"
        filesAccepted={[".png", ".jpg", ".jpeg", ".svg"]}
        label={"Cover Image"}
        placeholder="Select Image"
        setFileUrl={setImageUrl}
        setFile={setImage}
        folder="Adron/Vacancy"
      />
      <InputField
        type="text"
        name="title"
        placeholder="Enter Role"
        label="Role Title"
      />

      <Textbox
        placeholder={"Enter Description"}
        name={"description"}
        label={"Job Description"}
        // maxLength={256}
        errMsg={"invalid field"}
        extraClass={"extraHeight"}
      />
    </div>
  );
};

export default Role;
