import "./style.scss";
import Carousel from "../../../components/Carousel";

const ViewProduct = ({images, product, display = true}) => {
  return (
    <div className="pd-view" style={{display: display ? "block" : "none"}}>
      <div className="aboutEstate">
        <div className="carousel">
          <Carousel>
            {images.map((el, i) => (
              <img key={"image-" + i} src={el} alt={"image-" + i} />
            ))}
          </Carousel>
        </div>
        <div className="estateAbout">
          <p className="name">{product.name}</p>
          {/* <p className="price">{toCurrency(product.amount)}</p> */}
        </div>
        <div className="hotSide">
          <p className={product.tag.toLowerCase()}>{product.tag}</p>
          <p className={"blue"}>Estate</p>
          <p className={product.property_status.split(" ")[0].toLowerCase()}>
            {product.property_status}
          </p>
        </div>

        <div className="desc">
          <p className="head">Description</p>
          <p className="story">{product.description}</p>
        </div>

        <div className="date">
          <p className="head">Publish Date</p>
          <p className="dateText">18th October 2021</p>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
