/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import {Avatar, IconButton, Button} from "@mui/material";
// import {ReactComponent as BellIcon} from "../../assets/icons/bell.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./style.scss";
import DropDownWrapper from "../DropDownWrapper";
import {Link} from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import WebIcon from "@mui/icons-material/Web";
import {useGetUser} from "../../hooks/getUserHook";
// import { useDispatch } from "react-redux";
import {logoutAction} from "../../store/slice/AuthSlice";
import {useState} from "react";
import Notifications from "../Notifications";
import MobileSidebar from "../SideBar/MobileSideBar";
import MenuIcon from "@mui/icons-material/Menu";

const NavBar = () => {
  const {user} = useGetUser();
  // const dispatch = useDispatch();
  const [openNotifications, setopenNotifications] = useState(false);
  const [openMobileNav, setopenMobileNav] = useState(false);
  const toggleNotificationBar = () => {
    setopenNotifications(!openNotifications);
  };

  return (
    <nav className="nav">
      <div className="left">
        <IconButton
          onClick={() => setopenMobileNav(true)}
          style={{
            borderRadius: "10px",
            border: "1px solid #F0ECFF",
          }}
        >
          <MenuIcon />
        </IconButton>
        <p>Welcome {user.firstname}</p>
      </div>
      <Notifications
        handleClose={toggleNotificationBar}
        open={openNotifications}
      />
      <MobileSidebar
        handleClose={() => setopenMobileNav(!openMobileNav)}
        open={openMobileNav}
      />
      <div className="right">
        {/* <IconButton
          style={{
            borderRadius: "10px",
            border: "1px solid #F0ECFF",
          }}
          aria-label={notificationsLabel(100)}
          onClick={toggleNotificationBar}
        >
          <Badge variant="dot" color="error">
            <BellIcon />
          </Badge>
        </IconButton> */}

        <DropDownWrapper
          action={
            <Button className="profile">
              <Avatar
                alt={user.firstname + " " + user.lastname}
                src={user.display_picture}
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              />
              <div className="name">
                <div>
                  <p className="user_name">
                    {user.firstname + " " + user.lastname}
                  </p>
                  <small className="clr-textClr-80">{user.role}</small>
                </div>
                <KeyboardArrowDownIcon color="iconColor" />
              </div>
            </Button>
          }
        >
          <ul className="profile-links">
            <li className="profile-link">
              <Link to="/profile">
                <PersonIcon />
                <p>Profile</p>
              </Link>
            </li>
            <li className="profile-link">
              <Link to="">
                <WebIcon /> Return To Dashboard
              </Link>
            </li>
            <li className="profile-link logout">
              <button onClick={logoutAction} className="clr-red-100">
                <LogoutIcon color="red" /> Logout
              </button>
            </li>
          </ul>
        </DropDownWrapper>
      </div>
    </nav>
  );
};

export default NavBar;
