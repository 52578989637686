import {Button} from "@mui/material";
import LeadsTable from "./LeadsTable";
import {useState} from "react";
import {useExporterMutation, useGetAllLeedsQuery} from "../../../services/api";
import {convertObjToParams, downloader, toaster} from "../../../utils/utils";
import TablePagination from "../../../components/TablePagination";
import {FormProvider, useForm} from "react-hook-form";
import FilterSelect from "../../../components/Input/FilterSelect";
import SearchInput from "../../../components/SearchInput";

const Leads = () => {
  const [page, setPage] = useState(1);
  const filterMethods = useForm();
  const [search, setSearch] = useState("");
  const [params, setParams] = useState("");

  const {
    data: leeds = [],
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetAllLeedsQuery({page, search, params});
  const [getFile] = useExporterMutation();
  const download = async () => {
    try {
      const response = await getFile({type: "leads"}).unwrap();

      downloader(response, "leads");
      toaster("success", "Downloaded");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="leads table-pg-layout">
      <header className="heading">
        {/* <div className="pg-title">Leads</div> */}
        <div className="titleFlex">
          <h1 className="title">Leads</h1>
          <SearchInput setSearchVal={setSearch} />
        </div>
        <Button
          color="primary"
          variant="outlined"
          // disabled={selected.length < 1}

          onClick={download}
        >
          Export
        </Button>
      </header>
      <div className="filterSide">
        <FormProvider {...filterMethods}>
          <form
            onSubmit={filterMethods.handleSubmit(data =>
              setParams(convertObjToParams(data))
            )}
          >
            <div className="selectFilter">
              <FilterSelect
                label={"Interest"}
                name="interest"
                selectOption={["product", "estate", "home series"]}
              />
              <Button type="submit" variant="outlined">
                Apply Filter
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <LeadsTable
        rows={leeds.data}
        loading={isLoading}
        isError={isError}
        error={error}
      />
      {isSuccess && (
        <TablePagination page={page} setPage={setPage} data={leeds} />
      )}
    </div>
  );
};

export default Leads;
