import {IconButton} from "@mui/material";
import Carousel from "../Carousel";
import DropDownWrapper from "../DropDownWrapper";
import "../eachEstate/style.scss";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {Link} from "react-router-dom";
import {useDeleteProductMutation} from "../../services/api";
import {toaster} from "../../utils/utils";
import {LoadingButton} from "@mui/lab";
const EachProduct = ({
  product: {name, gallery, tag, property_status, description, id},
}) => {
  const [deleteProduct, {isLoading: delLoading}] = useDeleteProductMutation();
  const deleteHandler = async id => {
    try {
      // call login trigger from rtk query
      const response = await deleteProduct(id).unwrap();

      toaster("success", response.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  return (
    <div className="eachEstate product">
      <div className="leftImg">
        <Carousel showArrows={false}>
          {gallery.map((el, i) => (
            <img key={el + i} src={el} alt="product" />
          ))}
        </Carousel>{" "}
      </div>
      <div className="right">
        <div className="topSide">
          <p className="productTopic">{name}</p>

          <DropDownWrapper
            className="more-actions"
            action={
              <IconButton className="more-action-btn" aria-label="actions">
                <MoreVertIcon color="primary" />
              </IconButton>
            }
          >
            <Link to={`${id}`} className="btn-noBg">
              <VisibilityIcon color="iconColor" /> View Product
            </Link>
            <Link to={`edit/${id}`} className="btn-noBg">
              <EditIcon color="iconColor" />
              <span>Edit Product</span>
            </Link>
            <LoadingButton
              onClick={() => {
                deleteHandler(id);
              }}
              startIcon={<DeleteIcon color="red" />}
              loading={delLoading}
              loadingPosition="start"
              disabled={delLoading}
            >
              <span className="clr-red-100">Delete Product</span>
            </LoadingButton>
          </DropDownWrapper>
        </div>
        <div className="about">
          <p className={tag.toLowerCase()}>{tag}</p>
          <p className={property_status.split(" ")[0].toLowerCase()}>
            {property_status}
          </p>
        </div>
        <p className="story summary-txt">{description}</p>
      </div>
    </div>
  );
};

export default EachProduct;
