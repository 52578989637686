import locate from "../../assets/icons/location.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DropDownWrapper from "../DropDownWrapper";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";
import Carousel from "../Carousel";
import "../eachEstate/style.scss";
import dir from "../../assets/icons/dir.svg";
import {useDeleteHouseMutation} from "../../services/api";
import {toaster} from "../../utils/utils";
import {LoadingButton} from "@mui/lab";
const EachHousing = ({
  item: {
    description,
    name,
    location,
    id,
    configuration_type,
    gallery,
    tag,
    property_status,
  },
}) => {
  const [deleteHouse, {isLoading: delLoading}] = useDeleteHouseMutation();
  const deleteHandler = async id => {
    try {
      // call login trigger from rtk query
      const response = await deleteHouse(id).unwrap();

      toaster("success", response.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  return (
    <div className="eachEstate">
      <div className="leftImg">
        <Carousel showArrows={false}>
          {gallery.map((el, i) => (
            <img key={el + i} src={el} alt="product" />
          ))}
        </Carousel>
      </div>
      <div className="right">
        <div className="topSide">
          <p className="productTopic">{name}</p>

          <DropDownWrapper
            className="more-actions"
            action={
              <IconButton className="more-action-btn" aria-label="actions">
                <MoreVertIcon color="primary" />
              </IconButton>
            }
          >
            <Link to={`${id}`} className="btn-noBg">
              <VisibilityIcon color="iconColor" /> View Housing
            </Link>

            <Link to={`edit/${id}`} className="btn-noBg">
              <EditIcon color="iconColor" />
              <span>Edit Housing</span>
            </Link>

            <LoadingButton
              onClick={() => {
                deleteHandler(id);
              }}
              startIcon={<DeleteIcon color="red" />}
              loading={delLoading}
              loadingPosition="start"
              disabled={delLoading}
            >
              <span className="clr-red-100">Delete Housing</span>
            </LoadingButton>
          </DropDownWrapper>
        </div>
        <div className="about">
          <p className={tag.toLowerCase()}>{tag}</p>
          <p className={property_status.split(" ")[0].toLowerCase()}>
            {property_status}
          </p>
        </div>
        <p className="story summary-txt">{description}</p>

        <div className="location story">
          <img src={locate} alt="locate" />
          {location}
        </div>

        <div className="config">
          <p className="">
            {configuration_type?.length} configurations available
          </p>
          <img src={dir} alt="dir" />
        </div>
      </div>
    </div>
  );
};

export default EachHousing;
