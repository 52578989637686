export async function uploadImg(file, preset, folder) {
  let fileData = new FormData();
  fileData.append("file", file);
  fileData.append("upload_preset", preset);
  fileData.append("folder", folder);
  // let imgUrl;
  const requestOptions = {
    method: "POST",
    body: fileData,
    redirect: "follow",
  };
  return fetch(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
    requestOptions
  )
    .then(res => res.json())
    .then(response => {
      return response;
    });
}
export async function deleteImg(token) {
  const requestOptions = {
    method: "POST",
    body: `token=${token}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return fetch(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/delete_by_token`,
    requestOptions
  )
    .then(res => res.json())
    .then(response => {
      return response;
    })
    .catch(err => console.warn(err));
}

// "https://api.cloudinary.com/v1_1/fintechngr/image/upload";

// cloudinary://118759682255234:pruQ7s4FhCooA8E_iCrQ1z9c5_c@dpiyqfdpk
