/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "./style.scss";
import UsersTable from "./UsersTable";
import {useState} from "react";
import Modal from "../../../components/Modal";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import InputField from "../../../components/Input";
import SelectField from "../../../components/Input/SelectField";
import {FormProvider, useForm} from "react-hook-form";

import SearchInput from "../../../components/SearchInput";
import {useAddAdminMutation} from "../../../services/api";
import {toaster} from "../../../utils/utils";

const Users = () => {
  const methods = useForm({mode: "all"});

  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const closeModal = () => {
    setModal(!modal);
    methods.reset();
  };

  const [addAdmin, {isLoading}] = useAddAdminMutation();

  const onSubmit = async data => {
    try {
      const response = await addAdmin(data).unwrap();
      toaster(response?.status.toLowerCase(), response.message);
      if (response?.status !== "error") {
        closeModal();
      }
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  return (
    <div className="users-pg table-pg-layout">
      <div className="heading">
        <div className="titleFlex">
          <h1 className="title">Users</h1>
          <SearchInput setSearchVal={setSearch} />
        </div>
        <Button
          onClick={() => setModal(!modal)}
          variant="contained"
          endIcon={<AddOutlinedIcon color="white" />}
        >
          Create User
        </Button>
      </div>
      <UsersTable search={search} />
      <Modal openModal={modal} closeModal={closeModal}>
        <h2>Create User</h2>

        <FormProvider {...methods}>
          <form
            className="createUserForm"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div style={{width: "680px"}}>
              <div className="form-group-wrap">
                <InputField
                  type="text"
                  name={`firstname`}
                  placeholder="First Name"
                  label={"First Name"}
                  errMsg="invalid name input"
                />
                <InputField
                  type="text"
                  name={`lastname`}
                  placeholder="Last Name"
                  label={"Last Name"}
                  errMsg="invalid name input"
                />
              </div>
              <div className="form-group-wrap">
                <InputField
                  type="email"
                  name={`email`}
                  placeholder="Enter Email Address"
                  label={"Email Address"}
                  errMsg="invalid email address"
                />
                <SelectField
                  name={`gender`}
                  placeholder="Enter Email Address"
                  label={"Gender"}
                  errMsg="invalid email address"
                  selectOption={[
                    {
                      value: "",
                      label: "Select Gender",
                    },
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </div>
              <InputField
                type="text"
                name={`address`}
                placeholder="Enter House Address"
                label={"House Address"}
                errMsg="invalid house address"
              />
              <SelectField
                name={`role`}
                placeholder="Enter Email Address"
                label={"Role Name"}
                errMsg="invalid email address"
                selectOption={[
                  {
                    value: "",
                    label: "Select Role",
                  },
                  {
                    value: "Super Admin",
                    label: "Super Admin",
                  },
                  {
                    value: "Admin",
                    label: "Admin",
                  },
                  {
                    value: "Auditor",
                    label: "Auditor",
                  },
                  {
                    value: "Client Service",
                    label: "Client Service",
                  },
                  {
                    value: "Human Resource",
                    label: "Human Resource",
                  },
                  {
                    value: "Sales",
                    label: "Sales",
                  },
                ]}
              />
              <div className="form-group-wrap">
                <InputField
                  type="tel"
                  name={`phone_number`}
                  placeholder="Enter Phone Number"
                  label={"Phone Number"}
                  errMsg="invalid phone number"
                />
                <InputField
                  type="password"
                  name={`password`}
                  placeholder="Password"
                  label={"Password"}
                  errMsg="invalid input"
                />
              </div>
            </div>

            <div className="btn-wrap">
              <Button onClick={closeModal} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn
                disabled={!methods.formState.isValid}
                isLoading={isLoading}
                btnText={"Create User"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};

export default Users;
