import {Stack} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import PersonCard from "../../../components/personCard";
import {useParams} from "react-router-dom";
import {useGetOneSubscribeQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";

const ViewSubscribeForm = () => {
  const {id} = useParams();

  const {data: req, isLoading, isError, error} = useGetOneSubscribeQuery(id);
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="view-details">
      <div className="inner">
        <div className="heading">
          <TitleWithBackBtn title="View Form" />
          <Stack justifyContent={"flex-end"} direction="row" spacing={2}>
            {/* <Button
              color="primary"
              variant="contained"
              endIcon={
                <ReplyIcon
                  style={{ transform: "rotateY(180deg)" }}
                  color="white"
                />
              }
            >
              Move To Leads
            </Button> */}
            {/* <Button
              color="primary"
              variant="outlined"
              
            >
              Export
            </Button> */}
          </Stack>
        </div>
        <PersonCard
          name={req.users_name}
          email={req.email}
          phone={req.phine_number || "N/A"}
          head={"Form"}
        >
          <div className="each">
            <p className="left">Interested In:</p>
            <p className="right">{req.inquirers_name}</p>
          </div>
          <div className="each">
            <p className="left">Name of Estate:</p>
            <p className="right">{req.property_name}</p>
          </div>
          <div className="each">
            <p className="left">Estate Location:</p>
            <p className="right">{req.location || "N/A"}</p>
          </div>
          <div className="each">
            <p className="left">Promo:</p>
            <p className="right">{req.promo_name || "N/A"}</p>
          </div>
          <div className="each">
            <p className="left">Preferred Contact:</p>
            <p className="right">{req.email}</p>
          </div>
          <div className="each">
            <p className="left">Referral’s Code:</p>
            <p className="right">{req.referral_code || "N/A"}</p>
          </div>
          <div className="each">
            <p className="left">Question/Inquiry:</p>
            <p className="right">{req.inquiry}</p>
          </div>
        </PersonCard>
      </div>
    </div>
  );
};

export default ViewSubscribeForm;
