import InputField from "../../../components/Input";
import {useState} from "react";
import {Switch, FormControlLabel, Collapse, IconButton} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import {useFieldArray, useFormContext} from "react-hook-form";
// import AddSvgFile from "../../../components/AddSvgFile";
import AddBtn from "../../../components/addBtn";

const EachCustomization = ({children, title, desc, disabled}) => {
  const [checked, setChecked] = useState(disabled ? false : true);
  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <div className="each-custom">
      <div className="header">
        <div>
          <h3>{title}</h3>
          <p>{desc}</p>
        </div>
        <FormControlLabel
          control={
            <Switch
              size="medium"
              disabled={disabled}
              checked={checked}
              onChange={handleChange}
            />
          }
          labelPlacement="start"
          label={`${checked ? "Active" : "Inactive"}`}
        />
      </div>
      <Collapse unmountOnExit in={checked}>
        {children}
      </Collapse>
    </div>
  );
};

const Customization = ({display, type = 0}) => {
  const {control} = useFormContext();
  const {
    fields: featuresFields,
    append: appendFeature,
    remove: removeFeature,
  } = useFieldArray({
    control: control,
    name: "features",
  });
  const {
    fields: configFields,
    append: addConfig,
    remove: removeConfig,
  } = useFieldArray({
    control: control,
    name: "configurations",
  });
  const {
    fields: docsFields,
    append: addDocField,
    remove: removeDocField,
  } = useFieldArray({
    control: control,
    name: "documents",
  });
  const {
    fields: docsFeesFields,
    append: addDocFeesField,
    remove: removeDocFeesField,
  } = useFieldArray({
    control: control,
    name: "document_fee",
  });

  return (
    <div
      className="properties-pg"
      style={{display: display ? "block" : "none"}}
    >
      <EachCustomization
        disabled={type !== 2 && type !== 1}
        title={"Features"}
        desc={"Add features available on this property."}
      >
        <br />
        <div className="docs-field-wrap">
          {featuresFields.map((field, index) => (
            <div key={field.id} className="flex-wrap">
              <InputField
                type="text"
                name={`features.${index}.featureName`}
                placeholder="Name"
                id="question"
                className={"name"}
                errMsg="invalid input"
              />
              <IconButton onClick={() => removeFeature(index)}>
                <ClearIcon />
              </IconButton>
            </div>
          ))}
          <AddBtn
            btnText={"Add Features"}
            onClick={() => appendFeature({featureName: "", svg: ""})}
          />
        </div>
      </EachCustomization>

      <EachCustomization
        title={"Land Mass"}
        desc={"Does this property have a land mass?"}
        disabled={type !== 2}
      >
        <InputField
          type="text"
          name="land_mass"
          required={false}
          className={"input-sm"}
          placeholder="Enter mass (eg 400 x 300sqm)"
        />
      </EachCustomization>

      <EachCustomization
        disabled={type !== 2}
        title={"Available Documents"}
        desc={"Select documents attached to this property."}
      >
        <br />
        <div className="docs-wrap">
          <div className="docs-field-wrap">
            {docsFields.map((field, index) => (
              <div key={field.id} className="flex-wrap">
                <InputField
                  type="text"
                  name={`documents.${index}.document`}
                  placeholder="Contract Of Sale"
                  id="question"
                  className={"name"}
                  errMsg="invalid input"
                />
                <IconButton onClick={() => removeDocField(index)}>
                  <ClearIcon />
                </IconButton>
              </div>
            ))}
            <AddBtn
              btnText={"Add Documents"}
              onClick={() => addDocField({document: ""})}
            />
          </div>
        </div>
      </EachCustomization>

      <EachCustomization
        disabled={type !== 2}
        title={"Documentation Fees"}
        desc={"Select documentation fees attached to this property."}
      >
        <br />
        <div className="document-wrap">
          {docsFeesFields.map((field, index) => (
            <div key={field.id} className="form-group-wrap">
              <InputField
                type="text"
                name={`document_fee.${index}.documentFee`}
                placeholder="Developmental Fee"
                id="question"
                errMsg="invalid input"
              />
              <InputField
                type="number"
                name={`document_fee.${index}.price`}
                placeholder="₦0.00"
                className={"price"}
              />
              <IconButton onClick={() => removeDocFeesField(index)}>
                <ClearIcon />
              </IconButton>
            </div>
          ))}
          <AddBtn
            btnText={"Add Documentation Fee"}
            onClick={() => addDocFeesField({documentFee: ""})}
          />
        </div>
      </EachCustomization>

      <EachCustomization
        title={"Configurations"}
        desc={"Select configurations available."}
        disabled={type !== 1}
      >
        <br />
        <div className="configuration-wrap">
          {configFields.map((field, index) => (
            <div key={field.id} className="eachConfig">
              <div className="flex-wrap">
                <InputField
                  type="text"
                  name={`configurations[${index}].configurationTitle`}
                  placeholder="Enter Configuration Title"
                  label={"Configuration Type"}
                  className={"name"}
                  errMsg="invalid input"
                />
                <IconButton
                  sx={{mt: "1.2rem"}}
                  onClick={() => removeConfig(index)}
                >
                  <ClearIcon color="iconColor" />
                </IconButton>
              </div>
            </div>
          ))}
          <AddBtn
            btnText={"Add Configuration Type"}
            onClick={() => addConfig({configurationTitle: ""})}
          />
        </div>
      </EachCustomization>
    </div>
  );
};

export default Customization;
