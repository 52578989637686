// import { useState } from "react";
// import dot from "../../assets/icons/dot.svg";
// import product from "../../assets/icons/product.png";
import "./style.scss";
import locate from "../../assets/icons/location.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {moneyFormatter, toaster} from "../../utils/utils";
import DropDownWrapper from "../DropDownWrapper";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";
import Carousel from "../Carousel";
import {useDeleteEstateMutation} from "../../services/api";
import {LoadingButton} from "@mui/lab";

// import { useDeleteEventMutation } from "../../services/api";
const EachEstate = ({
  item: {
    description,
    name,
    location,
    land_mass,
    bg,
    id,
    amount,
    gallery,
    tag,
    property_status,
    features,
  },
  isDash,
}) => {
  // const [show, setShow] = useState(false);
  // const [deletePromo] = useDeleteEventMutation();
  const [deleteEstate, {isLoading: delLoading}] = useDeleteEstateMutation();
  const deleteHandler = async id => {
    try {
      // call login trigger from rtk query
      const response = await deleteEstate(id).unwrap();

      toaster(response.status, response.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  return (
    <div className="eachEstate">
      <div className="leftImg">
        <Carousel showArrows={false}>
          {gallery &&
            gallery.map((el, i) => <img key={el + i} src={el} alt="product" />)}
        </Carousel>
      </div>
      <div className="right">
        <div className="topSide">
          <p className="productTopic">{name}</p>

          <DropDownWrapper
            className="more-actions"
            action={
              <IconButton className="more-action-btn" aria-label="actions">
                <MoreVertIcon color="primary" />
              </IconButton>
            }
          >
            <Link
              to={`${isDash ? "/properties/" : ""}viewestate/${id}`}
              className="btn-noBg"
            >
              <VisibilityIcon color="iconColor" /> View Estate
            </Link>

            <Link
              to={`${isDash ? "/properties/" : ""}estate/edit/${id}`}
              className="btn-noBg"
            >
              <EditIcon color="iconColor" />
              <span>Edit Estate</span>
            </Link>

            <LoadingButton
              onClick={() => {
                deleteHandler(id);
              }}
              startIcon={<DeleteIcon color="red" />}
              loading={delLoading}
              loadingPosition="start"
              disabled={delLoading}
            >
              <span className="clr-red-100">Delete Estate</span>
            </LoadingButton>
          </DropDownWrapper>
        </div>
        <div className="about">
          <p className={tag && tag.toLowerCase()}>{tag && tag}</p>
          <p
            className={
              property_status && property_status.split(" ")[0].toLowerCase()
            }
          >
            {property_status && property_status}
          </p>
        </div>
        <p className="story summary-txt">{description && description}</p>

        <div className="location story">
          <img src={locate} alt="locate" />
          {location}
        </div>
        <div className="images story">
          <span className="title" style={{marginRight: 5}}>
            Features: |
          </span>
          {features.map(el => {
            return (
              <span style={{marginRight: 5}} key={el}>
                {el} |
              </span>
            );
          })}
        </div>
        <p className="images story">
          <span className="title">Land Mass: </span>
          {land_mass}
        </p>
        <p className="price">₦{moneyFormatter(amount)}</p>
      </div>
    </div>
  );
};

export default EachEstate;
