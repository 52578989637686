import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: "",
  errorMessage: "",
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails: (state, {payload: {user}}) => {
      state.user = user;
    },
    setUserToken: (state, {payload: {token}}) => {
      state.token = token;
    },
    logOut: state => {
      state.token = "";
      state.user = null;
      let redirectTo = window.location.pathname;
      window.localStorage.clear();
      window.location.href = `/login?redirectTo=${redirectTo}`;
    },
  },
});

const {actions, reducer} = authSlice;
export const {setUserDetails, logOut, setUserToken} = actions;

export const logoutAction = () => {
  window.localStorage.clear();
  window.location.href = "/login";
};

// selector to select user details from the store
export const selectCurrentUser = state => state.auth.user;

export default reducer;
