import csrImage from "../../../assets/icons/csr.png";
import "./style.scss";
import {Button} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EmptyResponsePage from "../../../components/EmptyResponsePage";
import {Link} from "react-router-dom";

const CPS = () => {
  return (
    <div className="pd_cps">
      <div className="heading">
        <h1 className="pg-title">Client Partnership Scheme</h1>
      </div>
      <EmptyResponsePage
        btn={
          <Button
            variant="contained"
            endIcon={<AddOutlinedIcon color="white" />}
            component={Link}
            to="add"
          >
            Add CPS
          </Button>
        }
        image={csrImage}
        message={"Client Partnership Scheme Would Appear Here"}
      />
    </div>
  );
};

export default CPS;
