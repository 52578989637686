import PageTitleAddBtn from "../../../components/pageTitleAddBtn";
/* eslint-disable jsx-a11y/anchor-is-valid */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EachStateItem from "../../../components/eachStateItem";
import {
  Button,
  Accordion,
  Grid,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import {states} from "../../../utils/data";
import "./style.scss";
import {useState} from "react";
import Modal from "../../../components/Modal";
import SubmitBtn from "../../../components/SubmitBtn/SubmitBtn";
import InputField from "../../../components/Input";
import SelectField from "../../../components/Input/SelectField";
import {FormProvider, useForm} from "react-hook-form";
import {
  useAddIndividualOfficeMutation,
  useDeleteOfficeMutation,
  useEditOfficeMutation,
  useGetAllOfficesQuery,
} from "../../../services/api";
import {groupBy} from "../../../utils/utils";

import "./style.scss";
import {toaster} from "../../../utils/utils";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
const Office = () => {
  //   const methods = useForm({
  //     mode: "all",
  //   });
  const methods = useForm({mode: "all"});
  const methods2 = useForm({mode: "all"});

  const onSubmit = async data => {
    try {
      // call login trigger from rtk query
      await addIndividualOffice(data).unwrap();

      toaster("success", "Office Location Added");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  const [addIndividualOffice, {isLoading}] = useAddIndividualOfficeMutation();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [editId, setEditId] = useState(null);
  const {
    data: offices = [],
    isLoading: isOfficeLoading,
    isError,
    error,
  } = useGetAllOfficesQuery();
  const closeModal = () => {
    setModal(!modal);
    methods.reset();
  };
  const closeModal2 = () => {
    setModal2(!modal2);

    // setValue("");
    methods2.reset();
  };

  const [deleteOffice] = useDeleteOfficeMutation();

  const deleteOfficeHandle = async id => {
    try {
      // call login trigger from rtk query
      const response = await deleteOffice(id).unwrap();

      toaster("success", response.message);
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  const [editOffice, {isLoading: editLoading}] = useEditOfficeMutation();

  const loadEdit = (
    state,
    office_name,
    office_address,
    office_email,
    office_phone_no,
    editId
  ) => {
    methods2.setValue("state", state);
    methods2.setValue("office_name", office_name);
    methods2.setValue("office_address", office_address);
    methods2.setValue("office_email", office_email);
    methods2.setValue("office_phone_no", office_phone_no);
    setEditId(editId);
    setModal2(!modal2);
  };

  const onEdit = async data => {
    const payload = {
      ...data,
      id: editId,
    };
    try {
      // call login trigger from rtk query
      await editOffice(payload).unwrap();

      toaster("success", "Office Location Updated");
    } catch (err) {
      console.warn(err);
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };

  if (isOfficeLoading) return <Loader />;
  if (isError) return <ErrorMsg error={error} />;

  return (
    <div className="pd_office">
      <PageTitleAddBtn
        title={"Our Offices"}
        btnText={"Add Office"}
        action={() => setModal(!modal)}
      />
      <div className="accordion_cover">
        {Object.entries(groupBy(offices, "state")).map(([key, arr], i) => {
          return (
            <Accordion key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="accTop">{key}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container mt={1} spacing={4}>
                  {offices.length ? (
                    arr.map((item, i) => (
                      <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
                        <EachStateItem
                          deleteOfficeHandle={deleteOfficeHandle}
                          office={item}
                          loadEdit={loadEdit}
                        />
                      </Grid>
                    ))
                  ) : (
                    <h1>No Item yet</h1>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <Modal openModal={modal} closeModal={closeModal}>
        <h2>Add Office</h2>

        <FormProvider {...methods}>
          <form
            className="createUserForm"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div style={{width: "680px"}}>
              <SelectField
                name="state"
                label="State"
                errMsg="invalid field"
                selectOption={[
                  {
                    label: "Select State",
                    value: "",
                  },
                  ...states.map(el => ({
                    label: el,
                    value: el,
                  })),
                ]}
              />
              <InputField
                type="text"
                name={`office_name`}
                placeholder="Enter Office Name"
                label={"Office Name"}
              />
              <InputField
                type="text"
                name={`office_address`}
                placeholder="Enter Office Address"
                label={"Office Address"}
              />
              <div className="form-group-wrap">
                <InputField
                  type="email"
                  name={`office_email`}
                  placeholder="Enter Email Address"
                  label={"Office Email Address"}
                />
                <InputField
                  type="tel"
                  name={`office_phone_no`}
                  placeholder="Enter Phone Number"
                  label={"Office Phone Number"}
                />
              </div>
            </div>
            <div className="btn-wrap">
              <Button onClick={closeModal} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn
                isLoading={isLoading}
                disabled={!methods.formState.isValid}
                btnText={"Create Office"}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      <Modal openModal={modal2} closeModal={closeModal2}>
        <FormProvider {...methods2}>
          <form
            className="createUserForm"
            onSubmit={methods2.handleSubmit(onEdit)}
          >
            <h2>Edit Office</h2>
            <div style={{width: "680px"}}>
              <SelectField
                name="state"
                label="State"
                errMsg="invalid field"
                selectOption={[
                  {
                    label: "Select State",
                    value: "",
                  },
                  ...states.map(el => ({
                    label: el,
                    value: el,
                  })),
                ]}
              />
              <InputField
                type="text"
                name={`office_name`}
                placeholder="Enter Office Name"
                label={"Office Name"}
              />
              <InputField
                type="text"
                name={`office_address`}
                placeholder="Enter Office Address"
                label={"Office Address"}
              />
              <div className="form-group-wrap">
                <InputField
                  type="email"
                  name={`office_email`}
                  placeholder="Enter Email Address"
                  label={"Office Email Address"}
                />
                <InputField
                  type="tel"
                  name={`office_phone_no`}
                  placeholder="Enter Phone Number"
                  label={"Office Phone Number"}
                />
              </div>
            </div>

            <div className="btn-wrap">
              <Button onClick={closeModal2} type="button" variant="outlined">
                Cancel
              </Button>

              <SubmitBtn isLoading={editLoading} btnText={"Edit Office"} />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};

export default Office;
