import React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import ReduxToastr from "react-redux-toastr";

import AllRoutes from "./routes";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1E791C",
    },
    primary80: {
      main: "#7CE17A",
    },
    primary60: {
      main: "#92E890",
    },
    primary40: {
      main: "#C4F0C3",
      contrastText: "#1E791C",
    },
    primary20: {
      main: "#E0FBD0",
      contrastText: "#1E791C",
    },
    iconColor2: {
      main: "#CDCFCE",
    },
    textClr: {
      main: "#2A3E1D",
    },
    textClr80: {
      main: "#4B5E4E",
    },
    textClr60: {
      main: "#78867A",
    },
    textClr40: {
      main: "#A5AFA7",
    },
    textClr20: {
      main: "#A5AFA7",
    },
    red: {
      main: "#D73B4E",
    },
    red80: {
      main: "#DF6271",
    },
    red60: {
      main: "#E78995",
    },
    red40: {
      main: "#EFB1B8",
      contrastText: "#D73B4E",
    },
    red20: {
      main: "#F7D8DC",
      contrastText: "#D73B4E",
    },
    red0: {
      main: "#FFFBFB",
      contrastText: "#D73B4E",
    },
    yellow: {
      main: "#F6CF44",
    },
    yellow80: {
      main: "#F8D969",
    },
    yellow60: {
      main: "#FAE28F",
    },
    yellow40: {
      main: "#FBECB4",
    },
    yellow20: {
      main: "#FDF5DA",
    },
    white80: {
      main: "#FAFAFA",
    },
    white: {
      main: "#fff",
    },
    iconColor: {
      main: "#B7BCC2",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "capitalize",
          boxShadow: "none",
          "&:disabled": {
            opacity: 0.6,
            cursor: "not-allowed",
            // PointerEvent: "init"
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AllRoutes />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        // preventDuplicates
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        position="top-center"
        removeOnHover={false}
        getState={state => state.toastr} // This is the default
        className="toastr"
        closeOnToastrClick={false}
      />
    </ThemeProvider>
  );
}

export default App;
