import DropDownWrapper from "../DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {IconButton} from "@mui/material";
import {Link} from "react-router-dom";
import {toaster, truncateString} from "../../utils/utils";
import "./style.scss";
import {useDeleteVacancyMutation} from "../../services/api";
const EachVacancy = ({id, title, date, story, closing, image}) => {
  const [deleteFaq, {isLoading: isDeleteLoading}] = useDeleteVacancyMutation();
  const deleteHandler = async id => {
    try {
      const response = await deleteFaq(id).unwrap();
      if (response.status === "success") toaster("success", response.message);
    } catch (err) {
      if (err.status === "FETCH_ERROR")
        toaster("error", "Something went wrong, please try again...");
      else toaster("error", err.data.message);
    }
  };
  return (
    <div className="pd_each_vacancy card">
      <div className="card-media">
        <img src={image} alt="vacancy" />
      </div>

      <DropDownWrapper
        className="more-actions"
        action={
          <IconButton className="more-action-btn" aria-label="actions">
            <MoreVertIcon color="primary" />
          </IconButton>
        }
      >
        <Link to={`view/${id}`} className="btn-noBg">
          <VisibilityIcon color="iconColor" /> View Vacancy
        </Link>

        <button
          onClick={() => {
            deleteHandler(id);
          }}
          className="btn-noBg"
        >
          <DeleteIcon color="red" /> Delete Vacancy
        </button>
      </DropDownWrapper>
      <div className="card-body">
        <div className="flex">
          <h4>{title}</h4>
          <p className="clr-textClr-40">{date}</p>
        </div>
        <p className="clr-textClr-40">Description</p>
        <p className="summary-txt">{truncateString(story, 120)}</p>
        <p className="publish-date">
          <span className="clr-textClr-40">Closing Date:</span>{" "}
          <span>{closing}</span>
        </p>
      </div>
    </div>
  );
};

export default EachVacancy;
