import {Button, Stack} from "@mui/material";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import PersonCard from "../../../components/personCard";
import EmailIcon from "@mui/icons-material/Email";
import {useParams} from "react-router-dom";
import {useGetSingleCustomerQuery} from "../../../services/api";
import Loader from "../../../components/Loader";
import ErrorMsg from "../../../components/ErrorMsg";
import moment from "moment";

const ViewMarketer = () => {
  const {id} = useParams();
  // get marketer
  const {
    data: marketer = null,
    isLoading,
    isError,
    error,
  } = useGetSingleCustomerQuery(id);
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="view-details">
      <div className="inner">
        <div className="heading">
          <TitleWithBackBtn title="View Marketer" />
          <Stack justifyContent={"flex-end"} direction="row" spacing={2}>
            <Button
              color="primary"
              variant="outlined"
              endIcon={<EmailIcon color="primary" />}
              href={`mailto:${marketer.email}`}
            >
              Send Mail
            </Button>
          </Stack>
        </div>
        <PersonCard
          name={marketer.fullname}
          email={marketer.email}
          phone={marketer.phone_number}
          head={"Other Details"}
          avatar={marketer.display_picture}
        >
          <div className="each">
            <p className="left">Location:</p>
            <p className="right">{marketer.location || "N/A"}</p>
          </div>
          <div className="each">
            <p className="left">Created By:</p>
            <p className="right">{marketer.fullname}</p>
          </div>
          <div className="each">
            <p className="left">Date Created:</p>
            <p className="right">{moment(marketer.created_at).format("L")}</p>
          </div>
          <div className="each">
            <p className="left">House Address:</p>
            <p className="right">{marketer.address || "N/A"}</p>
          </div>
        </PersonCard>
      </div>
    </div>
  );
};

export default ViewMarketer;
