import {Link, useMatch} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/icons/adronLogo.svg";
import {ReactComponent as Prop} from "../../assets/icons/sidebar/prop.svg";
import {ReactComponent as Sale} from "../../assets/icons/sidebar/sale.svg";
import {ReactComponent as Client} from "../../assets/icons/sidebar/client.svg";
import {ReactComponent as Search} from "../../assets/icons/sidebar/search.svg";
import {ReactComponent as Eye} from "../../assets/icons/sidebar/eye.svg";
import {ReactComponent as Publish} from "../../assets/icons/sidebar/publish.svg";
import {ReactComponent as Master} from "../../assets/icons/sidebar/master.svg";
import {ReactComponent as Dash} from "../../assets/icons/sidebar/dash.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import "./style.scss";
import {
  Drawer,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

const CustomSideLink = ({children, to, baseUrl, ...props}) => {
  let match = useMatch(
    to !== "" ? `/${baseUrl}/` + to + "/*" : `/${baseUrl}/` + to
  );
  return (
    <Link
      className={`side-item ${match ? "active" : ""}`}
      to={baseUrl + "/" + to}
      {...props}
    >
      {children}
    </Link>
  );
};

const MobileSidebar = ({handleClose, open}) => {
  let match = useMatch("");

  function CustomLink({children, baseUrl, title, icon, links, ...props}) {
    let match = useMatch(baseUrl !== "" ? baseUrl + "/*" : baseUrl);

    return (
      <Accordion
        className={`drop-down-title ${match ? "active" : ""}`}
        style={{boxShadow: "none"}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          className="item"
          {...props}
        >
          {icon}
          <p>{title}</p>
        </AccordionSummary>
        <AccordionDetails>
          <div className="drop-down-links mb-drop">
            {links.map(({name, to}) => (
              <CustomSideLink onClick={handleClose} baseUrl={baseUrl} to={to}>
                {name}
              </CustomSideLink>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={handleClose}
      className="sidebar mb-sidebar"
    >
      <div className="inner">
        <IconButton
          aria-label="actions"
          className="close-icon"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Logo className="logo" />
        <Link
          onClick={handleClose}
          className={`item ${match ? "active" : ""}`}
          to={""}
        >
          <Dash className="iconSide" />
          Dashboard
        </Link>
        <div className="line" />
        <CustomLink
          icon={<Prop className="iconSide" />}
          baseUrl="/properties"
          title="Properties"
          links={[
            {
              name: "Estates",
              to: "",
            },
            {
              name: "Housing Series",
              to: "housing",
            },
            {
              name: "Products",
              to: "products",
            },
          ]}
        />
        <CustomLink
          icon={<Sale className="iconSide" />}
          baseUrl="/sales"
          title="Sales"
          links={[
            {
              name: "Subscribe Form",
              to: "",
            },
            {
              name: "Marketers",
              to: "marketers",
            },
            {
              name: "Leads",
              to: "leads",
            },
          ]}
        />
        <CustomLink
          icon={<Client className="iconSide" />}
          baseUrl="/client-services"
          title="Client Service"
          links={[
            {
              name: "Feedbacks",
              to: "",
            },
            {
              name: "Customers",
              to: "customer",
            },
            {
              name: "Our Offices",
              to: "office",
            },
          ]}
        />
        <CustomLink
          icon={<Search className="iconSide" />}
          baseUrl="/hr"
          title="Human Resource"
          links={[
            {
              name: "Applications",
              to: "",
            },
            {
              name: "Vacancy",
              to: "vacancy",
            },
          ]}
        />
        <CustomLink
          icon={<Eye className="iconSide" />}
          baseUrl="/audit"
          title="Audit"
          links={[
            {
              name: "Whistle Blowing",
              to: "",
            },
          ]}
        />

        <CustomLink
          icon={<Publish className="iconSide" />}
          baseUrl="/publish"
          title="Publish"
          links={[
            {
              name: "Events",
              to: "",
            },
            {
              name: "Promos",
              to: "promos",
            },
            {
              name: "CSR",
              to: "csr",
            },
            {
              name: "Loyalty",
              to: "loyalty",
            },
          ]}
        />
        <CustomLink
          icon={<Master className="iconSide" />}
          baseUrl="/master-data"
          title="Master Data"
          links={[
            {
              name: "Role Management",
              to: "",
            },
            {
              name: "Administrators",
              to: "administrators",
            },
            {
              name: "FAQ",
              to: "faq",
            },
          ]}
        />
        <div className="line" />
      </div>
    </Drawer>
  );
};

export default MobileSidebar;
