import {IconButton} from "@mui/material";
import {Link} from "react-router-dom";
import DropDownWrapper from "../DropDownWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.scss";
import {truncateString} from "../../utils/utils";
import {
  useActivatePromoMutation,
  useDeactivatePromoMutation,
  // useDeletePromoMutation,
} from "../../services/api";
import {toaster} from "../../utils/utils";

const EachPromo = ({title, desc, id, status, bgImg}) => {
  // delet Promo
  const [deactivatePromo] = useDeactivatePromoMutation();
  const [activatePromo] = useActivatePromoMutation();

  const deleteHandler = async id => {
    // const payload = {
    //   promo_id: id,
    // };
    if (status === false) {
      try {
        const response = await activatePromo(id).unwrap();
        if (response.status === "success") toaster("success", response.message);
      } catch (err) {
        if (err.status === "FETCH_ERROR")
          toaster("error", "Something went wrong, please try again...");
        else toaster("error", err.data.message);
      }
    } else {
      try {
        const response = await deactivatePromo(id).unwrap();
        if (response.status === "success") toaster("success", response.message);
      } catch (err) {
        if (err.status === "FETCH_ERROR")
          toaster("error", "Something went wrong, please try again...");
        else toaster("error", err.data.message);
      }
    }
  };

  return (
    <div className="eachPromo">
      <div style={{backgroundImage: `url(${bgImg})`}} className="top"></div>

      <DropDownWrapper
        className="more-actions"
        action={
          <IconButton className="more-action-btn" aria-label="actions">
            <MoreVertIcon color="primary" />
          </IconButton>
        }
      >
        <Link to={`view/${id}`} className="btn-noBg">
          <VisibilityIcon color="iconColor" /> View Promo
        </Link>
        {status === true ? (
          <button
            onClick={() => {
              deleteHandler(id);
            }}
            className="btn-noBg"
          >
            <DeleteIcon color="red" /> Deactivate Promo
          </button>
        ) : (
          <button
            onClick={() => {
              deleteHandler(id);
            }}
            className="btn-noBg"
            style={{color: "#2a3e1d"}}
          >
            <DeleteIcon color="primary" /> Activate Promo
          </button>
        )}
      </DropDownWrapper>
      <div className="bottom">
        <p className="title">{title}</p>
        <div className="info">
          <p className="information">Information</p>
          <p className="desc"> {truncateString(desc, 230)}</p>
        </div>
      </div>
    </div>
  );
};

export default EachPromo;
