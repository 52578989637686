import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.scss";

const Notifications = ({handleClose, open}) => {
  return (
    <Drawer
      sx={{width: "30vw", maxWidth: "400px"}}
      anchor={"right"}
      open={open}
      onClose={handleClose}
      className="notifications"
    >
      <header className="notifications-header">
        <div>
          <h2>Notifications</h2>
          <Link component="button" variant="body2">
            Clear All
          </Link>
        </div>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <List>
        {[1, 2, 3, 4, 5, 6].map(el => (
          <ListItem className="notification-item" button key={el}>
            <ListItemIcon>
              <img src="/images/notificationImg.png" alt="notification" />
            </ListItemIcon>
            <ListItemText primary={"1 new Subscribe form"} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Notifications;
