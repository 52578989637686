import TitleWithBackBtn from "../../components/TitleWIthBackBtn";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useGetOneApplicationQuery } from "../../services/api";
import { toCurrency } from "../../utils/utils";
import Loader from "../../components/Loader";
import ErrorMsg from "../../components/ErrorMsg";
const ViewApplication = () => {
  const { id } = useParams();
  const {
    data: application = null,
    isLoading,
    isError,
    error,
  } = useGetOneApplicationQuery(id);
  // loading state
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }
  return (
    <div className="pd_view_application">
      <header className="heading">
        <TitleWithBackBtn title={"View Application"} />
        <div className="btn_box">
          {/* <Button variant="outlined" endIcon={<KeyboardArrowDownIcon />}>
            Export
          </Button> */}
          {/* <Button
            variant="contained"
            color="red0"
            endIcon={<DeleteIcon color="red" />}
          >
            Delete
          </Button> */}
        </div>
      </header>
      <div className="mesaageBox">
        <section>
          <div className="eachTitle">
            <p>Personal Information</p>
            <div className="line"></div>
          </div>
          <div className="members">
            <div className="eachMember">
              <p className="left">Full Name:</p>
              <p className="right">{application.fullname}</p>
            </div>
            <div className="eachMember">
              <p className="left">Email Address:</p>
              <p className="right">{application.email}</p>
            </div>
            <div className="eachMember">
              <p className="left">Phone Number:</p>
              <p className="right">{application.phone}</p>
            </div>
            <div className="eachMember">
              <p className="left">Date of Birth:</p>
              <p className="right">{application.dob}</p>
            </div>
            <div className="eachMember">
              <p className="left">Gender:</p>
              <p className="right">{application.gender}</p>
            </div>
            <div className="eachMember">
              <p className="left">Maritial Status:</p>
              <p className="right">{application.marital_status}</p>
            </div>
            <div className="eachMember">
              <p className="left">State of Residence:</p>
              <p className="right">{application.state_of_residence}</p>
            </div>
            <div className="eachMember">
              <p className="left">Residential Address:</p>
              <p className="right">{application.resident_address}</p>
            </div>
          </div>
        </section>
        <section>
          <div className="eachTitle">
            <p>Academic Qualification</p>
            <div className="line"></div>
          </div>
          <div className="members">
            <div className="eachMember">
              <p className="left">Highest Qualification:</p>
              <p className="right">{application.highest_qualification}</p>
            </div>
            <div className="eachMember">
              <p className="left">Role Applying For:</p>
              <p className="right">Managing Officer</p>
            </div>
            <a download href={application.resume} className="download">
              Resume{" "}
              <div className="coverIcon">
                {" "}
                <DownloadIcon />{" "}
              </div>
            </a>
          </div>
        </section>
        <section>
          <div className="eachTitle">
            <p>Preference</p>
            <div className="line"></div>
          </div>
          <div className="members">
            <div className="eachMember">
              <p className="left">Preferred Location:</p>
              <p className="right">{application.preferred_location}</p>
            </div>
            <div className="eachMember">
              <p className="left">Availability:</p>
              <p className="right">{application.availability}</p>
            </div>
            <div className="eachMember">
              <p className="left">Current Monthly Salary (₦):</p>
              <p className="right">{toCurrency(application.current_salary)}</p>
            </div>
            <div className="eachMember">
              <p className="left">Expected Monthly Salary (₦):</p>
              <p className="right">{toCurrency(application.expected_salary)}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewApplication;
