import {Outlet} from "react-router-dom";
import {CustomSideLink} from "../../components/CustomSideLink";
// import {CustomSideLink} from "../../components/Sidebar";
import "../MasterDataLayout/style.scss";

const ClientServiceLayout = () => {
  return (
    <div className="pg-layout">
      <div className="drop-down-links">
        <CustomSideLink baseUrl="client-services" to="">
          Feedbacks
        </CustomSideLink>
        <CustomSideLink baseUrl="client-services" to="customer">
          Customers
        </CustomSideLink>
        <CustomSideLink baseUrl="client-services" to="office">
          Our Offices
        </CustomSideLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default ClientServiceLayout;
