import {Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.scss";
import locate from "../../../assets/icons/location.svg";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
import Carousel from "../../../components/Carousel";
import {getYtId, toCurrency} from "../../../utils/utils";

const ViewEstate = ({images, estate, display = true, isFormView}) => {
  return (
    <div className="pd-view" style={{display: display ? "block" : "none"}}>
      {isFormView && (
        <div className="heading topSide">
          <TitleWithBackBtn title="View Estate" />
          <div className="btn-wrap">
            <Button
              variant="contained"
              color="primary20"
              endIcon={<EditIcon color="primary" />}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="red0"
              endIcon={<DeleteIcon color="red" />}
              className="delBtn"
            >
              Delete
            </Button>
          </div>
        </div>
      )}

      <div className="aboutEstate">
        <div className="carousel">
          <Carousel>
            {images.map((el, i) => (
              <img key={"image-" + i} src={el} alt={"image-" + i} />
            ))}
          </Carousel>
        </div>
        <div className="estateAbout">
          <p className="name">{estate.name}</p>
          <p className="price">{toCurrency(estate.amount)}</p>
        </div>
        <div className="hotSide">
          <p className={estate.tag.toLowerCase()}>{estate.tag}</p>
          <p className={"blue"}>Estate</p>
          <p className={estate.property_status.split(" ")[0].toLowerCase()}>
            {estate.property_status}
          </p>
        </div>
        <div className="location">
          <img src={locate} alt="locate" />
          {estate.location}
        </div>
        <div className="desc">
          <p className="head">Description</p>
          <p className="story">{estate.description}</p>
        </div>
        <div className="feature">
          <p className="head">Features</p>
          <div className="images story">
            {estate.features.map(el => {
              return (
                <span style={{marginRight: 5}} key={el}>
                  {el.featureName} |
                </span>
              );
            })}
          </div>
        </div>
        <div className="feature">
          <div className="head">Land Mass: </div>
          <p className="images story">{estate.land_mass}</p>
        </div>
        <div className="payment">
          <p className="head">Documentation Fees</p>
          {estate.document_fee.map(el => (
            <div key={el.documentFee} className="flex">
              <p>{el.documentFee}</p>
              <p>{toCurrency(el.price)}</p>
            </div>
          ))}
        </div>
        <div className="doc">
          <p className="head">Available Documents</p>
          <ul>
            {estate.documents.map((el, i) => (
              <li key={el.document + i}>{el.document}</li>
            ))}
          </ul>
        </div>
        <div className="video">
          <div className="eachVideo">
            <p className="head">Property Address</p>
            <div className="map">
              <div
                style={{
                  overflow: "hidden",
                  background: "none !important",
                  height: "100%",
                  width: "100%",
                }}
                className="gmap_canvas"
              >
                <iframe
                  title="maps"
                  width="100%"
                  height="100%"
                  id="gmap_canvas"
                  src={`https://maps.google.com/maps?q=${estate.address},${estate.location}=&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                <br />
                <a href="https://www.embedgooglemap.net">
                  embedded google search
                </a>
              </div>
            </div>
          </div>
          {estate.youtube_url && (
            <div className="eachVideo">
              <p className="head">Documentary</p>
              <div className="map">
                <iframe
                  title="youtube url"
                  height="173px"
                  width="100%"
                  src={`https://www.youtube.com/embed/${getYtId(
                    estate.youtube_url
                  )}`}
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <div className="date">
          <p className="head">Publish Date</p>
          <p className="dateText">18th October 2021</p>
        </div>
      </div>
    </div>
  );
};

export default ViewEstate;
