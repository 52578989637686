import {FormProvider, useForm, useFieldArray} from "react-hook-form";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBtn from "../../../components/addBtn";
import InputField from "../../../components/Input";
import CancelIcon from "@mui/icons-material/Cancel";
import Textbox from "../../../components/Input/Textbox";
import TitleWithBackBtn from "../../../components/TitleWIthBackBtn";
const AddCPS = () => {
  const methods = useForm({
    defaultValues: {
      scheme: [
        {
          schemeTitle: "",
        },
      ],
    },
  });

  const onSubmit = data => {};
  const {
    fields: schemeFields,
    append,
    remove: removeScheme,
  } = useFieldArray({
    control: methods.control,
    name: "scheme",
  });

  const BenefitInput = ({nestIndex}) => {
    const {
      fields: benefitFields,
      append,
      remove: removeBenefit,
    } = useFieldArray({
      control: methods.control,
      name: `scheme[${nestIndex}].benefits`,
    });
    return (
      <>
        {benefitFields.map((item, k) => (
          <div key={item.id} className="benefit-field-wrap flex-wrap">
            <InputField
              type="text"
              name={`scheme[${nestIndex}].benefits[${k}].benefit`}
              placeholder="Enter Scheme"
              errMsg="invalid input"
            />
            <IconButton onClick={() => removeBenefit(k)}>
              <CancelIcon color="iconColor" />
            </IconButton>
          </div>
        ))}
        <AddBtn onClick={() => append({benefit: ""})} btnText={"Add Benefit"} />
      </>
    );
  };

  return (
    <div className="pd_add_cps">
      <header className="heading">
        <TitleWithBackBtn title={"Add Client Partnership Scheme"} />
      </header>
      <div className="coverForm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Textbox
              placeholder={"Enter Message"}
              name={"introduction"}
              id={"introduction"}
              label={"Introduction"}
            />
            {schemeFields.map((field, i) => (
              <div key={field.id} className="eachScheme">
                <p className="schemeTitle">Scheme {i + 1}</p>
                <div className="flex-wrap">
                  <InputField
                    type="text"
                    name={`scheme[${i}].schemeTitle`}
                    placeholder="Enter Scheme"
                    errMsg="invalid input"
                  />
                  <IconButton onClick={() => removeScheme(i)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
                <BenefitInput nestIndex={i} />
              </div>
            ))}
            <div className="addBox">
              <AddBtn
                onClick={() => append({schemeTitle: ""})}
                btnText={"Add New Scheme"}
              />
            </div>

            <div className="saveSide">
              <button className="cancel2">Cancel</button>
              <button className="save">Add CPS</button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddCPS;
