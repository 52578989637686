import {Controller, useFormContext} from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputErrorMsg from "./InputErrorMsg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function FilterSelect({
  name,
  errMsg,
  className,
  required = false,
  selectOption,
  label,
  isDisabled,
  handleCustomChange,
  ...otherProps
}) {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <FormControl
      sx={{m: "10px 0"}}
      className={`form-group select-group ${className}`}
    >
      <Controller
        control={control}
        name={name}
        rules={{required: required ? errMsg : false}}
        render={({field: {onChange, value = "", ref}}) => (
          <Select
            className="select-mui filter-select"
            ref={ref}
            value={value}
            onChange={value => {
              onChange(value);
              handleCustomChange && handleCustomChange(value);
            }}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            {...otherProps}
            sx={{
              "& .MuiSelect-select::before": {
                content: `"${label} :"`,
                fontWeight: "700",
                paddingRight: "10px",
              },
            }}
          >
            <MenuItem className="menu-item" value={""}>
              All
            </MenuItem>
            {selectOption.map((option, i) => {
              return (
                <MenuItem
                  key={`option-${i}`}
                  className="menu-item"
                  value={typeof option === "string" ? option : option.value}
                >
                  {typeof option === "string" ? option : option.label}
                </MenuItem>
              );
            })}
            {selectOption.length === 0 && (
              <MenuItem value={null} disabled className="center">
                No Options
              </MenuItem>
            )}
          </Select>
        )}
      />
      {errMsg && errors[name] && (
        <InputErrorMsg errMsg={errors[name].message} />
      )}
    </FormControl>
  );
}
